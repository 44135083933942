import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import InputCheckbox from "../../form/input/InputCheckbox";
import ArrowIcon from "../../icons/DownArrowIcon";
import StoreController from "../../../stories/_client/Stores/StoreController";

const Line = props => {
    const { object, item, model, checkable, openable, preview, checked, handleChecked } = props;
    let localChecked = checked;

    const handleClickOnCheckbox = (event) => {
        localChecked = !localChecked;
        createRipple(event);
        handleChecked(object.id);
    }
    const colorLine = () => {
        if (localChecked)
            $("#line-" + object.id).addClass("checked");
        else
            $("#line-" + object.id).removeClass("checked");
    }
    const getOffset = elmt => {
        const rect = elmt.getBoundingClientRect()

        return {
            left: rect.left + $(".main").scrollLeft(),
            top: rect.top + $(".main").scrollTop()
        };
    }
    const createRipple = (event) => {
        const line = document.getElementById("line-" + object.id);
        const containerCircle = document.createElement("div");
        const circle = document.createElement("span");
        const diameter = Math.max(line.clientWidth, line.clientHeight);
        const radius = diameter / 2;
        const offset = getOffset(line);

        containerCircle.classList.add("containerCircle");
        containerCircle.style.width = `${line.clientWidth}px`;
        containerCircle.style.height = `${line.clientHeight}px`;
        containerCircle.style.left = `${offset.left - $(".menu").width()}px`;
        containerCircle.style.top = `${offset.top - $(".headerStore").height()}px`;
        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - radius}px`;
        circle.style.top = `${line.clientHeight / 2 - radius}px`;
        circle.classList.add("ripple");

        if (localChecked)
            circle.classList.add("blue");
        else
            circle.classList.add("gray");

        const ripple = line.getElementsByClassName("containerCircle")[0];

        if (ripple)
            ripple.remove();

        containerCircle.appendChild(circle);
        line.appendChild(containerCircle);

        setTimeout(() => {
            colorLine();
        }, 300);
        setTimeout(() => {
            containerCircle.remove();
            colorLine();
        }, 600);
    }
    const displaySince = strDate => {
        return moment(strDate).fromNow(true);
    }
    const displayDate = strDate => {
        return moment(strDate).format("DD MMM YYYY, LT");
    }
    const buildForType = (attributes, type) => {
        switch (type) {
            case "text":
                return object[attributes[0]]
            case "date":
                return displayDate(object[attributes[0]])
            case "price":
                if (item === "products")
                    return <Link to={ "/products/" + object.id + "/pricelists" }>
                        <span>
                            <NumericFormat value={ object[attributes[0]] } decimalScale={2} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                            { object[attributes[0]] !== null ? " €" : "" }
                        </span>
                    </Link>
                else
                    return <span><NumericFormat value={ object[attributes[0]] } decimalScale={2} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />{ object[attributes[0]] !== null ? " €" : "" }</span>
            case "vat":
                if (item === "products")
                    return <Link to={ "/products/" + object.id + "/pricelists" }>
                        <span>{ object[attributes[0]] !== null ? object[attributes[0]].replace(".", ",").replace("%", " %") : "" }</span>
                    </Link>
                else
                    return <span>{ object[attributes[0]] !== null ? object[attributes[0]].replace(".", ",").replace("%", " %") : "" }</span>
            case "fromNow":
                return <span>
                    il y a <b>{ displaySince(object[attributes[0]]) }</b><br />
                    par <b>{ object[attributes[1]] }</b>
                </span>
            case "state":
                switch (item) {
                    case "clients":
                        return <p className={"state " + object[attributes[0]]}>{ StoreController._states[object[attributes[0]]] }</p>
                    default:
                        return <p className={"state " + object[attributes[0]]}>{ object[attributes[0]] }</p>
                }
            default: break;
        }
    }
    const buildLink = column => {
        if (column.link.includes("http"))
            return <a href={ column.link } target="_blank">{ buildForType(column.attributes, column.type) }</a>
        else {
            let link = "/" + item + "/" + column.link;
            const regex = /\{([a-zA-Z]+)\}/g;
            const matches = link.match(regex);
            let param;

            for (let index in matches) {
                param = matches[index].replace('{','').replace('}','');
                link = link.replace(matches[index], object[param]);
            }

            return <Link to={ link }>{ buildForType(column.attributes, column.type) }</Link>;
        }
    }
    const buildLineFromModel = () => {
        return model.map((column, index) => (
            <td key={ index } className={ column.class }>
                {
                    column.link !== undefined
                        ? buildLink(column)
                        : buildForType(column.attributes, column.type)
                }
            </td>
        ));
    }
    const buildLinkOpenable = () => {
        switch (item) {
            case "clients":
                return object.id + "/informations"
            default:
                return object.id + "/informations"
        }
    }

    useEffect(() => {
        colorLine()
    }, [checked])

    return(
        <tr id={ "line-" + object.id }>
            {
                checkable
                && <td className="checkbox"><InputCheckbox name={ "selected-" + object.id } checked={ checked } handleChange={ handleClickOnCheckbox } /></td>
            }
            {
                buildLineFromModel()
            }
            {
                openable
                && <td className="access"><Link to={ buildLinkOpenable() }><button><span>Ouvrir</span></button></Link></td>
            }
            {
                preview
                && <td className="preview"><button><ArrowIcon /></button></td>
            }
        </tr>
    )
}

export default Line;