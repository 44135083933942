import Rest from "../../../class/tool/Rest"
import Partnership from "../Partnerships/Partnership"
import User from "../Users/User"

class AuthController {
    _rest = new Rest();
    _callback = null;

    me() {
        this._rest.sendQuery(
            'GET',
            '/me',
            {},
            this._callback);
    }

    logout() {
        this._rest.sendQuery(
            'POST',
            '/logout',
            {},
            this._callback);
    }

    findMyPassword(email) {
        this._rest.sendQuery(
            'POST',
            '/findMyPassword',
            {
                email: email
            },
            this._callback);
    }

    // Rules

    static hasRules (expected) {
        if (localStorage.getItem("user") === null) return

        let user = new User(JSON.parse(localStorage.getItem("user")))

        for (let i in user.rules)
            if(expected.indexOf(user.rules[i].name.toLowerCase()) >= 0)
                return true;

        return false;
    }
}

export default AuthController
