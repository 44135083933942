import Entity from "../../../class/abstract/Entity";
import InstallationPhase from "../InstallationPhase/InstallationPhase";
import License from "../Licenses/License";
import Account from "../Accounts/Account";

class Setup extends Entity {
    name = "";
    type_id = 0;
    type_name = "";
    expected_date = "";
    already_installed = false;
    setup_type = {};
    installation_phases = [];
    licenses = [];
    accounts = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = ["installation_phases_list", "list_licenses", "accounts"];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key])
            }
        }

        if (datas.installation_phases_list !== undefined && datas.installation_phases_list !== null)
            for (let i in datas.installation_phases_list)
                this.installation_phases.push(new InstallationPhase(datas.installation_phases_list[i]));

        if (datas.list_licenses !== undefined && datas.list_licenses !== null)
            for (let i in datas.list_licenses)
                this.licenses.push(new License(datas.list_licenses[i]));

        if (datas.accounts !== undefined && datas.accounts !== null)
            for (let i in datas.accounts)
                this.accounts.push(new Account(datas.accounts[i]));
    }
}

export default Setup;
