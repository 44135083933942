import React from "react";

function AccessIcon (props) {
    const { idAttribute, fill, classname, click } = props;

    return (
        <svg viewBox="0 0 512 512" width="1em" height="1em" fill={ fill } id={ idAttribute } className={ classname } onClick={ click !== undefined ? click : null }>
            <circle cx={256} cy={256} r={64} />
            <circle cx={256} cy={448} r={64} />
            <circle cx={256} cy={64} r={64} />
        </svg>
    );
}

export default AccessIcon;
