import React from "react"
import { useParams } from "react-router"
import Client from "./client/Client"
import "../../../css/page/Content.css"

const Content = props => {
    const { page } = props
    const params = useParams()

    const goContent = () => {
        switch (page) {
            //case "dashboard": return <div />
            case "client": return <Client page={ params.page !== undefined ? parseInt(params.page) : null } />
            //case "product": return <div />
            //case "clientCard": return <ClientCardContent id={ params.id } tab={ params.tab } idSubItem={ params.idSubItem } />;
            //case "partnership": return <PartnershipTableContent />;
            //case "partnershipCard": return <PartnershipCardContent id={ params.id } tab={ params.tab } />;
            //case "setting": return <SettingContent tab={ params.tab } />;
            //case "setup": return <SetupContent tab={ params.tab } />;
            //case "prospecting": return <ProspectPhaseContent tab={ params.tab } />;
            //case "settingTicket": return <SettingTicketContent tab={ params.tab } />;
            //case "category": return <CategoryTableContent />;
            //case "brand": return <BrandTableContent />;
            //case "provider": return <ProviderTableContent />;
            //case "providerCard": return <ProviderCardContent id={ params.id } tab={ params.tab } />;
            //case "tax": return <TaxTableContent />;
            //case "product": return <ProductTableContent />;
            //case "productCard": return <ProductCardContent id={ params.id } tab={ params.tab } />;
            default: return <div />
        }
    }

    return (
        <div id={"content"} className="content">
            {
                goContent()
            }
        </div>
    )
}

export default Content
