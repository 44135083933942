import Entity from "../../../class/abstract/Entity";

class Address extends Entity {
    name = null;
    address = null;
    additional = null;
    postalCode = null;
    city = null;
    country = null;
    type_name = null;
    main = false;
    fullAddress = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = []

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }

        let address = this.address !== null ? this.address : "";
        let postalCode = this.postalCode !== null ? this.postalCode : "";
        let city = this.city !== null ? this.city : "";
        Reflect.set(this, "fullAddress", (address.length > 0 ? address + ", " : "") + postalCode + " " + city);
    }
}

export default Address;
