import React from "react";

function ProductIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path d="M10.48 11.15a4.285 4.285 0 00.77.24v9.52a2.64 2.64 0 01-.47-.17l-6-2.67A3 3 0 013 15.33V8.67a2.955 2.955 0 01.11-.79zm4.34-2.23L6.67 5.09l-1.89.84a2.909 2.909 0 00-.91.63l7.21 3.21a2.268 2.268 0 001.84 0zm5.31-2.36a2.909 2.909 0 00-.91-.63l-6-2.67a2.966 2.966 0 00-2.44 0L8.49 4.28l8.15 3.83zm.76 1.32l-3.51 1.56v2.45a.75.75 0 11-1.5 0V10.1l-2.36 1.05a5.275 5.275 0 01-.77.24v9.52a2.64 2.64 0 00.47-.17l6-2.67A3 3 0 0021 15.33V8.67a2.955 2.955 0 00-.11-.79z" />
        </svg>
    );
}

export default ProductIcon;
