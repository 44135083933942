import Entity from "../../../class/abstract/Entity";
import stringTools from "../../../class/tool/StringTools";

class User extends Entity {
    lastname = "";
    firstname = "";
    email = "";
    gender = "Non défini";
    phone = "";
    mobile = "";
    api_key = "";
    rules = [];
    partnerships = [];
    partnerships_storage = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.lastname = datas.lastname !== undefined ? datas.lastname : "";
        this.firstname = datas.firstname !== undefined ? datas.firstname : "";
        this.email = datas.email !== undefined ? datas.email : "";
        this.gender = datas.gender !== undefined ? datas.gender : "";
        this.phone = datas.phone !== undefined ? datas.phone : "";
        this.mobile = datas.mobile !== undefined ? datas.mobile : "";
        this.api_key = datas.api_key !== undefined ? datas.api_key : "";
        this.rules = datas.rules !== undefined ? datas.rules : [];
        this.partnerships = datas.partnerships !== undefined ? datas.partnerships : [];
        this.partnerships_storage = datas.partnerships_storage !== undefined ? datas.partnerships_storage : [];
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }

    fullName() {
        return this.lastname.toLocaleUpperCase() + " " + stringTools.capitalizeFirstLetter(this.firstname);
    }
}

export default User;
