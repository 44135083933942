import React, {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Cookie from 'universal-cookie';
import Init from "./component/initialisation/Init";
import Site from "./component/page/Site";
import FormPwd from "./component/form/account/FormPwd";
import AuthController from "./stories/_auth/Auth/AuthController";
import User from "./stories/_auth/Users/User";
import SessionContext from "./context/SessionContext";
import "./css/Theme.css";

const Main = () => {
    const cookies = new Cookie(null, { domain: process.env.REACT_APP_HOST_ENVIRONMENT, path: "/" });
    const session = cookies.get('session') !== undefined ? cookies.get('session') : null;
    const [ loggedIn, setLoggedIn ] = useState(localStorage.getItem("logged") !== null && localStorage.getItem("logged") !== "NOT_LOGGED_IN" ? localStorage.getItem("logged") : (session !== null ? session.logged : "NOT_LOGGED_IN"));
    const [ user, setUser ] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : (session !== null ? session.user : {}));
    const [ showDefinePwd, setShowDefinePwd ] = useState(false);
    const loginURL = process.env.REACT_APP_LOGIN_URL + "/fromclient";

    const removeSession = () => {
        localStorage.clear()

        cookies.remove('session', { domain: process.env.REACT_APP_HOST_ENVIRONMENT })
        cookies.remove('target_url', { domain: process.env.REACT_APP_HOST_ENVIRONMENT })

        setLoggedIn('NOT_LOGGED_IN')
        setUser({})

        window.location = loginURL
    }
    const logout = () => {
        const auth = new AuthController()
        auth._callback = handleLogout
        auth.logout()
    }
    const handleLogout = (response, error, status) => {
        removeSession()
    }
    const me = () => {
        const auth = new AuthController()
        auth._callback = handleMe
        auth.me()
    }
    const handleMe = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("user", JSON.stringify(response.data))
                window.location.href = "/"
                break
            default:
                removeSession()
                break
        }
    }
    const checkDefinePwd = () => {
        if (localStorage.getItem("user") === null) return
        let user = JSON.parse(localStorage.getItem("user"))

        if (user.definePwd === 1)
            setShowDefinePwd(true)
    }
    const closeDefinePwd = () => {
        setShowDefinePwd(false)
    }
    const refresh = () => {
        const auth = new AuthController()
        auth._callback = handleRefresh
        auth.refresh()
    }
    const handleRefresh = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("expires_in", Math.floor((Date.now() + (response.data.expires_in * 1000)) / 1000).toString())
                localStorage.setItem("logged", "LOGGED_IN")
                setLoggedIn('LOGGED_IN')
                break

            default:
                removeSession()
                break
        }
    }
    const redirectAfterLogin = () => {
        return "/clients";
    }
    const redirectProcess = () => {
        let cookieToken = cookies.get("token");
        let cookieExpiresIn = cookies.get("expires_in");
        let cookieRememberMe = cookies.get("remember_me");

        if (localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
            if (cookieToken === undefined) {
                removeSession();
            }
            else {
                localStorage.setItem("token", cookieToken)
                localStorage.setItem("expires_in", cookieExpiresIn)
                localStorage.setItem("remember_me", cookieRememberMe)

                cookies.remove("token")
                cookies.remove("expires_in")
                cookies.remove("remember_me")
            }
        }

        let pathname = window.location.pathname.replace("/", "")
        let emptyPath = [""]
        let loginPath = ["login"]

        // LOGIN PROCESS
        if (!loginPath.includes(pathname) && localStorage.getItem("user") === null) {
            me();
            return;
        }

        // REFRESH OR UNLOG PROCESS
        const expiresIn = parseInt(localStorage.getItem("expires_in"))
        const rememberMe = localStorage.getItem("remember_me") === "true"

        if (expiresIn < (Date.now() / 1000) && rememberMe) {
            if (rememberMe)
                me()
            else
                removeSession()
        }

        // TARGET PROCESS IF NEEDED
        if (emptyPath.includes(pathname)) {
            window.location.href = redirectAfterLogin()
        }
    }

    const valueSessionContext = {
        handleLogout: logout,
        handleCheckDefinePwd: checkDefinePwd,
        me
    }

    useEffect(() => {
        if (session !== null) {
            localStorage.setItem("token", session.token)
            localStorage.setItem("remember_me", session.remember_me ? "true" : "false")
            localStorage.setItem("expires_in", session.expires_in.toString())
        }

        localStorage.setItem("logged", loggedIn)

        if (Object.keys(user).length > 0)
            localStorage.setItem("user", JSON.stringify(user))
    }, [])
    useEffect(() => {
        if(loggedIn === "LOG_WAIT")
            me();
        else if(loggedIn === "LOG_OUT")
            logout();
        else if(loggedIn === "REFRESH")
            refresh();
    }, [loggedIn])

    return (
        <SessionContext.Provider value={valueSessionContext}>
            <BrowserRouter future={{ v7_relativeSplatPath: true }}>
                {
                    redirectProcess()
                }
                <Routes>
                    { /* AUTH */ }
                    <Route path="login" component={() => {
                            window.location.href = loginURL;
                            return null;
                    }}/>
                    <Route path="logout" element={<Init logout={true} />} />
                    <Route path="init" element={<Init />} />

                    { /* INDEX */ }
                    <Route path="dashboard" element={<Site page="dashboard" />} />

                    { /* CLIENT */ }
                    <Route path="clients" element={<Site page={"client"} />}>
                        <Route path="new" element={<Site page={"client"} />} />
                        <Route path=":id/informations" element={<Site page={"client"} />} />
                        <Route path=":id/informations/assigner-utilisateur" element={<Site page={"client"} />} />
                        <Route path=":id/environnement" element={<Site page={"client"} />} />
                    </Route>

                    { /* CATALOG */ }
                    <Route path="products" element={<Site page="product" />} />
                </Routes>
                {
                    showDefinePwd
                    && <FormPwd object={ new User(JSON.parse(localStorage.getItem("user"))) } closeView={ closeDefinePwd } />
                }
            </BrowserRouter>
        </SessionContext.Provider>
    );
}

export default Main;
