import {useContext, useEffect, useState} from "react";
import { useParams } from "react-router";
import StoreController from "../../stories/_client/Stores/StoreController";
import AppContext from "../../context/AppContext";
import PartnershipController from "../../stories/_auth/Partnerships/PartnershipController";
import AuthController from "../../stories/_auth/Auth/AuthController";

const StateClient = props => {
    const { state, subState, loadingText, loadingIcon, callbackSuccess } = props;
    const [ partnerships, setPartnerships ] = useState(null);
    const params = useParams();
    const { setObjectPopup, setGetDatasPopup, setStatePopup, setSubStatePopup, setPopupLoadingText, setPopupLoadingIcon, setError } = useContext(AppContext);

    const init = () => {
        setObjectPopup("client");
        setStatePopup(state);

        if (loadingText !== undefined)
            setPopupLoadingText(loadingText);

        if (loadingIcon !== undefined)
            setPopupLoadingIcon(loadingIcon);

        switch (state) {
            case "loading":
                switch (subState) {
                    case "new":
                        if (AuthController.hasRules(["admin"]))
                            getPartnerships();
                        else {
                            setTimeout(() => {
                                setterNewClient({
                                    partnerships: []
                                });
                            }, 300);
                        }

                        break;
                    case "sheet":
                        getStore();
                        break;
                    default: break;
                }

                break;
            default:
                break;
        }
    }
    const getPartnerships = () => {
        const controller = new PartnershipController();
        controller._callback = returnGetPartnerships;
        controller.index();
    }
    const returnGetPartnerships = (list, error, status) => {
        switch (status) {
            case 200:
                let values = [];

                for (let i in list) {
                    values.push({
                        type: "partnership",
                        value: list[i].company,
                        id: list[i].id,
                        obj: list[i]
                    });
                }

                setPartnerships(values);
                break;
            default:
                setError("Une erreur s'est produite lors de l'ouverture de la vue");
                setStatePopup("hidden");
                break;
        }
    }
    const getStore = () => {
        const controller = new StoreController();
        controller._callback = returnGetStore;
        controller.show(params.id);
    }
    const returnGetStore = (object, error, status) => {
        switch (status) {
            case 200:
                setTimeout(() => {
                    setGetDatasPopup({
                        store: object
                    });
                    setStatePopup("sheet");
                    setSubStatePopup(null);
                    setPopupLoadingText(null);
                    setPopupLoadingIcon(null);
                }, 300);

                break;
            default:
                setError("Une erreur s'est produite lors de l'ouverture de la fiche client");
                setStatePopup("hidden");
                setSubStatePopup(null);
                break;
        }
    }
    const checkReturnDatas = () => {
        switch (state) {
            case "loading":
                switch (subState) {
                    case "new":
                        if (partnerships !== null) {
                            setTimeout(() => {
                                setterNewClient({
                                    partnerships: partnerships
                                });
                            }, 300);
                        }

                        break;
                    default: break;
                }

                break;
            default: break;
        }
    }
    const setterNewClient = datas => {
        setGetDatasPopup({
            partnerships: partnerships
        });
        setStatePopup("new");
        setSubStatePopup(null);
        setPopupLoadingText(null);
        setPopupLoadingIcon(null);
    }

    useEffect(() => {
        init();
    }, [state, subState, loadingText, loadingIcon, callbackSuccess]);
    useEffect(() => {
        checkReturnDatas();
    }, [partnerships]);
}

export default StateClient;