class StringTools {
    static capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    static removeAccents(str) {
        if (str === null) return ""
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    static removeSpecialChars(str) {
        return str.replace(/[^a-zA-Z ]/g, '')
    }
}

export default StringTools
