import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import $ from "jquery"
import DashboardIcon from "../../icons/DashboardIcon"
import ProductIcon from "../../icons/ProductIcon"
import BillIcon from "../../icons/BillIcon"
import SettingsIcon from "../../icons/SettingsIcon"
import ClientIcon from "../../icons/ClientIcon"
import HivyIcon from "../../icons/HivyIcon"
import "../../../css/page/Menu.css"

const Menu = props => {
    const { page } = props
    const [ visibilityBubble, setVisibilityBubble ] = useState(false)
    const [ textBubble, setTextBubble ] = useState(null)
    const navItems = [
        {
            "flag": "dashboard",
            "pages": ["dashboard"],
            "icon": <DashboardIcon />,
            "bubble": "Tableau de bord"
        },
        {
            "flag": "clients",
            "pages": ["client"],
            "icon": <ClientIcon />,
            "bubble": "Fichier client"
        },
        {
            "flag": "products",
            "pages": ["product"],
            "icon": <ProductIcon />,
            "bubble": "Base produit"
        }
    ]

    const hoverLink = item => {
        $(".menu .bubble").css("top", ($("#item-" + item.flag).offset().top - 3) + "px")
        setTextBubble(item.bubble)
        setVisibilityBubble(true)
    }
    const outLink = () => {
        setVisibilityBubble(false)
    }

    useEffect(() => {
        if (visibilityBubble)
            $(".menu .bubble").addClass("visible")
        else
            $(".menu .bubble").removeClass("visible")
    }, [visibilityBubble])

    return (
        <div className="menu">
            {
                <p className="bubble">
                    {
                        textBubble
                    }
                </p>
            }
            <HivyIcon classname="logo" />
            <nav
                className="listItem"
                onMouseLeave={ () => { outLink() } }>
                {
                    navItems.map((item, index) => (
                        <Link key={ index } to={"/" + item.flag}>
                            <div id={"item-" + item.flag} className={"item" + (item.pages.includes(page) ? " selected" : "")} onMouseOver={() => { hoverLink(item) }}>
                                { item.icon }
                            </div>
                        </Link>
                    ))}
            </nav>
        </div>)
}

export default Menu
