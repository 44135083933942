import React, {useEffect, useState} from "react"

const InputSelect = props => {
    const { attribute, returnType, value, list, dictionary, focus, isDisabled, eventsHandler } = props
    const [ disabled, setDisabled ] = useState((isDisabled !== undefined && isDisabled !== null) ? isDisabled : false)

    const changeEvent = event => {
        if (eventsHandler.handleChange === undefined || eventsHandler.handleChange === null) return;

        eventsHandler.handleChange(attribute, returnType, event.currentTarget.value);
    }
    const blurEvent = () => {
        if (eventsHandler.handleBlur === undefined || eventsHandler.handleBlur === null) return;

        eventsHandler.handleBlur(attribute);
    }
    const buildContent = () => {
        if (list === null || list.length === 0)
            return;

        if (list[0]["header"] === undefined && list[0]["content"] === undefined) {
            return <>
                {
                    list.map((object, index) => (
                        <option key={ index } value={ returnType === "string" ? object.value : index }>
                            {
                                (dictionary !== null && dictionary !== undefined)
                                ? dictionary[object.text !== undefined ? object.text : object.value]
                                : object.text !== undefined ? object.text : object.value
                            }
                        </option>
                    ))
                }
            </>;
        }
        else {

        }
    }

    useEffect(() => {
        buildContent();
    }, []);
    useEffect(() => {
        setDisabled(isDisabled);
    }, [isDisabled]);

    return (
        <select
            id={ attribute }
            value={ value !== null ? value : "" }
            onChange={ changeEvent }
            onBlur={ blurEvent }
            autoFocus={ focus !== null ? focus : false }
            disabled={ disabled }>
            {
                buildContent()
            }
        </select>
    )
}

export default InputSelect
