import React, { useContext, useEffect, useState } from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import { useParams } from "react-router";
import cookie from "react-cookies";
import Listing from "../../../tools/table/Listing";
import StateClient from "../../../load/StateClient";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../../context/SessionContext";
import ListingContext from "../../../../context/ListingContext";
import FilterContext from "../../../../context/FilterContext";
import AppContext from "../../../../context/AppContext";

const Client = props => {
    const item = "clients";
    const itemClass = "client";
    const titleWindow = "Clients";
    const emptyList = "Aucun client";
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    let object_per_page = cookie.load(item + "_per_page");
    let object_sorting_name = cookie.load(item + "_sorting_name");
    let object_sorting_value = cookie.load(item + "_sorting_value");
    const { page } = props;
    const { handleLogout } = useContext(SessionContext);
    const { filters } = useContext(FilterContext);
    const { getDatasPopup, statePopup, subStatePopup, setError } = useContext(AppContext);
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1);
    const [ loading, setLoading ] = useState(false);
    const [ list, setList ] = useState([]);
    const [ pagination, setPagination ] = useState(null);
    const [ perPage, setPerPage ] = useState(object_per_page !== undefined ? object_per_page : 25);
    const [ sortingName, setSortingName ] = useState(object_sorting_name !== undefined ? object_sorting_name : "name");
    const [ sortingValue, setSortingValue ] = useState(object_sorting_value !== undefined ? object_sorting_value : "asc");

    const getStores = (pPage, pPerPage, pSortingName, pSortingValue) => {
        setLoading(true)

        const controller = new StoreController()
        controller._callback = callbackGetStores
        controller.index(filters.type, filters.state, filters.version, filters.search, pPage, pPerPage, pSortingName, pSortingValue, "")
    }
    const callbackGetStores = (listStores, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                setList(listStores)
                setPagination(pagination !== undefined ? pagination : null )
                break;
            case 401:
                handleLogout();
                break;
            default:
                setError("Impossible de récupérer la liste des clients");
                break;
        }
    }
    const setCookie = () => {
        if (object_per_page !== undefined) return
        if (object_sorting_name !== undefined) return
        if (object_sorting_value !== undefined) return

        object_per_page = perPage
        object_sorting_name = sortingName
        object_sorting_value = sortingValue

        cookie.save(item + "_per_page", object_per_page, { path: '/' })
        cookie.save(item + "_sorting_name", object_sorting_name, { path: '/' })
        cookie.save(item + "_sorting_value", object_sorting_value, { path: '/' })
    }
    const updatePageSelect = page => {
        setPageSelect(page !== undefined ? page : 1)
    }
    const checkRefreshWithLocation = () => {
        if (location.state !== undefined && location.state !== null) {
            if (location.state.refresh !== undefined && location.state.refresh)
                handleRefresh();
        }
    }
    const handleRefresh = () => {
        getStores(pageSelect, perPage, sortingName, sortingValue)
    }
    const checkState = type => {
        switch (type) {
            case "informations":
            case "environnement":
                if (statePopup !== "loading" && (getDatasPopup === null || getDatasPopup.store === undefined))
                    return "loading";
                else
                    return statePopup;
            default:
                return null;
        }
    }

    const model = [
        {
            "class": "id",
            "sortingParam": "code",
            "title": "#",
            "attributes": ["code"],
            "type": "text",
            "link": "/{id}/informations"
        },
        {
            "class": "state",
            "sortingParam": "prospect_state",
            "title": "état",
            "attributes": ["prospect_state"],
            "type": "state"
        },
        {
            "class": "groupName",
            "sortingParam": "company_name",
            "title": "Groupe",
            "attributes": ["company_name"],
            "type": "text",
            "link": "{id}/informations"
        },
        {
            "class": "name",
            "sortingParam": "name",
            "title": "Nom",
            "attributes": ["name"],
            "type": "text",
            "link": "{id}/informations"
        },
        {
            "class": "postalCode",
            "sortingParam": "postal_code",
            "title": "CP",
            "attributes": ["postal_code"],
            "type": "text"
        },
        {
            "class": "city",
            "sortingParam": "city",
            "title": "Ville",
            "attributes": ["city"],
            "type": "text"
        },
        {
            "class": "version",
            "sortingParam": "",
            "title": "Version",
            "attributes": ["version"],
            "type": "text"
        }
    ]

    useEffect(() => {
        document.title = "Hivy - " + titleWindow
        setCookie()
    }, []);
    useEffect(() => {
        checkRefreshWithLocation();
    }, [location]);
    useEffect(() => {
        if (page !== null && page !== pageSelect) {
            updatePageSelect(page)
        }
    }, [page]);
    useEffect(() => {
        if (filters.type !== undefined) // first call
            handleRefresh();
    }, [perPage, pageSelect, sortingName, sortingValue, filters]);

    return (
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                titleNbItems={ itemClass }
                emptyList={ emptyList }
                model={ model }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                preview={ false }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Routes>
                <Route index element={<StateClient state={"hidden"} />} />
                <Route exact path="new" element={<StateClient state={"loading"} subState={"new"} loadingText={"Chargement..."} loadingIcon={true} />} />
                <Route exact path=":id/informations" element={<StateClient state={checkState("informations")} subState={"sheet"} loadingText={"Ouverture de la fiche client"} loadingIcon={true} />} />
                <Route exact path=":id/environnement" element={<StateClient state={checkState("environnement")} subState={"sheet"} loadingText={"Ouverture de la fiche client"} loadingIcon={true} />} />
            </Routes>
        </ListingContext.Provider>
    )
}

export default Client;
