import React, {useContext, useEffect} from "react";
import $ from "jquery";
import AppContext from "../../../context/AppContext";
import "../../../css/warning/Warning.css";

const Warning = props => {
    const { text } = props;
    const { setWarning } = useContext(AppContext);

    const cleanError = () => {
        $(".Warning").addClass("disappear");

        setTimeout(() => {
            setWarning(null);
        }, 500);
    }

    useEffect(() => {
        let timeout = setTimeout(() => { cleanError() }, 5000);

        return() => {
            clearTimeout(timeout);
        }
    }, []);

    return (<p className={"Warning"}>{ text }</p>)
}

export default Warning
