import React, { useEffect, useState } from "react";

const InputPredefinedTag = props => {
    const { attribute, returnType, value, tags, tagsDict, readonly, eventsHandler } = props;
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false);

    const selectTag = tag => {
        if (eventsHandler.handleChange === undefined || eventsHandler.handleChange === null) return;

        let valueTmp = value.slice();

        if (valueTmp.includes(tag.value)) {
            for (let i in valueTmp) {
                if (valueTmp[i] === tag.value) {
                    valueTmp.splice(i, 1);
                    break;
                }
            }
        }
        else {
            if (tag.master)
                valueTmp = [tag.value];
            else {
                valueTmp.push(tag.value);

                let master = tags.find(_ => _.master);

                if (master !== undefined) // traitement du master case
                {
                    for (let i in valueTmp) { // recherche la présence d'un master
                        if (valueTmp[i] === master.value) {
                            valueTmp.splice(i, 1);
                            break;
                        }
                    }

                    // check si tous les non master sont ajoutés
                    let tagNoMaster = [];
                    for (let i in tags) {
                        if (!tags[i].master)
                            tagNoMaster.push(tags[i].value);
                    }

                    let intersection = tagNoMaster.filter(x => !valueTmp.includes(x)).concat(valueTmp.filter(x => !tagNoMaster.includes(x)));

                    if (intersection.length === 0) {
                        valueTmp = [master.value];
                    }
                }
            }
        }

        eventsHandler.handleChange(attribute, returnType, valueTmp);
    }

    useEffect(() => {
        setReadOnly(readonly);
    }, [readonly]);

    return (
        <div className={"PredefinedTags"}>
            {
                tags.map((tag, index) => (
                    <p key={index} className={"tag" + (readOnly ? " readOnly" : "") + (value.includes(tag.value) ? " selected" : "")} onClick={ () => { selectTag(tag) } }>
                        { tagsDict !== null ? tagsDict[tag.value] : tag }
                    </p>
                ))
            }
        </div>
    );
}

export default InputPredefinedTag;