import React, {useEffect, useState} from "react"

const InputSelect = props => {
    const { attribute, returnType, backgroundColor, classname, classError, value, list, dictionary, focus, readonly, handleChange, handleBlur } = props
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false)

    const changeEvent = event => {
        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, event.currentTarget.value)
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return
        handleBlur(attribute)
    }

    useEffect(() => {
        setReadOnly(readonly)
    }, [readonly])

    return (
        <div className={ "selectInput " + (classname !== undefined ? classname : "") + ((classError !== undefined && classError !== null && classError.length > 0) ? " " + classError : "") }>
            <select
                id={ attribute }
                value={ value !== null ? value : "" }
                className={ "extend" + (classError !== undefined ? " " + classError : "") }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ focus !== null ? focus : false }
                disabled={ readOnly }
                style={{backgroundColor: ((backgroundColor !== undefined && backgroundColor !== null) ? backgroundColor + "22" : "transparent")}}
            >
                {
                    list !== null
                    && list.map((object, index) => (
                        <option key={ index } value={ returnType === "string" ? (object.text !== undefined ? object.text : object.value) : index }>
                            {
                                (dictionary !== null && dictionary !== undefined)
                                ? dictionary[object.text]
                                : object.text !== undefined ? object.text : object.value
                            }
                        </option>
                    ))
                }
            </select>
            <div className="clearing" />
        </div>
    )
}

export default InputSelect
