import React from "react";

function LogoutIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill={ fill }><path d="m16 1a15 15 0 1 0 15 15 15.018 15.018 0 0 0 -15-15zm-1 7a1 1 0 0 1 2 0v7a1 1 0 0 1 -2 0zm1 17a9.014 9.014 0 0 1 -9-9 8.92 8.92 0 0 1 3.38-7.02 1 1 0 1 1 1.24 1.56 7 7 0 1 0 8.76 0 1 1 0 0 1 1.24-1.56 8.92 8.92 0 0 1 3.38 7.02 9.014 9.014 0 0 1 -9 9z"></path></svg>
    );
}

export default LogoutIcon;
