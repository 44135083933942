import Rest from "../../../class/tool/Rest";
import FormBuilder from "../../../class/tool/FormBuilder";
import StringTools from "../../../class/tool/StringTools";

class LegalDataController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "stores/:idStore/legaldatas";
    _errors = {
        "socialReason": {
            "empty": "La raison sociale est obligatoire",
            "wrong": "La raison sociale doit être comprise entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "siret": {
            "empty": "Le numéro de SIRET est obligatoire",
            "wrong": "Le numéro de SIRET est invalide, il doit comporter 14 chiffres"
        },
        "ape": {
            "empty": "Le code APE (code NAF) est obligatoire",
            "wrong": "Le code APE (code NAF) est invalide, il doit comporter 4 chiffres + 1 lettre"
        },
        "tvaIntra": {
            "empty": "Le numéro de TVA intracommunautaire est obligatoire",
            "wrong": "Le numéro de SIRET est invalide"
        }
    };

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        if (formNew) {
            setValues(prev => ({
                ...prev,
                socialReason: "",
                siret: "",
                ape: "",
                tvaIntra: ""
            }));
        }
        else {

        }
    }
    setFormRows(setRows, formNew = false, datas) {
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Raison sociale",
                    attribute: "socialReason",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Nom de société figurant sur le KBIS",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "SIRET",
                    attribute: "siret",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "XXX XXX XXX XXXXX",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Code APE",
                    attribute: "ape",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "XX.XXX",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "TVA Intracommunautaire",
                    attribute: "tvaIntra",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "FRXX XXX XXX XXX",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                }
            ]
        }
        else {

        }

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["socialReason", "siret", "ape", "tvaIntra"];
        let optionals = [];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "newLegalData":
                datas = this.returnPostDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "socialReason": return this.controlSocialReason(value);
            case "siret": return this.controlSiret(value);
            case "ape": return this.controlApe(value);
            case "tvaIntra": return this.controlIntraTva(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlSocialReason(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["socialReason"]["empty"]
            };
        }

        let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["socialReason"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlSiret(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["siret"]["empty"]
            };
        }

        let regex = /^\d{14}$/i;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["siret"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlApe(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["ape"]["empty"]
            };
        }

        let regex = /^(\d{4}[a-zA-Z]{1}|\d{2}[.]\d{2}[a-zA-Z]{1})$/g;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["ape"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlIntraTva(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["tvaIntra"]["empty"]
            };
        }

        let regex = /^(ATU\d{8}|BE[01]\d{9}|BG\d{9,10}|CY\d{8}[LX]|CZ\d{8,10}|DE\d{9}|DK\d{8}|EE\d{9}|EL\d{9}|ES[\dA-Z]\d{7}[\dA-Z]|FI\d{8}|FR[\dA-Z]{2}\d{9}|HR\d{11}|HU\d{8}|IE\d{7}[A-Z]{2}|IT\d{11}|LT(\d{9}|\d{12})|LU\d{8}|LV\d{11}|MT\d{8}|NL\d{9}B\d{2}|PL\d{10}|PT\d{9}|RO\d{2,10}|SE\d{12}|SI\d{8}|SK\d{10})$/g

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["tvaIntra"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }

    // Controllers

    post(store, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace(":idStore", store.id),
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(store, legalData, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace(":idStore", store.id) + '/' + legalData.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default LegalDataController;
