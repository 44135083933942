import React from "react";
import { Link } from "react-router-dom";
import OptionNbItems from "./OptionNbItems";
import InputCheckbox from "../../form/input/InputCheckbox";
import DropDownIcon from "../../icons/DropDownIcon";
import Line from "./Line";
import LoaderCircle from "../loader/LoaderCircle";
import ArrowIcon from "../../icons/DownArrowIcon";
import AngleLeftIcon from "../../icons/AngleLeftIcon";
import AngleRightIcon from "../../icons/AngleRightIcon";
import "../../../css/tools/table/Line.css";

const Table = props => {
    const {
        item,
        titleNbItems,
        emptyList,
        model,
        list,
        checkable,
        openable,
        preview,
        listChecked,
        pagination,
        loading,
        loadingPagePrev,
        loadingPageNext,
        showOptionNbItems,
        sortingParam,
        sortingDirection,
        noBorder,
        handleChangePerPage,
        handleCheckAll,
        handleSort,
        handleCheckLine,
        handleShowOptionNbItems
    } = props;

    const calcColspan = () => {
        let count = 0

        if (checkable) count++;
        if (preview) count++;

        count += model.length

        return count
    }
    const hasUrl = direction => {
        if(direction === "left") {
            return pagination.prev_page_url !== null
        }
        else {
            return pagination.next_page_url !== null
        }
    }
    const returnSearchUrl = direction => {
        if(direction === "left") {
            return pagination.current_page - 1
        }
        else {
            return pagination.current_page + 1
        }
    }
    const buildButtonArrow = direction => {
        if (pagination !== null && hasUrl(direction)) {
            return <Link to={ "/" + item + "/page/" + returnSearchUrl(direction) }>
                <button className={ "buttonArrow " + direction }>
                    {
                        ((loadingPagePrev && direction === "left") || (loadingPageNext && direction === "right"))
                            ? <LoaderCircle display="loader loaderButtonArrow" strokeWidth="7" />
                            : (
                                direction === "left"
                                    ? <AngleLeftIcon />
                                    : <AngleRightIcon />
                            )
                    }
                </button>
            </Link>
        }
        else {
            return <button className={ "buttonArrow disabled " + direction }>
                {
                    direction === "left"
                        ? <AngleLeftIcon />
                        : <AngleRightIcon />
                }
            </button>
        }
    }
    const buildNbItems = () => {
        if (pagination !== null)
            return pagination.from + " - " + pagination.to + " de " + pagination.total

        return ""
    }

    return (
        <div className="wrapList">
            <div className="pagination">
                {
                    loading
                        ? <LoaderCircle display="loader loadingTable" strokeWidth={ 10 } />
                        : <p className="nbTotal">{ pagination !== null ? pagination.total : 0 }<span>{ titleNbItems + ((pagination !== null && pagination.total > 1) ? "s" : "") }</span></p>
                }
                <div className="index">
                    {
                        buildButtonArrow("right")
                    }
                    {
                        buildButtonArrow("left")
                    }
                    <p className="nbItems" id="nbItems_top" onClick={ () => { handleShowOptionNbItems("top") } }>{ buildNbItems() }</p>
                    <OptionNbItems type="optionNbItems" show={ showOptionNbItems } handleClick={ handleChangePerPage } />
                </div>
            </div>
            <table className="list">
                <tbody>
                    <tr className="headerList">
                        {
                            checkable
                            && <th className="checkbox"><InputCheckbox name="all-selected" checked={ list.length > 0 && listChecked.length === list.length } handleChange={ handleCheckAll } /></th>
                        }
                        {
                            model.map((column, index) => (
                                <th
                                    key={ index }
                                    className={ column.class + " " + ((sortingParam !== "" && sortingParam === column.sortingParam) ? ("sorted " + sortingDirection) : "") }>
                                    <span onClick={ column.sortingParam !== "" ? () => { handleSort(column.sortingParam) } : null }>{ column.title }</span>
                                    {
                                        column.sortingParam !== ""
                                        && <>
                                            {
                                                (sortingParam === column.sortingParam)
                                                    ? <ArrowIcon />
                                                    : <DropDownIcon />
                                            }
                                        </>
                                    }
                                </th>
                            ))
                        }
                        {
                            preview
                            && <th className="access" />
                        }
                    </tr>
                    {
                        list.length > 0
                            ? list.map((object, index) => (
                                <Line
                                    key={ index }
                                    object={ object }
                                    item={ item }
                                    model={ model }
                                    checkable={ checkable }
                                    openable={ openable }
                                    preview={ preview }
                                    checked={ listChecked.includes(object.id) }
                                    handleChecked={ handleCheckLine } />
                            ))
                            : <tr><td className="empty" colSpan={ calcColspan() }>{ loading ? "Chargement..." : emptyList }</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Table;
