import React, {useContext, useEffect, useState} from "react";
import GoogleLocation from "../../../../class/tool/GoogleLocation";
import moment from "moment";
import AddIcon from "../../../icons/AddIcon";
import EditIcon from "../../../icons/EditIcon";
import FormSheet from "../../../overbox/sheet/FormSheet";
import LoaderCircle from "../../../tools/loader/LoaderCircle";
import TagController from "../../../../stories/_client/Tags/TagController";
import UserController from "../../../../stories/_auth/Users/UserController";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import AddressController from "../../../../stories/_client/Addresses/AddressController";
import User from "../../../../stories/_auth/Users/User";
import AppContext from "../../../../context/AppContext";
import "../../../../css/page/contents/client/ClientInformations.css";
import "moment/locale/fr";

const ClientInformations = props => {
    moment.locale("fr");
    const { inDatas } = props;
    const { setError, setValid, setGetDatasPopup } = useContext(AppContext);
    const [ datas, setDatas ] = useState(inDatas);
    const [ contactSelected, setContactSelected ] = useState(null);
    const [ addressSelected, setAddressSelected ] = useState(null);
    const [ centerMap, setCenterMap ] = useState({ lat: 48.83685, lng: 2.55517 });
    const [ addressPosition, setAddressPosition ] = useState(null);
    const [ innerObjectType, setInnerObjectType ] = useState(null);
    const [ innerDatas , setInnerDatas ] = useState(null);
    const [ tags, setTags ] = useState(null);
    const [ users, setUsers ] = useState(null);
    const [ entryHistory, setEntryHistory ] = useState("");
    const [ savingHistory, setSavingHistory ] = useState(false);

    const getStore = () => {
        const controller = new StoreController();
        controller._callback = returnGetStore;
        controller.show(datas.store.id);
    }
    const returnGetStore = (object, error, status) => {
        switch (status) {
            case 200:
                let datasTmp = Object.assign({}, datas);
                datasTmp.store = object;
                setDatas(datasTmp);
                setGetDatasPopup(prev => ({
                    ...prev,
                    store: object
                }));

                break;
            default:
                break;
        }
    }
    const getTags = () => {
        const controller = new TagController();
        controller._callback = returnGetTags;
        controller.index("CONTACT");
    }
    const returnGetTags = (list, error, status) => {
        switch (status) {
            case 200:
                setTags(list);
                break;
            default:
                console.error("Error to get tags", error);
                break;
        }
    }
    const getUsers = () => {
        const controller = new UserController();
        controller._callback = returnGetUsers;
        controller.index(null, datas.store.partnership_id);
    }
    const returnGetUsers = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let users = [
                    {
                        value: "Choisir un utilisateur",
                        id: null
                    },
                    {
                        value: "----------",
                        id: null
                    },
                ];
                let user;

                for (let i in list) {
                    user = new User(list[i]);

                    users.push({
                        value: user.fullName(),
                        id: user.id
                    })
                }

                setInnerDatas({
                    store: datas.store,
                    users: users
                });

                break;
            default:
                closeInnerForm();
                setError("Impossible de récupérer la liste des utilisateurs");
                break;
        }
    }
    const initContactSelected = () => {
        if (datas.store.contacts !== undefined && datas.store.contacts !== null && datas.store.contacts.length > 0)
            setContactSelected(datas.store.contacts[0])
    }
    const selectContactToDisplay = contact => {
        setContactSelected(contact)
    }
    const assignUser = () => {
        setInnerObjectType("assignUser");

        getUsers();
    }
    const legalData = () => {
        setInnerObjectType("newLegalData");
        setInnerDatas({
            store: datas.store
        });
    }
    const addContact = () => {
        setInnerObjectType("newContact");
        setInnerDatas({
            store: datas.store,
            tagList: tags
        });
    }
    const updateContact = contact => {
        setInnerObjectType("updateContact");
        setInnerDatas({
            store: datas.store,
            tagList: tags,
            contact: contact
        });
    }
    const initAddressSelected = () => {
        if (datas.store.addresses !== undefined && datas.store.addresses !== null && datas.store.addresses.length > 0)
            setAddressSelected(datas.store.addresses[0])
    }
    const selectAddressToDisplay = address => {
        setAddressSelected(address)
    }
    const addAddress = () => {
        setInnerObjectType("newAddress")
        setInnerDatas({
            store: datas.store
        });
    }
    const updateAddress = address => {
        setInnerObjectType("updateAddress")
        setInnerDatas({
            address: address
        })
    }
    const buildGoogleApiAddress = (address, urlEncode = false) => {
        /*
        if (urlEncode) {
            return address.address + (address.address.length > 0 ? " " : "") + address.postalCode + " " + address.city + " " + address.country
        }
        else {
            return address.address + (address.address.length > 0 ? ", " : "") + address.postalCode + " " + address.city + ", " + address.country
        }
        */
    }
    const stateInnerPopup = () => {
        return innerObjectType !== null
    }
    const stateInnerForm = () => {
        switch (innerObjectType) {
            case "assignUser":
                if (innerDatas !== null)
                    return true;

                break;
            case "newContact":
            case "updateContact":
                if (tags !== null && innerDatas !== null)
                    return true;

                break;
            case "newAddress":
            case "newLegalData":
                return true;
            case "updateAddress":
                if (innerDatas !== null)
                    return true;

                break;
            default: break;
        }

        return false;
    }
    const titleFormSheet = () => {
        switch (innerObjectType) {
            case "assignUser":
                return "Choisir un utilisateur";
            case "newLegalData":
                return "Informations juridiques";
            case "newContact":
                return "Nouveau contact";
            case "newAddress":
                return "Nouvelle adresse";
            case "updateContact":
                return "Modifier le contact";
            case "updateAddress":
                return "Modifier l'adresse";
            default:
                return "";
        }
    }
    const closeInnerForm = () => {
        setInnerObjectType(null);
        setInnerDatas(null);
    }
    const returnInnerForm = () => {
        closeInnerForm();
        getStore();
    }
    const inputEntry = event => {
        setEntryHistory(event.currentTarget.value);
    }
    const saveHistory = () => {
        if (entryHistory.length === 0 || savingHistory)
            return;

        setSavingHistory(true);

        const controller = new StoreController();
        controller._callback = returnSaveHistory;
        controller.postHistory(datas.store, { comment: entryHistory });
    }
    const returnSaveHistory = (response, error, status) => {
        setSavingHistory(false);

        switch (status) {
            case 204:
                setValid("Commentaire enregistré");
                setEntryHistory("");
                getStore();
                break;
            default:
                setError("Une erreur s'est produite lors de l'enregistrement");
                break;
        }
    }

    useEffect(() => {
        getTags();
        initContactSelected();
        initAddressSelected();
    }, []);

    return (<>
        <div className={"overlay" + (stateInnerPopup() ? " appear" : "")}>
                <div className={"wrapPopup"}>
                    <div className={"popup " + (stateInnerForm() ? "new" : "loading")}>
                        {
                            stateInnerForm()
                                ? <FormSheet
                                    objectType={ innerObjectType }
                                    datas={ innerDatas }
                                    parentDatas={ datas }
                                    title={ titleFormSheet() }
                                    callbackClose={ closeInnerForm }
                                    callbackSuccess={ returnInnerForm } />
                                : <div className={"containerLoading"}>
                                    <LoaderCircle display="loader inPopup" hide="" strokeWidth="10" stroke="#00000070" />
                                </div>
                        }
                    </div>
                </div>
            </div>
        <div className={"containerInformations"}>
            {
                datas.store.assigned_to_id === null
                && <div className={"wrap orange alert"}>
                    <p className={"text"}>Ce client n'est assigné à aucun utilisateur référent</p>
                    <button className={"button"} onClick={ assignUser }>Assigner un référent</button>
                </div>
            }
            {
                datas.store.legal_datas === null
                && <div className={"wrap red alert"}>
                    <p className={"text"}>Ce client ne possède aucune informations juridiques</p>
                    <button className={"button"} onClick={ legalData }>Ajouter les informations</button>
                </div>
            }
            <div className={"wrapInformations"}>
                <div className={"wrapColumns"}>
                    <div className={"column"}>
                        <div className={"wrap"}>
                            <p className={"title"}>Référent</p>
                            <div className={"info"}>
                                <p className={"title"}>Partenaire</p>
                                <p className={"text"}>{datas.store.partnership_name}</p>
                            </div>
                            {
                                datas.store.assigned_to_id !== null
                                && <div className={"info"}>
                                    <p className={"title"}>Utilisateur</p>
                                    <p className={"text"}>{datas.store.assigned_to_name}</p>
                                </div>
                            }
                        </div>
                        <div className={"wrap contacts"}>
                            <p className={"title"}>Contacts</p>
                            {
                                contactSelected !== null
                                && <>
                                    <div className={"info"}>
                                        <p className={"title"}>Nom</p>
                                        <p className={"text"}>{contactSelected.fullname}</p>
                                    </div>
                                    {
                                        contactSelected.email !== null
                                        && <div className={"info"}>
                                            <p className={"title"}>Email</p>
                                            <p className={"text"}>{contactSelected.email}</p>
                                        </div>
                                    }
                                    {
                                        contactSelected.mobile !== null
                                        && <div className={"info"}>
                                            <p className={"title"}>Mobile</p>
                                            <p className={"text"}>{contactSelected.mobile}</p>
                                        </div>
                                    }
                                    {
                                        contactSelected.phone !== null
                                        && <div className={"info"}>
                                            <p className={"title"}>Fixe</p>
                                            <p className={"text"}>{contactSelected.phone}</p>
                                        </div>
                                    }
                                </>
                            }
                            <div className={"list"}>
                                {
                                    (datas.store.contacts !== undefined && datas.store.contacts.length > 0)
                                        ? datas.store.contacts.map((contact, index) => (
                                            <div key={index} className={"line" + ((contactSelected !== null && contact.id === contactSelected.id) ? " selected" : "")} onClick={() => { selectContactToDisplay(contact) }}>
                                                <div className={"containerName"}>
                                                    <p className={"name"}>{contact.fullname}</p>
                                                    <div className={"tags"}>
                                                        {contact.skytill_id !== null &&
                                                            <p className={"tag skytill"}>Compte SkyTill</p>} {contact.tags.map((tag, index) => (
                                                        <p key={index} className={"tag"}>{tag.name}</p>))}
                                                    </div>
                                                </div>
                                                <EditIcon classname={"edit"} handleClick={() => { updateContact(contact) }}/>
                                            </div>
                                        ))
                                        : <div className={"line empty"}>
                                            <div className={"containerName"}>
                                                <p className={"name"}>Aucun contact</p>
                                            </div>
                                        </div>
                                }
                            </div>
                            <button className={"button"} onClick={addContact}>
                                <AddIcon/>
                                <p>Ajouter un contact</p>
                            </button>
                        </div>
                    </div>
                    <div className={"column"}>
                {
                    datas.store.legal_datas !== null
                    && <div className={"wrap legalDatas"}>
                        <p className={"title"}>Informations légales</p>
                        <div className={"info"}>
                            <p className={"title"}>Raison sociale</p>
                            <p className={"text"}>{datas.store.legal_datas.socialReason}</p>
                        </div>
                        <div className={"info"}>
                            <p className={"title"}>SIRET</p>
                            <p className={"text"}>{datas.store.legal_datas.siret}</p>
                        </div>
                        <div className={"info"}>
                            <p className={"title"}>Code APE</p>
                            <p className={"text"}>{datas.store.legal_datas.ape}</p>
                        </div>
                        <div className={"info"}>
                            <p className={"title"}>TVA Intracommunautaire</p>
                            <p className={"text"}>{datas.store.legal_datas.tvaIntra}</p>
                        </div>
                    </div>
                }
                <div className={"wrap addresses"}>
                    <p className={"title"}>Adresses</p>
                    {
                        addressSelected !== null
                        && <>
                            {
                                (addressSelected.name !== null && addressSelected.name.length > 0)
                                && <div className={"info"}>
                                    <p className={"title"}>Nom</p>
                                    <p className={"text"}>{addressSelected.name}</p>
                                </div>
                            }
                            <div className={"info"}>
                                <p className={"title"}>Type</p>
                                <p className={"text"}>{ AddressController._addressTypesDict[addressSelected.type_name] }</p>
                            </div>
                            {
                                (addressSelected.address !== null && addressSelected.address.length > 0)
                                && <div className={"info"}>
                                    <p className={"title"}>Adresse</p>
                                    <p className={"text"}>{addressSelected.address}</p>
                                </div>
                            }
                            {
                                (addressSelected.additional !== null && addressSelected.additional.length > 0)
                                && <div className={"info"}>
                                    <p className={"title"}>Complément</p>
                                    <p className={"text"}>{addressSelected.additional}</p>
                                </div>
                            }
                            <div className={"info"}>
                                <p className={"title"}>Code postal</p>
                                <p className={"text"}>{ addressSelected.postalCode }</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Ville</p>
                                <p className={"text"}>{ addressSelected.city }</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Pays</p>
                                <p className={"text"}>{ addressSelected.country }</p>
                            </div>
                        </>
                    }
                    <div className={"list"}>
                        {
                            (datas.store.addresses !== undefined && datas.store.addresses.length > 0)
                                ? datas.store.addresses.map((address, index) => (
                                    <div key={index} className={"line" + ((addressSelected !== null && address.id === addressSelected.id) ? " selected" : "")} onClick={() => { selectAddressToDisplay(address) }}>
                                        <div className={"containerName"}>
                                            <p className={"name"}>{address.fullAddress}</p>
                                            <div className={"tags"}>
                                                {
                                                    address.main
                                                    && <p className={"tag"}>Principale</p>
                                                }
                                                <p className={"tag"}>{ AddressController._addressTypesDict[address.type_name] }</p>
                                            </div>
                                        </div>
                                        <EditIcon classname={"edit"} handleClick={() => { updateAddress(address) }}/>
                                    </div>
                                ))
                                : <div className={"line empty"}>
                                    <div className={"containerName"}>
                                        <p className={"name"}>Aucune adresse</p>
                                    </div>
                                </div>
                        }
                    </div>
                    <button className={"button"} onClick={addAddress}>
                        <AddIcon />
                        <p>Ajouter une adresse</p>
                    </button>
                </div>
            </div>
                </div>
                <div className={"wrapHistory"}>
                    <div className={"history"}>
                        {
                            datas.store.histories.map((history, index) => (
                                <div key={index} className={"item"}>
                                    <p className={"creator"}>Par <b>{ history.creator_name }</b></p>
                                    <p className={"date"}>le <b>{ moment(history.created_at).format("DD/MM/YYYY HH:mm") }</b>, il y a { moment(history.created_at).fromNow(true) }</p>
                                    <p className={"comment"}>{ history.comment }</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className={"input"}>
                        <input type={"text"} value={entryHistory} placeholder={"Inscrire une note"} onChange={inputEntry} />
                        <button onClick={saveHistory}>
                            {
                                savingHistory
                                    ? <LoaderCircle display="loader" hide="" strokeWidth="10" stroke="#2D2D2D" />
                                    : "Enregistrer"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ClientInformations;