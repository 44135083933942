import Rest from "./Rest";

class GoogleLocation {
    _APIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    #_rest = new Rest()
    _callback = null

    searchAddress(input) {
        this.#_rest._external = true
        this.#_rest.sendQuery(
            'GET',
            'https://maps.googleapis.com/maps/api/place/textsearch/json?key=' + this._APIKey + '&query=' + encodeURIComponent(input),
            {},
            this.returnSearchAddress)
    }
    returnSearchAddress = (response, error, status) => {
        this._callback(response, error, status)
    }

    geocoding(input) {
        this.#_rest._external = true
        this.#_rest.sendQuery(
            'GET',
            'https://maps.googleapis.com/maps/api/geocode/json?key=' + this._APIKey + '&address=' + encodeURIComponent(input),
            {},
            this.returnGeocoding)
    }
    returnGeocoding = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default GoogleLocation
