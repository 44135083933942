import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../css/page/Actions.css"
import "../../../css/form/Form.css"

const Actions = props => {
    const { page } = props
    const [ buttonsModel, setButtonsModel ] = useState([])

    const listButtons = () => {
        let buttons = []

        switch (page) {
            case "client":
                buttons.push({
                    title: "Nouveau",
                    classname: "add green",
                    link: "/clients/new"
                })
                setButtonsModel(buttons)

                break
            case "product":
                buttons.push({
                    title: "Nouveau",
                    classname: "add green",
                    link: "/products/new"
                })
                setButtonsModel(buttons)
                break
            default:
                setButtonsModel(buttons)
                break
        }
    }
    const buildButton = (button, index) => {
        if (button.link !== undefined) {
            return <Link key={ index } to={ button.link }>
                <p key={index} className={ "button" + (button.classname !== undefined ? " " + button.classname : "")}>
                    <span>{ button.title }</span>
                </p>
            </Link>
        }
        else if (button.action !== undefined) {
            return <p key={index} className={ "button" + (button.classname !== undefined ? " " + button.classname : "")} onClick={ button.action }>
                <span>{ button.title }</span>
            </p>
        }
    }

    useEffect(() => {
        listButtons()
    }, [page])

    return (
        <div className="wrapActions">
            <div className={ "buttons " + page }>
                {
                    buttonsModel.map((button, index) => buildButton(button, index))
                }
            </div>
        </div>
    )
}

export default Actions
