import React, {useContext, useEffect} from "react";
import LoaderCircle from "../../tools/loader/LoaderCircle";
import FormSheet from "../sheet/FormSheet";
import FrontSheet from "../sheet/FrontSheet";
import FormPwd from "../../form/account/FormPwd";
import AppContext from "../../../context/AppContext";

const Popup = () => {
    const { objectPopup, setStatePopup, getDatasPopup, setGetDatasPopup, statePopup, popupLoadingText, popupLoadingIcon } = useContext(AppContext);

    const buildContainer = () => {
        switch (statePopup) {
            case "loading":
                return <div className={"containerLoading"}>
                    <LoaderCircle display="loader inPopup" hide="" strokeWidth="10" stroke="#00000070" />
                    {
                        popupLoadingText !== null
                        && <p className={"loadingText"}>{ popupLoadingText }</p>
                    }
                </div>

            case "new":
            case "sheet":
                return buildContent()

            default: break
        }
    }
    const buildContent = () => {
        switch (objectPopup) {
            case "client":
                switch (statePopup) {
                    case "new":
                        return <FormSheet
                            objectType={ objectPopup }
                            datas={ getDatasPopup }
                            parentDatas={ null }
                            title={ "Nouveau client" }
                            previousLink={ "/clients" }
                            successLink={ "/clients/:id/informations" } />
                    case "sheet":
                        return <FrontSheet
                            objectType={ objectPopup }
                            datas={ getDatasPopup }
                            previousLink={ "/clients" } />
                    default: break
                }

                break
            case "userPassword":
                switch (statePopup) {
                    case "new":
                        return <FormPwd
                            object={ getDatasPopup.user }
                            title={ "Définir votre mot de passe" } />
                    default: break
                }

                break
            default: break
        }
    }

    useEffect(() => {
        if (statePopup === null || statePopup === "hidden") {
            setGetDatasPopup(null);

            if (statePopup === "hidden")
                setStatePopup(null);
        }
    }, [statePopup]);

    return (
        <div className={"overlay" + ((statePopup !== null && statePopup !== "hidden") ? " appear" : "")}>
            <div className={"wrapPopup"}>
                <div className={"popup" + (objectPopup !== null ? " " + objectPopup : "") + ((statePopup !== null && statePopup !== "hidden") ? " " + statePopup : "")}>
                    { buildContainer() }
                </div>
            </div>
        </div>
    )
}

export default Popup;