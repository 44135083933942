import React, {useContext, useEffect} from "react";
import $ from "jquery";
import AppContext from "../../../context/AppContext";
import "../../../css/valid/Valid.css";

const Valid = props => {
    const { text } = props;
    const { setValid } = useContext(AppContext);

    const cleanError = () => {
        $(".Valid").addClass("disappear");

        setTimeout(() => {
            setValid(null);
        }, 500);
    }

    useEffect(() => {
        let timeout = setTimeout(() => { cleanError() }, 5000);

        return() => {
            clearTimeout(timeout);
        }
    }, []);

    return (<p className={"Valid"}>{ text }</p>)
}

export default Valid;
