import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Filters from "./Filters";
import Actions from "./Actions";
import User from "../userBlock/User";
import FilterContext from "../../../context/FilterContext";
import "../../../css/page/Header.css";
import "../../../css/form/Form.css";
import "moment/locale/fr";

const Header = props => {
    moment.locale("fr");
    const { page, handleShowCalendarTool } = props;
    const { filters, setFilters } = useContext(FilterContext);
    const [ date, setDate ] = useState("");
    const [ hours, setHours ] = useState("");
    const [ minutes, setMinutes ] = useState("");
    const [ visible, setVisible ] = useState(true);

    const timer = on => {
        setTimeout(() => { timer(!on) }, 500)

        let date = new Date()
        let hour = date.getHours()
        let min = date.getMinutes()

        if (hour < 10) hour = "0" + hour
        else hour = hour.toString()

        if (min < 10) min = "0" + min
        else min = min.toString()

        setVisible(on)
        setDate(moment(date).format("dddd, Do MMMM, YYYY"))
        setHours(hour)
        setMinutes(min)
    }

    useEffect(() => {
        timer(true);
    }, []);

    return (
        <div className="header">
            <div className="wrapHeader">
                <div className="wrapDate">
                    <p className="hour">
                        { hours }
                        <span className={ "dot" + (visible ? " visible" : "") }>:</span>
                        { minutes }
                    </p>
                    <p className="date">{ date }</p>
                </div>
                <User />
            </div>
            <div className="wrapTitle">
                <Filters page={ page } values={ filters } setValues={ setFilters } />
                <Actions page={ page } />
            </div>
        </div>
    )
}

export default Header;
