import React, {useContext, useEffect, useState} from "react";
import FormBuilder from "../../../class/tool/FormBuilder";
import FilterContext from "../../../context/FilterContext";
import "../../../css/page/Filters.css";
import "../../../css/form/Form.css";

const Filters = props => {
    const { page } = props;
    const { filters, setFilters } = useContext(FilterContext);
    const [ filtersModel, setFiltersModel ] = useState([]);

    const initValues= () => {
        switch (page) {
            case "dashboard":
                setFilters(prev => ({
                    ...prev
                }))
                break
            case "client":
                setFilters(prev => ({
                    ...prev,
                    type: "all",
                    state: "all",
                    version: "all",
                    search: ""
                }))
                break
            case "product":
                setFilters(prev => ({
                    ...prev,
                    category: "all",
                    search: ""
                }))
                break
            default: break
        }
    }
    const listFilters = () => {
        switch (page) {
            case "dashboard":
                setFiltersModel([])
                break
            case "client":
                setFiltersModel([
                    {
                        attribute: "type",
                        inputType: "tab",
                        returnType: "string",
                        title: "",
                        list: [
                            {
                                text: "all",
                                id: 0
                            },
                            {
                                text: "client",
                                id: 1
                            },
                            {
                                text: "prospect",
                                id: 2
                            }
                        ],
                        dictionary: {
                            "all": "Tous",
                            "prospect": "Prospect",
                            "client": "Client"
                        },
                        classnameInput: "borderRadiusMore"
                    },
                    {
                        attribute: "state",
                        inputType: "select",
                        returnType: "string",
                        title: "État",
                        list: [
                            {
                                text: "all",
                                id: 0
                            },
                            {
                                text: "active",
                                id: 1
                            },
                            {
                                text: "inactive",
                                id: 2
                            },
                            {
                                text: "deleted",
                                id: 3
                            }
                        ],
                        dictionary: {
                            "all": "Tous les états",
                            "active": "Actif",
                            "inactive": "Inactif",
                            "deleted": "Supprimé"
                        },
                        classnameInput: "borderRadiusMore size1"
                    },
                    {
                        attribute: "version",
                        inputType: "select",
                        returnType: "string",
                        title: "Version",
                        list: [
                            {
                                text: "all",
                                id: 0
                            },
                            {
                                text: "1.6.0",
                                id: 1
                            },
                            {
                                text: "1.5.1",
                                id: 2
                            },
                            {
                                text: "1.5.0",
                                id: 3
                            },
                            {
                                text: "1.4.2",
                                id: 4
                            },
                            {
                                text: "1.4.1",
                                id: 5
                            },
                            {
                                text: "1.4.0",
                                id: 6
                            }
                        ],
                        dictionary: {
                            "all": "Toutes versions",
                            "1.6.0": "1.6.0",
                            "1.5.1": "1.5.1",
                            "1.5.0": "1.5.0",
                            "1.4.2": "1.4.2",
                            "1.4.1": "1.4.1",
                            "1.4.0": "1.4.0",
                        },
                        classnameInput: "borderRadiusMore size1"
                    },
                    {
                        attribute: "search",
                        inputType: "text",
                        returnType: "string",
                        icon: "search",
                        placeholder: "Rechercher",
                        value: "",
                        classnameInput: "borderRadiusMore border size2"
                    }
                ])

                break
            case "product":
                setFiltersModel([
                    {
                        attribute: "state",
                        inputType: "select",
                        title: "Catégorie",
                        list: [
                            {
                                text: "all",
                                id: 0
                            }
                        ],
                        dictionary: {
                            "all": "Toutes les catégories"
                        },
                        classnameInput: "size3"
                    },
                    {
                        attribute: "search",
                        inputType: "text",
                        returnType: "string",
                        icon: "search",
                        placeholder: "Rechercher",
                        value: "",
                        classnameInput: "size2"
                    }
                ])
                break
            default: break
        }
    }
    const buildFilter = (filter, index) => {
        return <div key={index} className="filter">
            {
                FormBuilder.buildInputByType(filter, filters, [], change)
            }
        </div>
    }
    const change = (attribute, returnType, val, strict = false) => {
        let rows = filtersModel
        let index

        switch (returnType) {
            default:
                let value = FormBuilder.buildVal(returnType, val);
                let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
                index = value;

                if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
                    value = parseInt(filtered[0].list[index].id);

                    if (filtered[0].list[index].type !== undefined && filters[attribute.replace("_id", "_type")] !== undefined) {
                        setFilters(prev => ({
                            ...prev,
                            [attribute]: value,
                            [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                        }));
                    }
                    else {
                        setFilters(prev => ({
                            ...prev,
                            [attribute]: value
                        }));
                    }
                }
                else {
                    filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

                    if (filtered.length > 0) {
                        if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                            value = null
                    }

                    setFilters(prev => ({
                            ...prev,
                            [attribute]: value
                        }))
                }

                if (attribute === "category_id") {
                    if (filtered.length > 0 && filtered[0].list.length > 0 && filtered[0].list[index].object !== undefined) {
                        change(rows, "vat_rate_id", "int", filtered[0].list[index].object.vat.id, true);
                    }
                }

                break;
        }
    }

    useEffect(() => {
        initValues()
    }, [page])
    useEffect(() => {
        if (Object.keys(filters).length > 0)
            listFilters()
    }, [filters])

    return (
        <div id={"wrapFilters"} className={"wrapFilters"}>
            <div className={ "filters " + page }>
                {
                    filtersModel.map((filter, index) => buildFilter(filter, index))
                }
                <div className="clearing" />
            </div>
        </div>
    )
}

export default Filters;
