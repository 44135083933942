import React, {useContext, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import moment from "moment";
import CloseRegularIcon from "../../icons/CloseRegularIcon";
import ClientInformations from "../../page/contents/client/ClientInformations";
import ClientEnvironnement from "../../page/contents/client/ClientEnvironnement";
import LoaderCircle from "../../tools/loader/LoaderCircle";
import StoreController from "../../../stories/_client/Stores/StoreController";
import AppContext from "../../../context/AppContext";
import "../../../css/overbox/sheet/FrontSheet.css";
import "moment/locale/fr";

const FrontSheet = props => {
    moment.locale("fr");
    const { objectType, datas, previousLink } = props;
    const { setValid, setError, setGetDatasPopup, setStatePopup, setPopupLoadingText, setPopupLoadingIcon } = useContext(AppContext);
    const [ confirmView, setConfirmView ] = useState(null);
    const params = useParams();
    const locationParams = useLocation();
    let navigate = useNavigate();

    const buildTitle = () => {
        switch (objectType) {
            case "client":
                return <>
                    <div className={"containerHeader"}>
                        <p className={"header1"}>
                            {
                                (datas.store.company_name !== null && datas.store.company_name.length > 0)
                                && <span className={"pre"}>{ datas.store.company_name.toLocaleUpperCase() }</span>
                            }
                            { datas.store.name.toLocaleUpperCase() }
                            {
                                datas.store.code !== null
                                && <span className={"post"}>#{ datas.store.code }</span>
                            }
                        </p>
                        <p className={"header2"}>
                            Il y a { moment(datas.store.created_at).fromNow(true) },
                            Par <b>{ datas.store.creator_name }</b> le <b>{ moment(datas.store.created_at).format("DD/MM/YYYY à HH:mm") }</b>
                        </p>
                    </div>
                    {
                        datas.store.code === null
                        && <>
                            <select className={"state"} value={ datas.store.prospect_state.toLocaleUpperCase() } onChange={ changeProspectState }>
                                <option value={"ACTIVE"}>Actif</option>
                                <option value={"STANDBY"}>Inactif</option>
                                <option value={"EJECT"}>Supprimé</option>
                            </select>
                            <button className={"button"} onClick={ callSwitchToClient }>Basculer en client</button>
                        </>
                    }
                </>;
            default: break;
        }
    }
    const buildNavigation = () => {
        let navigationLinks = [];

        switch (objectType) {
            case "client":
                navigationLinks = [
                    {
                        "title": "Informations",
                        "link": "/clients/:id/informations",
                        "flag": "informations"
                    },
                    {
                        "title": "Environnement",
                        "link": datas.store.code === null ? "#" : "/clients/:id/environnement",
                        "flag": "environnement",
                        "classname": datas.store.code === null ? "disable" : ""
                    }
                    /*{
                        "title": "Tickets",
                        "link": "/clients/:id/tickets",
                        "flag": "tickets",
                        "classname": datas.store.code === null ? "disable" : "inProgress"
                    },
                    {
                        "title": "Contrats",
                        "link": "/clients/:id/contracts",
                        "flag": "contracts",
                        "classname": datas.store.code === null ? "disable" : "inProgress"
                    },
                    {
                        "title": "Devis",
                        "link": "/clients/:id/quotes",
                        "flag": "quotes",
                        "classname": "inProgress"
                    },
                    {
                        "title": "Bons de livraison",
                        "link": "/clients/:id/vouchers",
                        "flag": "vouchers",
                        "classname": datas.store.code === null ? "disable" : "inProgress"
                    },
                    {
                        "title": "Factures",
                        "link": "/clients/:id/bills",
                        "flag": "bills",
                        "classname": datas.store.code === null ? "disable" : "inProgress"
                    }*/
                ];

                return <nav className={"containerNavigation"}>
                    {
                        navigationLinks.map((item, index) => (
                            <Link key={index} to={item.link.replace(":id", datas.store.id)}>
                                <p className={"item" + (item.classname !== undefined ? " " + item.classname : "") + (locationParams.pathname.includes(item.flag) ? " selected" : "")}>
                                    {item.title}
                                </p>
                            </Link>
                        ))
                    }
                </nav>
        ;
    default:
        break;
    }
}
    const buildContent = () => {
        switch (objectType) {
            case "client":
                if (locationParams.pathname.includes("informations")) {
                    return <ClientInformations inDatas={datas} />;
                }
                else if (locationParams.pathname.includes("environnement")) {
                    return <ClientEnvironnement inDatas={datas} />;
                }

                break;
            default:
                break;
        }
    }
    const abortConfirmView = () => {
        setConfirmView(null);
    }
    const close = () => {
        /*
        setConfirmView({
            title: "Hop hop hop !",
            question: "Êtes-vous sûr de vouloir fermer cette vue ?",
            textConfirm: "Oui",
            textAbort: "Non",
            confirm: confirmClose,
            abort: abortConfirmView,
            loading: false
        });
         */
        confirmClose();
    }
    const confirmClose = () => {
        abortConfirmView();
        navigate(previousLink, { state: {  } });
    }
    const changeProspectState = event => {
        const controller = new StoreController();
        controller._callback = returnChangeProspectState;
        controller.put(datas.store, { prospect_state: event.currentTarget.value });
    }
    const returnChangeProspectState = (response, error, status) => {
        switch (status)
        {
            case 200:
                setValid("Statut du prospect mise à jour");
                setGetDatasPopup({
                    store: response.data
                })
                break;
            default:
                setError("Une erreur s'est produite lors de la mise à jour du statut");
                break;
        }
    }
    const callSwitchToClient = () => {
        setConfirmView({
            title: "Basculer en tant que client",
            question: "Souhaitez-vous transformer le prospect en tant que client ?",
            textConfirm: "Oui",
            textAbort: "Non",
            confirm: switchToClient,
            abort: abortConfirmView,
            loading: false
        })
    }
    const switchToClient = () => {
        setConfirmView(prev => ({
            ...prev,
            loading: true
        }));

        const controller = new StoreController();
        controller._callback = returnSwitchToClient;
        controller.toClient(datas.store);
    }
    const returnSwitchToClient = (response, error, status) => {
        setConfirmView(prev => ({
            ...prev,
            loading: false
        }));

        switch (status) {
            case 201:
                abortConfirmView();
                setValid("Bravo ! Le prospect est maintenant un client");
                setGetDatasPopup({
                    store: response.data
                })
                break;
            default:
                setError("Une erreur s'est produite pour basculer le prospect vers client");
                break;
        }
    }

    return (
        datas !== null
            ? <>
                <div className={"overlay" + (confirmView !== null ? " appear" : "")}>
                    <div className={"wrapPopup"}>
                        <div className={"popup alert"}>
                            <p className={"title"}>{ confirmView !== null ? confirmView.title : "" }</p>
                            <p className={"question"}>{ confirmView !== null ? confirmView.question : "" }</p>
                            <div className={"form"}>
                                <div className={"containerButtons margin"}>
                                    <button className={"button cancel"} onClick={confirmView !== null ? confirmView.abort : null}>{confirmView !== null ? confirmView.textAbort : ""}</button>
                                    <button className={"button submit"} onClick={confirmView !== null ? confirmView.confirm : null}>
                                        {
                                            confirmView !== null
                                                && <p className={"text"}>
                                                    {
                                                        confirmView.loading
                                                            ? <LoaderCircle display="loader" strokeWidth="8" stroke={ "#FFFFFF" } />
                                                            : confirmView.textConfirm
                                                    }
                                                </p>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"containerHeader"}>
                    <div className={"containerTitle"}>
                        { buildTitle() }
                    </div>
                    <div className={"closeButton"}>
                        <CloseRegularIcon handleClick={close}/>
                    </div>
                </div>
                {buildNavigation()}
                <div className={"containerContent " + objectType}>
                    { buildContent() }
                </div>
            </>
            : <></>
    );
}

export default FrontSheet;