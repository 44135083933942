import React from "react";
import DatePicker, { registerLocale } from  "react-datepicker"
import InputSelect from "../../component/form/input/V2/InputSelect";
import InputText from "../../component/form/input/V2/InputText";
import InputTag from "../../component/form/input/V2/InputTag";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import InputPredefinedTag from "../../component/form/input/V2/InputPredefinedTag";
registerLocale("fr", fr);

class FormBuilderV2 {
    static buildInputByType(row, values, eventsHandler, firstFocus = null) {
        let inputType = row.inputType;

        switch (inputType) {
            case "text":
                return <InputText
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    value={ (values[row.attribute] !== undefined && values[row.attribute] !== null) ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    isDisabled={ row.isDisabled !== undefined ? row.isDisabled : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    focus={ firstFocus !== null ? firstFocus : null }
                    eventsHandler={ eventsHandler }
                />
            case "select":
                return <InputSelect
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    value={ row.returnType === "int" ? FormBuilderV2.getIndexForValue(row, values) : values[row.attribute] }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    isDisabled={ row.isDisabled !== undefined ? row.isDisabled : null }
                    placeholder={ row.placeholder }
                    eventsHandler={ eventsHandler }
                />
            case "tag":
                return <InputTag
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    value={ (values[row.attribute] !== undefined && values[row.attribute] !== null) ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    isDisabled={ row.isDisabled !== undefined ? row.isDisabled : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    focus={ firstFocus !== null ? firstFocus : null }
                    eventsHandler={ eventsHandler }
                />
            case "predefinedTag":
                return <InputPredefinedTag
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    value={ (values[row.attribute] !== undefined && values[row.attribute] !== null) ? values[row.attribute] : "" }
                    tags={ row.tags }
                    tagsDict={ row.tagsDict !== undefined ? row.tagsDict : null }
                    placeholder={ row.placeholder }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    isDisabled={ row.isDisabled !== undefined ? row.isDisabled : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    focus={ firstFocus !== null ? firstFocus : null }
                    eventsHandler={ eventsHandler }
                />
            default:
                break;
        }
    }

    static getIndexForValue(row, values) {
        let value = 0;

        if (row.list === undefined || row.list === null)
            return value;

        for (let i = 0; i < row.list.length; i++) {
            if (row.list[i].type !== undefined && row.list[i].type !== "" && values[row.attribute.replace("_id", "_type")] !== undefined) {
                if (
                    row.list[i].id === values[row.attribute]
                    && row.list[i].type === values[row.attribute.replace("_id", "_type")]
                ) {
                    value = i;
                    break;
                }
            }
            else {
                if (row.list[i].id === values[row.attribute]) {
                    value = i;
                    break;
                }
            }
        }

        return value;
    }
    static buildVal = (returnType, val) => {
        switch (returnType) {
            case "string": return val;
            case "int":
                switch (typeof val) {
                    case "string": return parseInt(val);
                    case "object": return (val !== null ? parseInt(val.toString()) : null);
                    case "undefined": return 0;
                    default: return val;
                }
            case "float": return val.replace(',', '.');
            default: return val;
        }
    }
    static handleChange = (rows, setValues, attribute, returnType, val, strict = false) => {
        let value = FormBuilderV2.buildVal(returnType, val);
        let type = "";
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = filtered[0].list[index].id !== null ? parseInt(filtered[0].list[index].id) : null

            if (filtered[0].list[index].type !== undefined && value[attribute.replace("_id", "_type")] !== undefined) {
                type = filtered[0].list[index].type

                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }

        return {
            value: value,
            type: type,
            filtered: filtered,
            index: index
        }
    }
    static returnUpdates = (object, datas, compare = true) => {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (object[key] !== undefined) {
                if (compare) {
                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue
                            else if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        case "object":
                            if (object[key] === null || (object[key].length === 0 && datas[key].length === 0))
                                continue
                            else if (!this.isEqual(object[key], datas[key]) && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        default:
                            if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break
                    }
                }
                else {
                    if (datas[key] === null)
                        continue

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue

                            break

                        default: break
                    }

                    Reflect.set(updates, key, datas[key])
                }
            }
        }

        return updates
    }
    static isEqual = (obj1, obj2) => {
        if (
            (obj1 === null && obj2 !== null)
            || (obj1 !== null && obj2 === null)
        )
            return false

        let props1 = Object.getOwnPropertyNames(obj1)
        let props2 = Object.getOwnPropertyNames(obj2)

        if (props1.length !== props2.length)
            return false;

        for (let i = 0; i < props1.length; i++) {
            let val1 = obj1[props1[i]]
            let val2 = obj2[props1[i]]
            let isObjects = this.isObject(val1) && this.isObject(val2)

            if (isObjects && !this.isEqual(val1, val2) || !isObjects && val1 !== val2)
                return false
        }

        return true
    }
    static isObject = object => {
        return object != null && typeof object === 'object'
    }
}

export default FormBuilderV2;
