import Entity from "../../../class/abstract/Entity";
import moment from "moment";
import "moment/locale/fr";

class InstallationEnabling extends Entity {
    license_id = null;
    token = "";
    readyDate = null;
    askingDate = null;
    validityPeriod = 0;
    canceled = 0;
    status = 0;
    batch = {};
    accountStatus = {};
    catalogStatus = {};
    clientStatus = {};
    legaldataStatus = {};
    saleStatus = {};
    settingStatus = {};
    statisticStatus = {};

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = ['accountStatus', 'catalogStatus', 'clientStatus', 'legaldataStatus', 'saleStatus', 'settingStatus', 'statisticStatus'];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }

        let status = ['accountStatus', 'catalogStatus', 'clientStatus', 'legaldataStatus', 'saleStatus', 'settingStatus', 'statisticStatus'];

        for (let i in status) {
            if (datas[status[i]] !== undefined && datas[status[i]] !== null)
                Reflect.set(this, status[i], JSON.parse(datas[status[i]]));
        }
    }

    duration() {
        moment.locale("fr");

        let startDate = moment.utc(this.readyDate).toDate();
        let startTime = moment(startDate).add(this.validityPeriod, "minutes");
        let minutes = Math.abs(parseInt(startTime.diff(moment(), "minutes")));

        return minutes > 0 ? minutes + " minutes" : "moins d'une minute";
    }
}

export default InstallationEnabling;
