import React, {useContext, useEffect} from "react";
import LoaderCircle from '../tools/loader/LoaderCircle';

import '../../css/Init.css';

import SessionContext from "../../context/SessionContext";

const Init = props => {
    const {logout} = props;
    const sentences = [
        {
            "sentence": "Un pessimiste voit la difficulté dans chaque opportunité, un optimiste voit l’opportunité dans chaque difficulté.",
            "author": "Churchill"
        },
        {
            "sentence": "N’allez pas où va le chemin. Allez là où il n’y en a pas encore, et ouvrez la route.",
            "author": "Ralph Waldo Emerson"
        },
        {
            "sentence": "Si vous faites toujours ce que vous avez toujours fait, vous n’irez pas plus loin que jusque-là où vous êtes toujours allé.",
            "author": ""
        },
        {
            "sentence": "Les grandes choses ne sont pas faites par la force, mais par la persévérance.",
            "author": "Samuel Johnson"
        },
        {
            "sentence": "Si on me donnait six heures pour abattre un arbre, je passerais la première à affûter la hâche.",
            "author": "Abraham Lincoln"
        },
        {
            "sentence": "L’ignorant affirme, le savant doute, le sage réfléchit.",
            "author": "Aristote"
        },
        {
            "sentence": "Tout est difficile avant de devenir facile.",
            "author": "Thomas Fuller"
        },
        {
            "sentence": "Pour le meilleur et pour l'Empire.",
            "author": "SkyTeam"
        }
    ];
    const selection = sentences[getRandomInt(8)];
    const {handleLogout, loggedIn, user} = useContext(SessionContext);

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    useEffect(() => {
        if(user.email !== undefined) {
            if(logout !== undefined)
                handleLogout();
        }
    });

    return (
        <div className="wrapInit">
            <div className="box">
                {
                    loggedIn !== "LOG_WAIT"
                    && <div className="waitingText">
                        <p className="sentence">{ selection.sentence }</p>
                        <p className="author">{ selection.author }</p>
                    </div>
                }
                <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            </div>
        </div>
    );
}

export default Init;
