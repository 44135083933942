import React from "react";

const CloseIcon = props => {
    const { classname, fill, handleClick } = props;

    return (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={ classname !== undefined ? classname : "" } fill={ fill } onClick={ handleClick !== undefined ? handleClick : () => {} }>
            <path
                clipRule="evenodd"
                d="M0 10C0 4.486 4.486 0 10 0s10 4.486 10 10-4.486 10-10 10S0 15.514 0 10zm7.707-3.707a1 1 0 00-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 101.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414L11.414 10l2.293-2.293a1 1 0 00-1.414-1.414L10 8.586z"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default CloseIcon;
