import React from "react";

function DropDownIcon (props) {
    const { fill } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 493.258 493.258"
            fill={ fill }
        >
            <path
                d="M131.564 219.258h230.13c9.314 0 17.857-5.16 22.203-13.389a25.12 25.12 0 00-1.494-25.895L265.92 9.772A22.485 22.485 0 00247.461 0a22.436 22.436 0 00-18.507 9.658L110.935 179.863a25.1 25.1 0 00-1.607 25.941 25.114 25.114 0 0022.236 13.454zm230.13 54.74h-230.13a25.117 25.117 0 00-22.236 13.455 25.099 25.099 0 001.607 25.939l118.019 170.205a22.443 22.443 0 0018.507 9.66 22.485 22.485 0 0018.459-9.772L382.403 313.28a25.119 25.119 0 001.494-25.895 25.116 25.116 0 00-22.203-13.387z"
                data-original="#000000"
            />
        </svg>
    );
}

export default DropDownIcon;
