import React, {useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import NotificationIcon from "../../icons/NotificationIcon";
import MessageIcon from "../../icons/MessageIcon";
import Logout from "./Logout";
import userPic from "../../../image/ressource/user/user.png";
import "../../../css/page/User.css";
moment.locale("fr");

const User = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [ numberMessages, setNumberMessages ] = useState(0);
    const [ numberNotifications, setNumberNotifications ] = useState(0);

    const defineHello = () => {
        let date = new Date();
        let hour = date.getHours();

        if (hour < 18)
            return "Bonjour"
        else
            return "Bonsoir"
    }
    const getMessages = () => {

    }
    const getNotifications = () => {

    }

    return (
        <div className="user">
            <div className="wrapUser">
                <div className="wrapPicture">
                    <img src={ userPic } alt="user" />
                </div>
                <div className="wrapName">
                    <p className="hello">{ defineHello() },</p>
                    <p className="name">{ (user.firstname + " " + user.lastname).trim() }</p>
                </div>
            </div>
            <div className="wrapIcons">
                {
                    /*
                    <div className={ "bubble messages" + (numberMessages > 0 ? "  visible" : "") }>
                        <p className="number">{ numberMessages }</p>
                    </div>
                    <div className={ "bubble notifications" + (numberNotifications > 0 ? "  visible" : "") }>
                        <p className="number">{ numberNotifications }</p>
                    </div>
                    <MessageIcon />
                    <NotificationIcon />
                     */
                }
                <Logout />
            </div>
        </div>
    );
}

export default User;
