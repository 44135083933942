import Entity from "../../../class/abstract/Entity"

class Company extends Entity {
    skytill_id = null
    name = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = []

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default Company
