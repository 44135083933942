import Entity from "../../../class/abstract/Entity";
import StringTools from "../../../class/tool/StringTools";
import moment from "moment/moment";

class Account extends Entity {
    lastname = "";
    firstname = "";
    email = "";
    companyRules = "";
    rules = "ADMIN";
    status = 0;
    validity = null;
    onlyHivy = 0;
    fullname = null;
    fullnameLimited = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }

        let firstname = this.firstname !== null ? this.firstname : "";
        let lastname = this.lastname !== null ? this.lastname.replace(" (SKYTILL)", "") : "";
        Reflect.set(this, "fullname", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase()).trim());
        Reflect.set(this, "fullnameLimited", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase().charAt(0)).trim());
    }
    buildStrRules(type) {
        let strRules = "";
        let rulesToExplode = type === "company" ? this.companyRules : this.rules

        if (rulesToExplode.length === 0)
            return "aucun"

        if (rulesToExplode.includes("|")) {
            let explodeRules = rulesToExplode.split("|")

            for (let i in explodeRules) {
                if (strRules.length > 0) strRules += ", "
                strRules += this.ruleName(explodeRules[i].trim())
            }
        }
        else
            strRules = this.ruleName(rulesToExplode)

        return strRules
    }
    ruleName(rule) {
        switch (rule) {
            case "SKYTECH": return "[TECHNICIEN] Administrateur";
            case "CONTROLLER": return "Contrôleur";
            case "ADMIN": return "Administrateur";
            case "ACCOUNTANT": return "Comptable";
            case "ANALYST": return "Analyste";
            case "TECH": return "Technicien";
            case "PRODUCTS": return "Catalogue";
            case "MANAGER": return "Manager";
            default: return "";
        }
    }
    duration() {
        if (this.validity === null)
            return;

        moment.locale("fr");

        let endValidityTime = moment(this.created_at).add(this.validity, "minutes");
        let hours = Math.abs(parseInt(moment().diff(endValidityTime, "hours")));

        if (hours < 1) {
            let minutes = Math.abs(parseInt(moment().diff(endValidityTime, "minutes")));
            return minutes > 0 ? minutes + " minutes" : "moins d'une minute";
        }
        else
            return hours + " heure" + (hours > 1 ? "s" : "");
    }
}

export default Account;
