import Entity from "../../../class/abstract/Entity";
import moment from "moment/moment";

class StoreHistory extends Entity {
    store_id = null;
    created_by = null;
    comment = null;
    creator_name = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""
        let i = 0

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = [];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default StoreHistory;
