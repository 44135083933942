import React, {useEffect, useState} from "react";
import $ from "jquery";
import Menu from "./menu/Menu";
import Header from "./header/Header";
import Error from "../tools/error/Error";
import Warning from "../tools/warning/Warning";
import Valid from "../tools/valid/Valid";
import Content from "./contents/Content";
import MyCalendar from "../tools/MyCalendar/MyCalendar";
import AppContext from "../../context/AppContext";
import FilterContext from "../../context/FilterContext";
import CloseIcon from "../icons/CloseIcon";
import Popup from "../overbox/popup/Popup";
import "../../css/page/Site.css";

const Site = props => {
    const { page } = props;
    const [ error, setError ] = useState(null);
    const [ warning, setWarning ] = useState(null);
    const [ valid, setValid ] = useState(null);
    const [ bubbleText, setBubbleText ] = useState(null);
    const [ objectPopup, setObjectPopup ] = useState(null);
    const [ getDatasPopup, setGetDatasPopup ] = useState(null);
    const [ statePopup, setStatePopup ] = useState(null);
    const [ subStatePopup, setSubStatePopup ] = useState(null);
    const [ popupLoadingIcon, setPopupLoadingIcon ] = useState(null);
    const [ popupLoadingText, setPopupLoadingText ] = useState(null);
    const [ nbPage, setNbPage ] = useState(1);
    const [ filters, setFilters ] = useState([]);
    const [ showCalendarTool, setShowCalendarTool ] = useState(false);

    const handleShowCalendarTool = () => {
        setShowCalendarTool(!showCalendarTool);
    }
    const bubbleHunter = () => {
        if (bubbleText === null) {
            window.removeEventListener('mousemove', moveBubble, false)
        }
        else {
            window.addEventListener('mousemove', moveBubble, false)
        }
    }
    const moveBubble = event => {
        let idBubbleItem = "#bubble"
        if ($(idBubbleItem).length === 0) return

        let posX = event.pageX
        let posY = event.pageY

        if ((posX + 10 + $(idBubbleItem).width()) >= ($(document).width() - 60)) {
            posX -= (60 + $(idBubbleItem).width());
        }

        $(idBubbleItem).css({
            left: (posX + 10),
            top: (posY - 35),
        });
    }

    useEffect(() => {
        bubbleHunter();
    }, [bubbleText]);

    const appContextValues = {
        setError,
        setWarning,
        setValid,
        setBubbleText,
        objectPopup,
        setObjectPopup,
        getDatasPopup,
        setGetDatasPopup,
        statePopup,
        setStatePopup,
        subStatePopup,
        setSubStatePopup,
        popupLoadingIcon,
        setPopupLoadingIcon,
        popupLoadingText,
        setPopupLoadingText
    };
    const sessionContextValues = {
        nbPage,
        setNbPage,
        filters,
        setFilters
    };

    return (
        <AppContext.Provider value={appContextValues}>
            <FilterContext.Provider value={sessionContextValues}>
                <div className="site">
                    <div id={"bubble"} className={"bubbleHover" + (bubbleText !== null ? " visible" : "")}><p>{ bubbleText }</p></div>
                    <Header page={ page } handleShowCalendarTool={ handleShowCalendarTool } />
                    <Menu page={ page } />
                    <div className="page">
                        <Content page={ page } />
                    </div>
                    <Popup />
                    {
                        valid !== null
                        && <Valid text={ valid } />
                    }
                    {
                        warning !== null
                        && <Warning text={ warning } />
                    }
                    {
                        error !== null
                        && <Error text={ error } />
                    }
                    <div className={ "calendarTool" + (showCalendarTool ? " visible" : "") }>
                        <CloseIcon classname="close" handleClick={ handleShowCalendarTool } />
                        <MyCalendar />
                    </div>
                </div>
            </FilterContext.Provider>
        </AppContext.Provider>
    )
}

export default Site;
