import Entity from "../../../class/abstract/Entity"
import Tag from "../Tags/Tag"
import StringTools from "../../../class/tool/StringTools"

class Contact extends Entity {
    skytill_id = null
    partnership_id = null
    firstname = null
    lastname = null
    fullname = null
    fullnameLimited = null
    gender = null
    email = null
    phone = null
    mobile = null
    companies_list = []
    stores_list = []
    tags = []

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if (key === "tags")
                    this.initTags(datas.tags)
                else {
                    // except keys
                    let exceptKeys = []

                    if (!exceptKeys.includes(key))
                        Reflect.set(this, key, datas[key])
                }
            }
        }

        let firstname = this.firstname !== null ? this.firstname : ""
        let lastname = this.lastname !== null ? this.lastname : ""
        Reflect.set(this, "fullname", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase()).trim())
        Reflect.set(this, "fullnameLimited", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase().charAt(0)).trim())
    }

    initTags(tags) {
        for(let i in tags)
            this.tags.push(new Tag(tags[i]))
    }
}

export default Contact
