import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router";
import LoaderCircle from "../tools/loader/LoaderCircle";
import FormBuilderV2 from "../../class/tool/FormBuilderV2";
import CompanyController from "../../stories/_client/Companies/CompanyController";
import StoreController from "../../stories/_client/Stores/StoreController";
import LegalDataController from "../../stories/_client/LegalDatas/LegalDataController";
import ContactController from "../../stories/_client/Contacts/ContactController";
import AddressController from "../../stories/_client/Addresses/AddressController";
import Store from "../../stories/_client/Stores/Store";
import LegalData from "../../stories/_client/LegalDatas/LegalData";
import Address from "../../stories/_client/Addresses/Address";
import Contact from "../../stories/_client/Contacts/Contact";
import AppContext from "../../context/AppContext";
import "../../css/form/FormV2.css";
import TagController from "../../stories/_client/Tags/TagController";
import LicenseController from "../../stories/_setup/Licenses/LicenseController";
import AccountController from "../../stories/_setup/Accounts/AccountController";
import Account from "../../stories/_setup/Accounts/Account";

const PresentForm = props => {
    const { objectType, datas, parentDatas, title, previousLink, successLink, callbackClose, callbackSuccess } = props;
    const { setError } = useContext(AppContext);
    const [ loading, setLoading ] = useState(true);
    const [ object, setObject ] = useState(null);
    const [ values, setValues ] = useState(null);
    const [ rows, setRows ] = useState([]);
    const [ stateSubmit, setStateSubmit ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const navigate = useNavigate();

    const buildController = () => {
        switch (objectType) {
            case "client":
            case "assignUser":
                return new StoreController();
            case "newLegalData":
                return new LegalDataController();
            case "newContact":
                return new ContactController();
            case "newAddress":
                return new AddressController();
            case "updateLicense":
                return new LicenseController();
            case "addAccount":
            case "addAccountController":
            case "addAccountContact":
                return new AccountController();
            default:
                return null;
        }
    }
    const initObject = () => {
        switch (objectType) {
            case "client":
                setObject(new Store());
                break;
            case "assignUser":
                setObject(parentDatas.store);
                break;
            case "newLegalData":
                setObject(new LegalData());
                break;
            case "newContact":
                setObject(new Contact());
                break;
            case "newAddress":
                setObject(new Address());
                break;
            case "updateLicense":
                setObject(datas.license);
                break;
            case "addAccount":
            case "addAccountController":
            case "addAccountContact":
                setObject(new Account());
                break;
            default:
                setError("Type d'objet inconnu");
                setLoading(false);
                break;
        }
    }
    const initValues = () => {
        const controller = buildController();

        switch (objectType) {
            case "assignUser":
                controller.setFormAssignUserValues(object, setValues);
                break;
            case "updateLicense":
                controller.setFormValues(object, setValues, false, datas);
                break;
            case "addAccountController":
                controller.setFormControllerValues(setValues);
                break;
            case "addAccountContact":
                controller.setFormFromContactValues(object, setValues, datas);
                break;
            default:
                controller.setFormValues(object, setValues, true, datas);
                break;
        }
    }
    const initDatas = () => {
        const controller = buildController();

        switch (objectType) {
            case "assignUser":
                controller.setFormAssignUserRows(setRows, datas);
                break;
            case "updateLicense":
                controller.setFormRows(setRows, false, datas);
                break;
            case "addAccountController":
                controller.setFormControllerRows(setRows);
                break;
            case "addAccountContact":
                controller.setFormFromContactRows(setRows, datas);
                break;
            default:
                controller.setFormRows(setRows, true, datas);
                break;
        }
    }
    const goPrevious = () => {
        if (previousLink !== undefined && previousLink !== null)
            navigate(previousLink, { state: {} });
        else if (callbackClose !== undefined && callbackClose !== null)
            callbackClose();
    }
    const goSuccess = (id = null) => {
        if (successLink !== undefined && successLink !== null && id !== null)
            navigate(successLink.replace(":id", id), { state: { refresh: true } });
        else if (callbackSuccess !== undefined && callbackSuccess !== null)
            callbackSuccess();
    }
    const submit = () => {
        if (!stateSubmit || saving)
            return;

        setSaving(true);

        const controller = buildController();
        const datasToSend = getDatas(controller);

        if (datasToSend.length === 0)
        {
            setSaving(false);
            return;
        }

        controller._callback = resultSubmit;

        send(controller, datasToSend);
    }
    const getDatas = controller => {
        switch (objectType) {
            case "assignUser":
                return controller.returnPostAssignUserDatas(values);
            case "client":
            case "newLegalData":
            case "newContact":
            case "newAddress":
            case "addAccount":
                return controller.returnPostDatas(values);
            case "addAccountController":
                return controller.returnPostControllerDatas(values);
            case "addAccountContact":
                return controller.returnPostFromContactDatas(values);
            case "updateLicense":
                return controller.returnPutDatas(values);
            default:
                return {};
        }
    }
    const send = (controller, datasToSend) => {
        switch (objectType) {
            case "client":
                controller.post(datasToSend);
                break;
            case "assignUser":
                controller.put(datas.store, datasToSend);
                break;
            case "newLegalData":
            case "newContact":
            case "newAddress":
                controller.post(datas.store, datasToSend);
                break;
            case "addAccount":
                controller.addAccount(datas.store.setup, datasToSend);
                break;
            case "addAccountController":
                controller.registerControllerAccount(datas.store.setup, datasToSend);
                break;
            case "addAccountContact":
                let datasRegisterAccounts = {
                    contacts: [datasToSend.contact_id],
                    rule: datasToSend.rule
                }

                if (datasToSend.companyRule !== undefined && datasToSend.companyRule !== null)
                    datasRegisterAccounts.companyRule = datasToSend.companyRule;

                controller.registerAccounts(datas.store.setup, datasRegisterAccounts);
                break;
            case "updateLicense":
                controller.put(datas.license, datasToSend);
                break;
            default:
                break;
        }
    }
    const resultSubmit = (response, error, status) => {
        setSaving(false);

        switch (objectType) {
            case "client":
                switch (status) {
                    case 201:
                        goSuccess(response.data.id);
                        break;
                    default:
                        setError("Une erreur s'est produite lors de la création du client");
                        break;
                }

                break;
            case "assignUser":
                switch (status) {
                    case 200:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de l'enregistrement de l'utilisateur [" + status + "]");
                        break;
                }

                break;
            case "newLegalData":
                switch (status) {
                    case 201:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de l'enregistrement des données légales");
                        break;
                }

                break;
            case "newContact":
                switch (status) {
                    case 201:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de l'enregistrement du contact");
                        break;
                }

                break;
            case "newAddress":
                switch (status) {
                    case 204:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de l'enregistrement de l'adresse");
                        break;
                }

                break;
            case "addAccount":
                switch (status) {
                    case 204:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de la création du compte BO");
                        break;
                }

                break;
            case "addAccountController":
                switch (status) {
                    case 204:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de la création du compte contrôleur");
                        break;
                }

                break;
            case "addAccountContact":
                switch (status) {
                    case 204:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de l'ajout du compte existant");
                        break;
                }

                break;
            case "updateLicense":
                switch (status) {
                    case 204:
                        goSuccess();
                        break;
                    default:
                        setError("Une erreur s'est produite lors de la modification des informations de la licence");
                        break;
                }

                break;
            default:
                break;
        }
    }
    const change = (attribute, returnType, value) => {
        FormBuilderV2.handleChange(rows, setValues, attribute, returnType, value);
        checkData(attribute, value);
    }
    const checkData = (attribute, value) => {
        const controller = buildController();
        let result = checkingByType(controller, attribute, value);
        let rowsTmp = rows.slice();
        let index = rowsTmp.findIndex(_ => _.attribute === attribute);

        if (rowsTmp[index] === undefined)
            return;

        if (result === null) {
            rowsTmp[index].checkData = null;
            rowsTmp[index].error = null;
        }
        else {
            rowsTmp[index].checkData = result.result ? "valid" : "wrong";
            rowsTmp[index].error = result.error;
        }

        setRows(rowsTmp);
    }
    const checkingByType = (controller, attribute, value) => {
        switch (objectType) {
            case "assignUser":
                return controller.checkDataToAssignUser(attribute, value);
            case "client":
            case "newLegalData":
            case "newContact":
            case "newAddress":
            case "updateLicense":
            case "addAccount":
            case "addAccountController":
            case "addAccountContact":
                return controller.checkDataToPost(attribute, value);
            default:
                return null;
        }
    }
    const defineHandleSearch = row => {
        switch (row.attribute) {
            case "company_name":
                switch (objectType) {
                    case "client":
                        return searchCompany;
                    default:
                        return null;
                }
            case "lastname":
                switch (objectType) {
                    case "newContact":
                        return searchContact;
                    default:
                        return null;
                }
            case "email":
                switch (objectType) {
                    case "addAccountContact":
                        return searchContact;
                    default:
                        return null;
                }
            case "tags":
                switch (objectType) {
                    case "newContact":
                        return searchTag;
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
    const defineHandleSearchColumnName = row => {
        switch (row.attribute) {
            case "company_name":
                return "name";
            case "email":
                switch (objectType) {
                    case "addAccountContact":
                        return "email";
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
    const defineHandleClickOnSearch = row => {
        switch (row.attribute) {
            case "company_name":
                switch (objectType) {
                    case "client":
                        return clickOnCompany;
                    default:
                        return null;
                }
            case "lastname":
                switch (objectType) {
                    case "newContact":
                        return clickOnContact;
                    default:
                        return null;
                }
            case "email":
                switch (objectType) {
                    case "addAccountContact":
                        return clickOnContactAccount;
                    default:
                        return null;
                }
            case "tags":
                switch (objectType) {
                    case "newContact":
                        return clickOnTag;
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
    const buildRows = () => {
        if (loading)
            return <LoaderCircle display="loader logWaitForm" hide="" strokeWidth="8" stroke="#008C4F" />;

        let rowsToDisplay = rows.filter(_ => _.hidden === undefined || !_.hidden);

        return <>
            {
                (title !== undefined && title !== null)
                && <p className={"title"}>{ title }</p>
            }
            <div className={"scroller" + ((title !== undefined && title !== null) ? "withTitle" : "")}>
                <div className={"shadowTop"} />
                <div className={"shadowBottom"} />
                <div className={"containerInputField"}>
                {
                    rowsToDisplay.map((row, index) => (
                        <div key={index} className={ "inputField" + (row.classnameWrapper !== undefined && row.classnameWrapper !== null ? " " + row.classnameWrapper : "") }>
                            {
                                (row.label !== undefined && row.label !== null && row.label.length > 0)
                                && <label className={ row.classnameLabel + (!row.needed ? " optional" : "") } htmlFor={ row.attribute }>{ row.label }</label>
                            }
                            <div className={"field" + (row.checkData !== null ? " " + row.checkData : "")}>
                                {
                                    FormBuilderV2.buildInputByType(
                                        row,
                                        values,
                                        {
                                            handleChange: change,
                                            handleSubmit: submit,
                                            handleSearch: defineHandleSearch(row),
                                            handleSearchColumnName: defineHandleSearchColumnName(row),
                                            handleClickOnSearch: defineHandleClickOnSearch(row)
                                        },
                                        index === 0
                                    )
                                }
                            </div>
                            {
                                (row.additional !== undefined && row.additional !== null)
                                && <p className={"additional"}>{row.additional}</p>
                            }
                            {
                                (row.error !== undefined && row.error !== null)
                                && <p className={"error"}>{row.error}</p>
                            }
                        </div>
                    ))
                }
                </div>
            </div>
        </>;
    }
    const rowsRefreshed = () => {
        if (rows.length === 0)
            return;

        if (loading)
            setLoading(false);

        checkSubmitState();
    }
    const checkSubmitState = () => {
        const controller = buildController();
        setStateSubmit(controller.hasValidMinimumDatas(values, objectType));
    }

    const searchCompany = (input, callback) => {
        const controller = new CompanyController();
        controller._callback = callback;
        controller.index(input, true, 20);
    }
    const clickOnCompany = data => {
        change("company_id", "int", data.id);
        change("company_name", "string", data.text);
    }
    const searchContact = (input, callback) => {
        const controller = new ContactController();
        controller._callback = callback;
        controller.index(input, false, true, 20);
    }
    const clickOnContact = data => {
        change("contact_id", "int", data.id);
        change("lastname", "string", data.text);
    }
    const clickOnContactAccount = data => {
        change("contact_id", "int", data.id);
        change("email", "string", data.text);
    }
    const searchTag = (input, callback) => {
        const controller = new TagController();
        controller._callback = callback;
        controller.index(input, true, 20);
    }
    const clickOnTag = data => {
        change("contact_id", "int", data.id);
        change("lastname", "string", data.text);
    }

    useEffect(() => {
        initObject()
    }, []);
    useEffect(() => {
        if (object !== null)
            initValues()
    }, [object]);
    useEffect(() => {
        if (values !== null && rows.length === 0)
            initDatas();
    }, [values]);
    useEffect(() => {
        rowsRefreshed();
    }, [rows]);

    return (
        <div className="form padding">
            {
                buildRows()
            }
            <div className={"containerButtons"}>
                <button className={"button cancel"} onClick={goPrevious}>Fermer</button>
                <button className={"button submit"} disabled={!stateSubmit} onClick={submit}>
                    {
                        saving
                            ? <>
                                <p className={"text"}>Enregistrement...</p>
                                <LoaderCircle display="loader" strokeWidth="8" stroke="#FFFFFF" />
                            </>
                            : <p className={"text"}>Enregistrer</p>
                    }
                </button>
            </div>
        </div>
    )
}

export default PresentForm;
