import React from "react"

const OptionNbItems = props => {
    return (
        <div className={"overOptions " + props.type + (props.show ? " show" : "")}>
            <nav className="options">
                <p className="itemOption" onClick={ () => { props.handleClick(25) } }>25 lignes</p>
                <p className="itemOption" onClick={ () => { props.handleClick(50) } }>50 lignes</p>
                <p className="itemOption" onClick={ () => { props.handleClick(100) } }>100 lignes</p>
            </nav>
        </div>
    )
}

export default OptionNbItems
