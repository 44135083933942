import React, {useContext, useEffect, useState} from "react";
import $ from "jquery";
import moment from "moment";
import AddIcon from "../../../icons/AddIcon";
import FormSheet from "../../../overbox/sheet/FormSheet";
import LoaderCircle from "../../../tools/loader/LoaderCircle";
import AccessIcon from "../../../icons/AccessIcon";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import SetupController from "../../../../stories/_setup/Setup/SetupController";
import LicenseController from "../../../../stories/_setup/Licenses/LicenseController";
import AppContext from "../../../../context/AppContext";
import "../../../../css/page/contents/client/ClientEnvironnement.css";
import "moment/locale/fr";
import InstallationEnabling from "../../../../stories/_setup/InstallationEnablings/InstallationEnabling";
import AccountController from "../../../../stories/_setup/Accounts/AccountController";

const ClientEnvironnement = props => {
    moment.locale("fr");
    const { inDatas } = props;
    const { setError, setValid, setBubbleText, setGetDatasPopup } = useContext(AppContext);
    const [ datas, setDatas ] = useState(inDatas);
    const [ accountSelected, setAccountSelected ] = useState(null);
    const [ licenseSelected, setLicenseSelected ] = useState(null);
    const [ innerObjectType, setInnerObjectType ] = useState(null);
    const [ innerDatas , setInnerDatas ] = useState(null);
    const [ confirmView, setConfirmView ] = useState(null);
    const [ menuLicense, setMenuLicense ] = useState(null);
    const [ tokenView, setTokenView ] = useState(null);
    const [ accountMenu, setAccountMenu ] = useState(false);
    const [ loadingTechnicianAccount, setLoadingTechnicianAccount ] = useState(false);
    const [ loadingChangeStatus, setLoadingChangeStatus ] = useState(false);

    const getStore = () => {
        const controller = new StoreController();
        controller._callback = returnGetStore;
        controller.show(datas.store.id);
    }
    const returnGetStore = (object, error, status) => {
        switch (status) {
            case 200:
                let datasTmp = Object.assign({}, datas);
                datasTmp.store = object;
                setDatas(datasTmp);
                setGetDatasPopup(prev => ({
                    ...prev,
                    store: object
                }));

                break;
            default:
                break;
        }
    }
    const refreshLicense = id => {
        const controller = new LicenseController();
        controller._callback = returnRefreshLicense;
        controller.show(datas.store.setup.id, id);
    }
    const returnRefreshLicense = (object, error, status) => {
        switch (status) {
            case 200:
                let datasTmp = Object.assign({}, datas);

                if (datasTmp.store.setup !== null) {
                    let indexLicense = datasTmp.store.setup.licenses.findIndex(_ => _.id === object.id);

                    if (indexLicense >= 0) {
                        datasTmp.store.setup.licenses[indexLicense] = object;

                        setDatas(datasTmp);
                        setGetDatasPopup(prev => ({
                            ...prev,
                            store: datasTmp.store
                        }));
                    }
                }

                break;
            default:
                break;
        }
    }
    const initAccountSelected = () => {
        if (accountSelected !== null)
            return;

        if (datas.store.setup !== null && datas.store.setup.accounts !== null && datas.store.setup.accounts.length > 0)
            setAccountSelected(datas.store.setup.accounts[0]);
    }
    const initLicenseSelected = () => {
        if (licenseSelected !== null)
            return;

        if (datas.store.setup !== null && datas.store.setup.licenses !== null && datas.store.setup.licenses.length > 0)
            setLicenseSelected(datas.store.setup.licenses.find(_ => _.main));
    }
    const selectLicenseToDisplay = license => {
        setLicenseSelected(license);
    }
    const selectAccountToDisplay = account => {
        setAccountSelected(account);
    }
    const abortConfirmView = () => {
        setConfirmView(null);
        setMenuLicense(null);
    }
    const callAddEnvironment = () => {
        setConfirmView({
            title: "Créer un environnement",
            question: "Êtes-vous sûr de vouloir créer un environnement SkyTill ?",
            textConfirm: "Oui",
            textAbort: "Non",
            confirm: addEnvironment,
            abort: abortConfirmView,
            loading: false
        });
    }
    const addEnvironment = () => {
        setConfirmView(prev => ({
            ...prev,
            loading: true
        }));

        const controller = new SetupController();
        controller._callback = returnAddEnvironment;
        controller.post(datas.store);
    }
    const returnAddEnvironment = (response, error, status) => {
        abortConfirmView();

        switch (status) {
            case 201:
                setValid("L'environnement est créé");
                getStore();
                break;
            default:
                setError("Une erreur s'est produite lors de la création de l'environnement");
                break;
        }
    }
    const handleMenuLicense = license => {
        if (menuLicense !== null && license.id === menuLicense.id)
            setMenuLicense(null);
        else {
            let menuId = "#menuLicense";
            let textActiveId = "#textActiveLicense";
            let mainLicenseState = "#mainLicenseState";
            let editIconId = "#editLicense-" + license.id;
            let offset = $(editIconId).offset();

            if (license.status === 460) {
                $(textActiveId + " > p").text("Activer la licence");
                $(textActiveId).removeClass("red").addClass("green");
            }
            else {
                $(textActiveId + " > p").text("Désactiver la licence");
                $(textActiveId).removeClass("green").addClass("red");
            }

            if (license.main)
                $(mainLicenseState).addClass("disabled");
            else
                $(mainLicenseState).removeClass("disabled");

            $(menuId).css({left: (offset.left - $(menuId).width() + $(editIconId).outerWidth()) + "px", top: (offset.top + $(editIconId).outerHeight() + 5) + "px"});
            setMenuLicense(license);
        }
    }
    const addLicense = () => {
        if (!datas.store.has_setup)
            return;


    }
    const callAccountMenu = () => {
        if (!datas.store.has_setup) return;
        if (loadingTechnicianAccount) return;

        setAccountMenu(!accountMenu);
    }
    const choiceAccountType = type => {
        if (loadingTechnicianAccount) return;

        switch (type) {
            case "classic":
                callAccountMenu();
                addAccount("addAccount");
                break;
            case "technician":
                addTechnicianAccount();
                break;
            case "controller":
                callAccountMenu();
                addAccount("addAccountController");
                break;
            case "contact":
                callAccountMenu();
                addAccount("addAccountContact");
                break;
        }
    }
    const addAccount = objectType => {
        if (!datas.store.has_setup) return;
        if (loadingTechnicianAccount) return;

        setInnerObjectType(objectType);
        setInnerDatas({
            store: datas.store
        });
    }
    const addTechnicianAccount = () => {
        setLoadingTechnicianAccount(true);

        const controller = new AccountController();
        controller._callback = returnAddTechnicianAccount;
        controller.registerTechnicianAccount(datas.store.setup);
    }
    const returnAddTechnicianAccount = (response, error, status) => {
        setLoadingTechnicianAccount(false);

        switch (status) {
            case 204:
                setValid("Le compte technicien vient d'être ajouté");
                callAccountMenu();
                break;
            default:
                setError("Une erreur s'est produite lors de l'ajout du compte technicien");
                break;
        }
    }
    const tokenDetails = license => {
        if (license.install === null)
            return;

        setTokenView(license.install);
    }
    const buildTokenDetails = () => {
        if (tokenView === null)
            return;

        return <>
            <div className={"token"}>
                {
                    tokenView.status !== 200
                        ? <>
                            <LoaderCircle display="loader" strokeWidth="10" stroke={"#545454"} />
                            <p className={"textLoader"}>Progression {tokenView.batch.progress}%</p>
                        </>
                        : <>
                            <p className={"code"}>{ tokenView.token }</p>
                            <p className={"textLoader"}>Disponible pendant { tokenView.duration() }</p>
                        </>
                }
                <p className={"askingDate"}>Demandé il y a { moment(tokenView.batch.createdAt).fromNow(true) }</p>
                <ul className={"status"}>
                    <li>{ buildStatus("account") }</li>
                    <li>{ buildStatus("setting") }</li>
                    <li>{ buildStatus("catalog") }</li>
                    <li>{ buildStatus("client") }</li>
                    <li>{ buildStatus("legaldata") }</li>
                    <li>{ buildStatus("statistic") }</li>
                    <li>{ buildStatus("sale") }</li>
                </ul>
            </div>
        </>
    }
    const buildStatus = type => {
        let classStatus = type + "Status";
        let classnameCode;
        let textCode;

        if ([200].includes(tokenView[classStatus].status)) {
            classnameCode = "ok";
            textCode = "OK";
        }
        else if ([202].includes(tokenView[classStatus].status)) {
            classnameCode = "progress";
            textCode = "En cours";
        }
        else {
            classnameCode = "wrong";
            textCode = "Erreur";
        }

        return <>
            <p className={"code " + classnameCode} onMouseOver={(classnameCode === "wrong" ? () => { setBubbleText(tokenView[classStatus].message) } : null)} onMouseOut={(classnameCode === "wrong" ? () => { setBubbleText(null) } : null)}>{ textCode }</p>
            <p className={"text"}>{ type }</p>
        </>;
    }
    const checkTokenView = () => {
        if (tokenView !== null && datas.store.setup !== null) {
            let license = datas.store.setup.licenses.find(_ => _.skytill_id === tokenView.license_id);

            if (license !== undefined) {
                setTokenView(license.install);
            }
        }
    }
    const closeTokenDetails = () => {
        setTokenView(null);
    }
    const changeStateLicense = () => {
        if (menuLicense === null)
            return;

        setLoadingChangeStatus(true);

        let license = Object.assign({}, menuLicense);
        let status = license.status === 460 ? 200 : 460;
        let controller = new LicenseController();

        controller._callback = returnChangeStateLicense;
        controller.put(license, { status });
    }
    const returnChangeStateLicense = (response, error, status) => {
        setLoadingChangeStatus(false);

        switch (status) {
            case 204:
                setValid("Le status de la licence à été modifié");
                refreshLicense(menuLicense.id);
                setMenuLicense(null);
                break;
            default:
                setError("Impossible de changer le statut de la licence");
                break;
        }
    }
    const changeInfosLicense = () => {
        setMenuLicense(null);
        setInnerObjectType("updateLicense")
        setInnerDatas({
            license: Object.assign({}, menuLicense)
        })
    }
    const callEnableInstallation = () => {
        if (![200, 462, 463, 468].includes(menuLicense.status)) {
            setError("Le status de cette licence ne permet pas sa réinstallation");
            setMenuLicense(null);
        }

        setConfirmView({
            title: "Réinstaller la licence",
            question: "Êtes-vous sûr de vouloir générer un code d'installation ?",
            textConfirm: "Oui",
            textAbort: "Non",
            confirm: doubleCallEnableInstallation,
            abort: abortConfirmView,
            loading: false
        });
    }
    const doubleCallEnableInstallation = () => {
        setConfirmView({
            title: "ATTENTION",
            question: "Si cette licence est en cours d'utilisation, les échanges avec le serveur seront définitivement interrompus et les données non envoyées seront PERDUES.",
            textConfirm: "Oui, j'ai bien compris !",
            textAbort: "Annuler",
            confirm: enableInstallation,
            abort: abortConfirmView,
            loading: false
        });
    }
    const enableInstallation = () => {
        setConfirmView(prev => ({
            ...prev,
            loading: true
        }));

        let license = Object.assign({}, menuLicense);
        const controller = new LicenseController();
        controller._callback = returnEnableInstallation;
        controller.enablingInstallation(license);
    }
    const returnEnableInstallation = (response, error, status) => {
        let license = Object.assign({}, menuLicense);

        abortConfirmView();

        switch (status) {
            case 204:
                refreshLicense(license.id);
                setValid("Le code d'installation est en cours de création...");
                break;
            default:
                abortConfirmView();
                setError("Une erreur s'est produite lors de la génération du code d'installation");
                break;
        }
    }
    const callChangeMainLicense = () => {
        if (menuLicense.main)
            return;

        setConfirmView({
            title: "Modifier la licence principale",
            question: "Êtes-vous sûr de modifier la licence principale ?",
            textConfirm: "Oui",
            textAbort: "Non",
            confirm: doubleCallChangeMainLicense,
            abort: abortConfirmView,
            loading: false
        });
    }
    const doubleCallChangeMainLicense = () => {
        setConfirmView({
            title: "ATTENTION",
            question: "Dans certains cas bien précis, la modification de licence principale peut entrainer la perte de données si une ou plusieurs caisses sont en mode dégradé.",
            textConfirm: "Oui, j'ai bien compris !",
            textAbort: "Annuler",
            confirm: changeMainLicense,
            abort: abortConfirmView,
            loading: false
        });
    }
    const changeMainLicense = () => {
        setConfirmView(prev => ({
            ...prev,
            loading: true
        }));

        let license = Object.assign({}, menuLicense);
        const controller = new LicenseController();
        controller._callback = returnChangeMainLicense;
        controller.defineNewMainLicense(license);
    }
    const returnChangeMainLicense = (response, error, status) => {
        let license = Object.assign({}, menuLicense);

        abortConfirmView();

        switch (status) {
            case 204:
                refreshLicense(license.id);
                setValid("La licence principale à été redéfinie");
                break;
            default:
                abortConfirmView();
                setError("Une erreur s'est produite lors de la modification de la caisse principale");
                break;
        }
    }
    const stateInnerPopup = () => {
        return innerObjectType !== null
    }
    const stateInnerForm = () => {
        switch (innerObjectType) {
            case "updateLicense":
            case "addAccount":
            case "addAccountController":
            case "addAccountContact":
                if (innerDatas !== null)
                    return true;

                break;
            default: break;
        }

        return false;
    }
    const titleFormSheet = () => {
        switch (innerObjectType) {
            case "updateLicense": return "Modifier la licence";
            case "addAccount": return "Ajouter un compte Backoffice";
            case "addAccountController": return "Ajouter un compte Contrôleur";
            case "addAccountContact": return "Ajouter depuis un contact existant";
            default: return "";
        }
    }
    const closeInnerForm = () => {
        setInnerObjectType(null);
        setInnerDatas(null);
    }
    const returnInnerForm = () => {
        closeInnerForm();
        getStore();
    }

    useEffect(() => {
        initAccountSelected();
        initLicenseSelected();
        checkTokenView();

        let intervals = [];

        if (datas.store.setup !== null && datas.store.setup.licenses.length > 0) {
            for (let i in datas.store.setup.licenses) {
                intervals.push(setInterval(() => {
                    refreshLicense(datas.store.setup.licenses[i].id);
                }, 5000));
            }
        }
        else {
            for (let i in intervals)
                clearInterval(intervals[i]);
        }

        return () => {
            for (let i in intervals)
                clearInterval(intervals[i]);
        };
    }, [datas]);

    return (
        <div className={"containerEnvironnement"}>
            <div className={"overlay" + (stateInnerPopup() ? " appear" : "")}>
                <div className={"wrapPopup"}>
                    <div className={"popup " + (stateInnerForm() ? "new" : "loading")}>
                        {
                            stateInnerForm()
                                ? <FormSheet
                                    objectType={ innerObjectType }
                                    datas={ innerDatas }
                                    parentDatas={ datas }
                                    title={ titleFormSheet() }
                                    callbackClose={ closeInnerForm }
                                    callbackSuccess={ returnInnerForm } />
                                : <div className={"containerLoading"}>
                                    <LoaderCircle display="loader inPopup" hide="" strokeWidth="10" stroke="#00000070" />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className={"overlay" + (confirmView !== null ? " appear" : "")}>
                <div className={"wrapPopup"}>
                    <div className={"popup alert"}>
                        <p className={"title"}>{ confirmView !== null ? confirmView.title : "" }</p>
                        <p className={"question"}>{ confirmView !== null ? confirmView.question : "" }</p>
                        <div className={"form"}>
                            <div className={"containerButtons margin"}>
                                <button className={"button cancel"} onClick={confirmView !== null ? confirmView.abort : null}>{confirmView !== null ? confirmView.textAbort : ""}</button>
                                <button className={"button submit"} onClick={confirmView !== null ? confirmView.confirm : null}>
                                    {
                                        confirmView !== null
                                            && <p className={"text"}>
                                                {
                                                    confirmView.loading
                                                        ? <LoaderCircle display="loader" strokeWidth="8" stroke={ "#FFFFFF" } />
                                                        : confirmView.textConfirm
                                                }
                                            </p>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"overlay" + (tokenView !== null ? " appear" : "")}>
                <div className={"wrapPopup"}>
                    <div className={"popup token"}>
                        <p className={"title"}>Code d'installation</p>
                        { buildTokenDetails() }
                        <div className={"form"}>
                            <div className={"containerButtons margin"}>
                                <button className={"button cancel"} onClick={closeTokenDetails}>Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"overlay" + (accountMenu ? " appear" : "")}>
                <div className={"wrapPopup"}>
                    <div className={"popup token"}>
                        <p className={"title"}>Type de compte</p>
                        <div className={"form"}>
                            <button className={"choiceButton first"} onClick={() => { choiceAccountType("classic") }}>
                                <p>Compte classique</p>
                            </button>
                            <button className={"choiceButton"} onClick={() => { choiceAccountType("contact") }}>
                                <p>Depuis un contact existant</p>
                            </button>
                            <button className={"choiceButton orange"} onClick={() => { choiceAccountType("technician") }}>
                                <p>{ loadingTechnicianAccount ? "Création en cours..." : "Compte technicien" }</p>
                                {
                                    loadingTechnicianAccount
                                    && <LoaderCircle display="loader" strokeWidth="10" stroke="#FFFFFF" />
                                }
                            </button>
                            <button className={"choiceButton orange"} onClick={() => { choiceAccountType("controller") }}>
                                <p>Compte contrôleur</p>
                            </button>
                            <div className={"containerButtons margin"}>
                                <button className={"button cancel"} onClick={callAccountMenu}>Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !datas.store.has_setup
                && <div className={"wrap orange alert"}>
                    <p className={"text"}>Aucun environnement existant</p>
                    <button
                        disabled={(datas.store.addresses === undefined || datas.store.addresses.length === 0)}
                        className={"button" + ((datas.store.addresses === undefined || datas.store.addresses.length === 0) ? " disabled" : "")}
                        onClick={ callAddEnvironment }
                        onMouseOver={ ((datas.store.addresses === undefined || datas.store.addresses.length === 0) ? () => { setBubbleText("Vous devez inscrire au moins une adresse") } : null) }
                        onMouseOut={ ((datas.store.addresses === undefined || datas.store.addresses.length === 0) ? () => { setBubbleText(null) } : null) }>
                        Créer un environnement
                    </button>
                </div>
            }
            <div className={"column"}>
                <div className={"wrap licenses"}>
                    <p className={"title"}>Licences</p>
                    {
                        licenseSelected !== null
                        && <>
                            <div className={"info"}>
                                <p className={"title"}>Statut</p>
                                <p className={"text status" + (licenseSelected.status)}>{LicenseController._licenseStatus[licenseSelected.status]}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Nom</p>
                                <p className={"text"}>{licenseSelected.name}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Numéro</p>
                                <p className={"text"}>{licenseSelected.number}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Type</p>
                                <p className={"text"}>{LicenseController._licenseTypes[licenseSelected.type]}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Version</p>
                                <p className={"text" + (licenseSelected.version === null ? " gray" : "")}>{ (licenseSelected.version !== null ? licenseSelected.version : "Pas encore installée") }</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Dernière synchronisation</p>
                                <p className={"text" + (licenseSelected.ascending_synced_at === null ? " gray" : "")}>{ (licenseSelected.ascending_synced_at !== null ? "Il y a " + moment(licenseSelected.ascending_synced_at).fromNow(true) : "Aucune") }</p>
                            </div>
                        </>
                    }
                    <div className={"list"}>
                        {
                            (datas.store.setup !== null && datas.store.setup.licenses.length > 0)
                                ? datas.store.setup.licenses.map((license, index) => (
                                    <div
                                        key={index}
                                        className={"line" + ((licenseSelected !== null && license.id === licenseSelected.id) ? " selected" : "")}
                                        onClick={() => { selectLicenseToDisplay(license) }}>
                                        <div className={"containerName"}>
                                            <p className={"name"}>{license.name} - {license.number}</p>
                                            <div className={"tags"}>
                                                {
                                                    license.main
                                                    && <p className={"tag"}>Principale</p>
                                                }
                                                {
                                                    license.install !== null
                                                    && <p className={"tag token"} onClick={() => { tokenDetails(license) }}>
                                                        {
                                                            license.install.status !== 200
                                                                ? <>
                                                                    <LoaderCircle display="loader" strokeWidth="10" stroke={"#FFFFFF"} />
                                                                    <p className={"text"}>Token en cours - { license.install.batch.progress }%</p>
                                                                </>
                                                                : <p className={"text"}>{ license.install.token } - { license.install.duration() }</p>
                                                        }
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <AccessIcon idAttribute={"editLicense-" + license.id} classname={"edit"} click={ () => { handleMenuLicense(license) } } />
                                    </div>
                                ))
                                : <div className={"line empty"}>
                                    <div className={"containerName"}>
                                        <p className={"name"}>Aucune licence</p>
                                    </div>
                                </div>
                        }
                        <ul id={"menuLicense"} className={"menuLicense" + (menuLicense !== null ? " appear" : "")}>
                            <li id={"textActiveLicense"} onClick={ changeStateLicense }>
                                <p>Désactiver</p>
                                {
                                    loadingChangeStatus
                                    && <LoaderCircle display="loader" strokeWidth="10" stroke="#006D3E" />
                                }
                            </li>
                            <li onClick={ changeInfosLicense }>
                                <p>Modifier les informations</p>
                            </li>
                            <li className={"orange"} onClick={ callEnableInstallation }>
                                <p>Générer un code d'installation</p>
                            </li>
                            <li id={"mainLicenseState"} className={"orange"} onClick={ callChangeMainLicense }>
                                <p>Définir comme licence principale</p>
                            </li>
                        </ul>
                    </div>
                    {
                        /*
                            <button className={"button" + (!datas.store.has_setup ? " disabled" : "")} onClick={addLicense}>
                                <AddIcon/>
                                <p>Ajouter une licence</p>
                            </button>
                         */
                    }
                </div>
            </div>
            <div className={"column"}>
                <div className={"wrap accounts"}>
                    <p className={"title"}>Compte backoffice</p>
                    {
                        accountSelected !== null
                        && <>
                            <div className={"info"}>
                                <p className={"title"}>Statut</p>
                                <p className={"text status" + (accountSelected.status)}>{AccountController._accountStatus[accountSelected.status]}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Nom</p>
                                <p className={"text"}>{accountSelected.fullname}</p>
                            </div>
                            <div className={"info"}>
                                <p className={"title"}>Email</p>
                                <p className={"text"}>{accountSelected.email}</p>
                            </div>
                            {
                                accountSelected.rules === "SKYTECH"
                                && <>
                                    <div className={"info"}>
                                        <p className={"title"}>Mot de passe</p>
                                        <p className={"text"}>{ moment(accountSelected.created_at).format("DDMMHH") }</p>
                                    </div>
                                    <div className={"info"}>
                                        <p className={"title"}>Temps restant</p>
                                        <p className={"text"}>{ accountSelected.duration() }</p>
                                    </div>
                                </>
                            }
                            <div className={"info"}>
                                <p className={"title"}>Droit(s) boutique</p>
                                <p className={"text"}>{ accountSelected.buildStrRules("store") }</p>
                            </div>
                            {
                                datas.store.company_name !== null
                                && <div className={"info"}>
                                    <p className={"title"}>Droit(s) groupe</p>
                                    <p className={"text"}>{ accountSelected.buildStrRules("company") }</p>
                                </div>
                            }
                        </>
                    }
                    <div className={"list"}>
                        {
                            (datas.store.setup !== null && datas.store.setup.accounts.length > 0)
                                ? datas.store.setup.accounts.map((account, index) => (
                                    <div key={index} className={"line" + ((accountSelected !== null && account.id === accountSelected.id) ? " selected" : "")} onClick={() => { selectAccountToDisplay(account) }}>
                                        <div className={"containerName"}>
                                            <p className={"name"}>{account.fullname}</p>
                                            <div className={"tags"}>
                                                {
                                                    account.rules === "SKYTECH"
                                                    && <p className={"tag temporary"}>Technicien</p>
                                                }
                                                {
                                                    account.rules === "CONTROLLER"
                                                    && <p className={"tag temporary"}>Contrôleur</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <div className={"line empty"}>
                                    <div className={"containerName"}>
                                        <p className={"name"}>Aucun compte backoffice</p>
                                    </div>
                                </div>
                        }
                    </div>
                    <button className={"button" + (!datas.store.has_setup ? " disabled" : "")} onClick={callAccountMenu}>
                        <AddIcon/>
                        <p>Ajouter une compte</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ClientEnvironnement;