import React, {useContext, useEffect, useState} from "react"
import MyCalendarListIcon from "../../icons/MyCalendarListIcon"
import MyCalendarAgendaIcon from "../../icons/MyCalendarAgendaIcon"
import CalendarController from "../../../stories/_task/Calendars/CalendarController"
import LoaderCircle from "../loader/LoaderCircle";
import "../../../css/tools/MyCalendar/MyCalendar.css"
import SessionContext from "../../../context/SessionContext"

const MyCalendar = () => {
    const { setError } = useContext(SessionContext)
    const [ typeView, setTypeView ] = useState("list")
    const [ calendars, setCalendars ] = useState(null)
    const [ calendarsSelected, setCalendarsSelected ] = useState([])

    const listView = () => {
        setTypeView("list")
    }
    const calendarView = () => {
        setTypeView("calendar")
    }
    const getCalendars = () => {
        //const controller = new CalendarController()
        //controller._callback = returnGetCalendars
        //controller.index()
    }
    const returnGetCalendars = (list, error, status) => {
        switch (status) {
            case 200:
                setCalendars(list)
                break

            default:
                setCalendars([])
                setError("Une erreur s'est produite lors de la récupération des calendriers")
                break
        }
    }
    const selectAllCalendars = () => {
        setCalendarsSelected([])
    }
    const selectCalendar = id => {
        let index = calendarsSelected.findIndex(_ => _ === id)
        let selectedTmp = calendarsSelected.slice()

        if (index === -1)
            selectedTmp.push(id)
        else
            selectedTmp.splice(index, 1)

        setCalendarsSelected(selectedTmp)
    }

    useEffect(() => {
        getCalendars()
    }, [])

    return (
        <div className="MyCalendar">
            <header>
                <h1>Agenda</h1>
                <MyCalendarAgendaIcon classname={ typeView === "calendar" ? "select" : "" } handleClick={ calendarView } />
                <MyCalendarListIcon classname={ typeView === "list" ? "select" : "" } handleClick={ listView } />
                <nav className="listCalendars">
                    <p className={ "item" + (calendarsSelected.length === 0 ? " select" : "") } onClick={ selectAllCalendars }>Tous</p>
                    {
                        calendars !== null
                            ? calendars.map((calendar, index) => (
                                <p
                                    key={ index }
                                    className="item"
                                    style={{ backgroundColor: (calendarsSelected.find(_ => _ === calendar.id) ? calendar.color : "") }}
                                    onClick={ () => { selectCalendar(calendar.id) } }
                                >{ calendar.name }</p>
                            ))
                            : <LoaderCircle display="loader getCalendars" hide="" strokeWidth="8" stroke="#006D3E" />
                    }
                </nav>
            </header>
        </div>
    );
}

export default MyCalendar
