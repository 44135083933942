import React from "react";

function HivyIcon (props) {
    const { classname, fill } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={ classname } fill={ fill }>
            <path
                d="M48 3.25H16A12.76 12.76 0 003.25 16v32A12.76 12.76 0 0016 60.75h32A12.76 12.76 0 0060.75 48V16A12.76 12.76 0 0048 3.25zM23.1 35.43L32 30.3l8.9 5.13v10.28L32 50.85l-8.9-5.14zm-1-1.73l-8.89-5.13V18.29l8.89-5.14 8.9 5.14v10.28zM33 28.57V18.29l8.9-5.14 8.89 5.14v10.28L41.9 33.7z"
                data-name="Layer 45"
                fill="#008c4f"
                data-original="#000000"
            />
        </svg>
    );
}

export default HivyIcon;
