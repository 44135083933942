import Rest from "../../../class/tool/Rest";
import Partnership from "./Partnership";
import User from "../Users/User";
import UserGroup from "../UserGroups/UserGroup";

class PartnershipController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "partnerships";

    handleIndex = (response, error, status) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(let item in data)
                listObjects.push(new Partnership(data[item]));
        }

        this._callback(listObjects, error, status);
    }
    index(input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }

    handleShow = (response, error) => {
        const object = !error ? new Partnership(response.data) : null;
        this._callback(object, error);
    }
    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }

    handlePost = (response, error) => {
        this._callback(response, error);
    }
    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.partnership_type_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        );
    }

    handlePut = (response, error) => {
        this._callback(response, error);
    }
    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }

    handleDelete = (response, error) => {
        this._callback(response, error);
    }
    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }

    handleUploadLogo = (response, error) => {
        this._callback(response, error);
    }
    uploadLogo(object, formData, handleProgress) {
        this._rest._contentType = 'multipart/form-data';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + object.id + '/logo',
            formData,
            this.handleUploadLogo,
            handleProgress);
    }

    // Users

    handleIndexUsers = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push( new User(data[item]));
        }

        this._callback(listObjects, error);
    }
    indexUsers(partnership, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + partnership.id + '/users' + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndexUsers);
    }

    handleAttachUser = (response, error) => {
        this._callback(response, error);
    }
    attachUser(partnership, users, storage) {
        const objectToSend = {
            'users': users
        };

        if (storage > 0)
            objectToSend.storage_id = storage;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + partnership.id + '/users',
            objectToSend,
            this.handleAttachUser
        );
    }

    handleDetachUser = (response, error) => {
        this._callback(response, error);
    }
    detachUser(partnership, idUser) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + partnership.id + '/users/' + idUser,
            {},
            this.handleDetachUser
        );
    }

    // User groups

    handleIndexUserGroups = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push( new UserGroup(data[item]));
        }

        this._callback(listObjects, error);
    }
    indexUserGroups(partnership, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + partnership.id + '/usergroups' + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndexUserGroups);
    }

    // Assignables

    handleIndexAssignables = (response, error) => {
        let listObjects = {
            groups: [],
            users: []
        };

        if(response.data !== undefined) {
            const data = response.data;
            let item;

            for(item in data.groups)
                listObjects.groups.push(new UserGroup(data.groups[item]));

            for(item in data.users)
                listObjects.users.push(new User(data.users[item]));
        }

        this._callback(listObjects, error);
    }
    indexAssignables(partnership, rules = [], input= "") {
        let url = "/" + this._baseURL + "/" + partnership.id + "/assignables";
        let params = "";

        if (input !== "")
            params += "?input=" + input;

        if (rules.length > 0) {
            if (params === "") params += "?";
            else params += "&";

            params += "rules=" + JSON.stringify(rules);
        }

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            url + params,
            {},
            this.handleIndexAssignables);
    }

    // AcquisitionChannels

    handleIndexAcquisitionChannels = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;
            let item;

            for(item in data)
                listObjects.push(new UserGroup(data[item]));
        }

        this._callback(listObjects, error);
    }
    indexAcquisitionChannels(partnership) {
        let url = "/" + this._baseURL + "/" + partnership.id + "/acquisitionchannels";
        let params = "";

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            url + params,
            {},
            this.handleIndexAcquisitionChannels);
    }
}

export default PartnershipController;
