import Rest from "../../../class/tool/Rest"
import Company from "./Company"

class CompanyController {
    _rest = new Rest()
    _callback = null
    _baseURL = "companies"

    index(input= "", tree = false, limit = null) {
        let params = ""

        if (input.length > 0)
            params += "?input=" + input

        if (tree) {
            params += (params.length === 0 ? "?" : "&")
            params += "tree=true"
        }

        if (limit !== null) {
            params += (params.length === 0 ? "?" : "&")
            params += "limit=" + limit
        }

        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []
        let pagination = null
        let data

        if (response.data !== undefined && response.data.data !== undefined) {
            if(response.data.total !== undefined) {
                pagination = {
                    total: response.data.total,
                    from: response.data.from != null ? response.data.from : 0,
                    to: response.data.to != null ? response.data.to : 0,
                    per_page: response.data.per_page,
                    current_page: response.data.current_page,
                    first_page_url: new URL(response.data.first_page_url).search,
                    last_page_url: new URL(response.data.last_page_url).search,
                    next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                    prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                }
            }

            data = response.data.data
        }
        else if (response.data)
            data = response.data

        for(let item in data)
            listObjects.push(new Company(data[item]))

        this._callback(listObjects, error, pagination, status)
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Company(response.data) : null;
        this._callback(object, error);
    }

    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.skytill_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.skytill_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }
}

export default CompanyController
