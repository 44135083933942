import React from "react"
import DatePicker, { registerLocale } from  "react-datepicker"
import fr from "date-fns/locale/fr"
import InputText from "../../component/form/input/generated/InputText"
import InputSelect from "../../component/form/input/generated/InputSelect"
import InputColor from "../../component/form/input/generated/InputColor"
import InputCode from "../../component/form/input/generated/InputCode"
import InputSwitch from "../../component/form/input/generated/InputSwitch"
import InputRichText from "../../component/form/input/generated/InputRichText"
import InputPricelist from "../../component/form/input/generated/InputPricelist"
import InputBidirectionalList from "../../component/form/input/generated/InputBidirectionalList"
import NoInput from "../../component/form/input/generated/NoInput"
import InputBarcode from "../../component/form/input/generated/InputBarcode"
import InputMultiLinesChoices from "../../component/form/input/generated/InputMultiLinesChoices"
import "react-datepicker/dist/react-datepicker.css"
import InputTab from "../../component/form/input/generated/InputTab"
import MailIcon from "../../component/icons/MailIcon";
import PwdIcon from "../../component/icons/PwdIcon";
import InputCloudTag from "../../component/form/input/generated/InputCloudTag";
registerLocale("fr", fr)

class FormBuilder {
    static buildInputByType(row, values, errors, handleChange = null, handleBlur = null, handleRecovery = null, handleSubmit = null, handleAdd = null, handleRemove = null, handleBold = null, firstFocus = null) {
        let inputType = row.inputType;

        switch (inputType) {
            case "text":
            case "password":
                return <InputText
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    icon={ row.icon }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ (values[row.attribute] !== undefined && values[row.attribute] !== null) ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    list={ row.list }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    focus={ firstFocus !== null ? firstFocus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                    handleSubmit={ handleSubmit != null ? handleSubmit : null }
                    handleSelect={ row.handleSelect }
                />
            case "textDisplay":
                return <InputText
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    maxChars={ row.maxChars }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ FormBuilder.getValueLineForDisplayText(row.initialAtribute, row.attribute, values) }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "textHeader":
                return <InputText
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    maxChars={ row.maxChars }
                    toBold={ handleBold }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ FormBuilder.getValueLineForHeaderText(row.initialAtribute, row.attribute, values) }
                    bold={ FormBuilder.getBoldLineForHeaderText(row.initialAtribute, row.attribute, values) }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "select":
                return <InputSelect
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ row.returnType === "int" ? FormBuilder.getIndexForValue(row, values) : values[row.attribute] }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "tab":
                return <InputTab
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ row.returnType === "int" ? FormBuilder.getIndexForValue(row, values) : values[row.attribute] }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    placeholder={ row.placeholder }
                    focus={ row.focus !== undefined ? row.focus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "date" :
                return <DatePicker
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    startDate={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    className="input"
                    calendarIcon={ null }
                    clearIcon={ null }
                    onChange={
                        (update) => {
                            handleChange(row.attribute, "string", update);
                        }
                    }
                    showWeekNumbers
                />
            case "daterange" :
                return <DatePicker
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    startDate={ values[row.attributeStart] }
                    endDate={ values[row.attributeEnd] }
                    className="input"
                    calendarIcon={ null }
                    clearIcon={ null }
                    onChange={
                        (update) => {
                            handleChange(row.attributeRange, "string", update);
                        }
                    }
                    selectsRange
                    withPortal
                    showWeekNumbers
                />
            case "color":
                return <InputColor
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "code":
                return <InputCode
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    maxLength={ row.maxLength }
                    alphanumeric={ row.alphanumeric }
                    classname={ row.classnameInput }
                    classError={ errors.includes(row.attribute) ? "wrong" : "" }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "switch":
                return <InputSwitch
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    label={ row.label }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "cloudTag":
                return <InputCloudTag
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    inputText={ row.inputText }
                    list={ row.list }
                    classname={ row.classname }
                    values={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "textarea":
                return <InputRichText
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    focus={ row.focus !== undefined ? row.focus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "multiline":
                return <InputMultiLinesChoices
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    selectType={ row.selectType }
                    smartList={ row.smartList }
                    list={ row.list }
                    listSelected={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} } />
            case "pricelist":
                return <InputPricelist
                    lines={ values[row.attribute] }
                    pricelists={ row.pricelists }
                    vatrates={ row.vatrates }
                    category={ row.category }
                    classname={ row.classnameInput }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleAdd={ handleAdd != null ? handleAdd : () => {} }
                    handleRemove={ handleRemove != null ? handleRemove : () => {} }
                    handleRecovery={ handleRecovery != null ? handleRecovery : () => {} }
                />
            case "barcode":
                return <InputBarcode
                    barcodes={ values[row.attribute] }
                    storeSettings={ row.storeSettings }
                    classname={ row.classnameInput }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleRecovery={ handleRecovery != null ? handleRecovery : () => {} }
                />
            case "bidirectionalList":
                return <InputBidirectionalList
                    attribute={ row.attribute }
                    classname={ row.classnameInput }
                    classnameLabel={ row.classnameLabel }
                    classnameInputLiLeft={ row.classnameInputLiLeft }
                    classnameInputLiRight={ row.classnameInputLiRight }
                    labelLeft={ row.labelLeft }
                    labelRight={ row.labelRight }
                    labelButtonLeft={ row.labelButtonLeft }
                    labelButtonRight={ row.labelButtonRight }
                    emptyText={ row.emptyText }
                    maxHeight={ row.maxHeight }
                    updated={ row.updated }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    list={ row.list }
                    handleChange={ handleChange != null ? handleChange : () => {} } />
            default: break;
        }
    }
    static getValueLineForDisplayText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace("line", "")) - 1

        return lines[indexLine] !== undefined ? lines[indexLine] : ""
    }
    static getValueLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].text : ""
    }
    static getBoldLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].bold : 0
    }
    static getIndexForValue(row, values) {
        let value = 0;

        if (row.list === undefined || row.list === null)
            return value;

        for (let i = 0; i < row.list.length; i++) {
            if (row.list[i].type !== undefined && row.list[i].type !== "") {
                if (
                    row.list[i].id === values[row.attribute]
                    && row.list[i].type === values[row.attribute.replace("_id", "_type")]
                ) {
                    value = i;
                    break;
                }
            }
            else {
                if (row.list[i].id === values[row.attribute]) {
                    value = i;
                    break;
                }
            }
        }

        return value;
    }
    static buildNoInputByType(row, values, handleEdit) {
        const val = FormBuilder.getValueForNoInput(row, values);

        return <NoInput
            inputType={ row.inputType }
            attribute={ row.attribute }
            classname={ row.classnameNoInput }
            value={ val !== null && val !== "" ? val : row.emptyText }
            edit={ row.edit !== undefined ? row.edit : true }
            handleEdit={ handleEdit != null ? handleEdit : () => {} } />
    }
    static getValueForNoInput = (row, values) => {
        let value = ""

        switch (row.inputType) {
            case "select":
                if (row.returnType === "int")
                    value = FormBuilder.getDataByKeyInList(row, values, "value")
                else {
                    if (row.dictionary !== null)
                        value = row.dictionary[values[row.attribute]]
                    else
                        value = values[row.attribute]
                }
                break
            case "textarea":
                value = values[row.attribute]
                break
            default:
                value = values[row.attribute]
                break
        }

        return value
    }
    static getDataByKeyInList = (row, values, data) => {
        let filtered;

        if (row.list === undefined) return ""

        if (row.attribute.includes("_id") && values[row.attribute.replace("_id", "_type")] !== undefined)
            filtered = row.list.filter(item => item.id === values[row.attribute] && item.type === values[row.attribute.replace("_id", "_type")])
        else
            filtered = row.list.filter(item => item.id === values[row.attribute])

        if (filtered.length === 0 || filtered[0][data] === undefined) return ""

        return filtered[0][data]
    }
    static buildVal = (returnType, val) => {
        switch (returnType) {
            case "string": return val
            case "int":
                switch (typeof val) {
                    case "string": return parseInt(val)
                    case "object": return (val !== null ? parseInt(val.toString()) : null)
                    case "undefined": return 0
                    default: return val
                }
            case "float": return val.replace(',', '.')
            default: return val
        }
    }
    static buildIcon = icon => {
        switch (icon) {
            case "mail":
                return <MailIcon />
            case "password":
                return <PwdIcon />
            default: break
        }
    }

    static handleChange = (rows, setValues, attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let type = ""
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = filtered[0].list[index].id !== null ? parseInt(filtered[0].list[index].id) : null

            if (filtered[0].list[index].type !== undefined) {
                type = filtered[0].list[index].type

                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }

        return {
            value: value,
            type: type,
            filtered: filtered,
            index: index
        }
    }
    static returnUpdates = (object, datas, compare = true) => {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]
            //console.log("CHECK UPDATE", key)

            if (object[key] !== undefined) {
                if (compare) {
                    //console.log("COMPARE", typeof datas[key], object[key], datas[key])

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue
                            else if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        case "object":
                            if (object[key] === null || (object[key].length === 0 && datas[key].length === 0))
                                continue
                            else if (!this.isEqual(object[key], datas[key]) && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        default:
                            if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break
                    }
                }
                else {
                    //console.log("NOT COMPARE", typeof datas[key], object[key], datas[key])

                    if (datas[key] === null)
                        continue

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue

                            break

                        default: break
                    }

                    Reflect.set(updates, key, datas[key])
                }
            }
        }

        return updates
    }
    static isEqual = (obj1, obj2) => {
        if (
            (obj1 === null && obj2 !== null)
            || (obj1 !== null && obj2 === null)
        )
            return false

        let props1 = Object.getOwnPropertyNames(obj1)
        let props2 = Object.getOwnPropertyNames(obj2)

        if (props1.length !== props2.length)
            return false;

        for (let i = 0; i < props1.length; i++) {
            let val1 = obj1[props1[i]]
            let val2 = obj2[props1[i]]
            let isObjects = this.isObject(val1) && this.isObject(val2)

            if (isObjects && !this.isEqual(val1, val2) || !isObjects && val1 !== val2)
                return false
        }

        return true
    }
    static isObject = object => {
        return object != null && typeof object === 'object'
    }
}

export default FormBuilder;
