import Rest from "../../../class/tool/Rest";
import License from "./License";
import FormBuilder from "../../../class/tool/FormBuilder";
import RegexTools from "../../../class/tool/RegexTools";
import StringTools from "../../../class/tool/StringTools";

class LicenseController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "licenses";
    _baseURLSetup = "setups/{idSetup}/licenses";
    _errors = {
        "name": {
            "empty": "Le nom est obligatoire",
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        }
    };
    static _licenseTypes = {
        "POS_WINDOWS": "Caisse Windows",
        "POS_IOS": "Caisse iOS",
    }
    static _licenseStatus = {
        0: "Erreur",
        200: "OK",
        460: "Désactivée",
        462: "Installation en cours",
        463: "Remise à zéro",
        468: "Initialisation"
    }

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        if (formNew) {

        }
        else {
            setValues(prev => ({
                ...prev,
                name: object.name
            }))
        }
    }
    setFormRows(setRows, formNew = false, datas) {
        let rows;

        if (formNew) {
        }
        else {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Comptoir",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                }
            ];
        }

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["type_name", "postalCode", "city", "country", "main"];
        let optionals = ["address", "additional"];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnPutDatas(datas) {
        let datasToPost = {};
        let needed = ["name"];
        let optionals = [];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }

    // control values

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "updateLicense":
                datas = this.returnPutDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "name": return this.controlName(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlName(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("name");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["name"]["wrong"]
                };
            }
        }
        else {
            return {
                result: false,
                error: this._errors["name"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }

    // rest API

    index(idSetup) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURLSetup.replace("{idSetup}", idSetup),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(let item in data)
                listObjects.push(new License(data[item]));
        }

        this._callback(listObjects, error, status);
    }

    show(idSetup, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURLSetup.replace("{idSetup}", idSetup) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status) => {
        const object = !error ? new License(response.data) : null;
        this._callback(object, error, status);
    }

    post(idSetup, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.partnership_type_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLSetup.replace("{idSetup}", idSetup),
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    enablingInstallation = (object) => {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + object.id + '/enableinstallation',
            {},
            this.handleEnablingInstallation);
    }
    handleEnablingInstallation = (response, error, status) => {
        this._callback(response, error, status);
    }

    defineNewMainLicense = (object) => {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + object.id + '/main',
            {},
            this.handleDefineNewMainLicense);
    }
    handleDefineNewMainLicense = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default LicenseController;
