import Rest from "../../../class/tool/Rest"
import Tag from "./Tag"

class TagController {
    _rest = new Rest()
    _callback = null
    _baseURL = "tags"

    index(type, input= "") {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?type=" + type.toLowerCase() + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex
        )
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data.data

            for(let item in data)
                listObjects.push(new Tag(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    show(id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow
        )
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Tag(response.data) : null;
        this._callback(object, error, status)
    }

    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object))

        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete
        )
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default TagController
