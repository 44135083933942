import React from "react";
import PresentForm from "../../form/PresentForm";

const FormSheet = props => {
    const { objectType, datas, parentDatas, title, previousLink, successLink, callbackClose, callbackSuccess } = props;

    return (
        <PresentForm
            title={ title }
            objectType={ objectType }
            datas={ datas }
            parentDatas={ parentDatas }
            previousLink={ previousLink }
            successLink={ successLink }
            callbackClose={ callbackClose }
            callbackSuccess={ callbackSuccess } />
    )
}

export default FormSheet;
