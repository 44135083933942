import React, {useEffect} from "react"
import $ from "jquery"

function InputTab (props) {
    const {
        attribute,
        returnType,
        classname,
        list,
        dictionary,
        value,
        handleChange
    } = props

    const definePositionSelectedItem = () => {
        let index = -1;

        if (returnType === "string")
            index = list.findIndex(_ => _.text === value);
        else
            index = list.findIndex(_ => _.id === value);

        if (index < 0) return

        const elmt = $("#" + $(".tabInput .item")[index].id)
        let x = 0;

        for (let i = 0; i < index; i++)
            x += $("#" + $(".tabInput .item")[i].id).innerWidth()

        $(".selectedBlock").css("width", (elmt.innerWidth()) + "px").css("marginLeft", x + "px")

        let valBorderRadius = "5px"

        if (classname.includes("borderRadiusMore"))
            valBorderRadius = "15px"

        if (index === 0)
            $(".selectedBlock").css("borderRadius", valBorderRadius + " 0 0 " + valBorderRadius)
        else if (index === ($(".tabInput .item").length - 1))
            $(".selectedBlock").css("borderRadius", "0 " + valBorderRadius + " " + valBorderRadius + " 0")
        else
            $(".selectedBlock").css("borderRadius", "0")
    }
    const changeEvent = value => {
        if (handleChange === null) return
        handleChange(attribute, returnType, value)
    }

    useEffect(() => {
        definePositionSelectedItem()
    }, [value])

    return (
        <div className={ "tabInput" + (classname !== undefined ? " " + classname : "") }>
            <div className="selectedBlock" />
            {
                list.map((item, index) => (
                    <p
                        key={ index }
                        id={ "item-" + attribute + "-" + index }
                        className="item"
                        onClick={ () => { changeEvent(returnType === "string" ? item.text : item.id) } }>
                        {
                            dictionary !== null
                                ? dictionary[item.text]
                                : item.text
                        }
                    </p>
                ))
            }
        </div>
    )
}

export default InputTab
