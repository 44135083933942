import Entity from "../../../class/abstract/Entity";
import InstallationEnabling from "../InstallationEnablings/InstallationEnabling";
import moment from "moment/moment";

class License extends Entity {
    skytill_id = null;
    name = "";
    number = "";
    type = "";
    main = 0;
    version = "";
    status = 0;
    hardware = "";
    ascending_synced_at = null;
    install = null;

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = ['install'];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }

        if (this.ascending_synced_at !== null)
            this.ascending_synced_at = moment.utc(this.ascending_synced_at).toDate();

        if (datas.install !== undefined && datas.install !== null)
            this.install = new InstallationEnabling(datas.install);
    }
}

export default License;
