import React from "react";

export default React.createContext({
    setError: () => {},
    setWarning: () => {},
    setValid: () => {},
    setBubbleText: () => {},
    objectPopup: null,
    setObjectPopup: () => {},
    getDatasPopup: null,
    setGetDatasPopup: () => {},
    statePopup: null,
    setStatePopup: () => {},
    subStatePopup: null,
    setSubStatePopup: () => {},
    popupLoadingIcon: null,
    setPopupLoadingIcon: () => {},
    popupLoadingText: null,
    setPopupLoadingText: () => {}
});
