import Rest from "../../../class/tool/Rest";
import User from "./User";
import StringTools from "../../../class/tool/StringTools";
import Store from "../../_client/Stores/Store";

class UserController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "users";
    qs = require('qs');

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            firstname: StringTools.capitalizeFirstLetter(object.firstname.toLocaleLowerCase()),
            lastname: StringTools.capitalizeFirstLetter(object.lastname.toLocaleLowerCase()),
            email: object.email.toLocaleLowerCase(),
            rule: this.buildRules(object.rules)
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }
    buildRules(strRules) {
        if (strRules === null || strRules === undefined || strRules.length === 0)
            return []
        else
            return strRules.split('|')
    }

    index(input = null, partnership = null) {
        let parameters = "";

        if (input !== null)
            parameters += "?input=" + input;

        if (partnership !== null)
            parameters += (parameters.length === 0 ? "?" : ";") + "partnership=" + partnership;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + parameters,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    };
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new User(data[item]));
        }

        this._callback(listObjects, error, pagination, status);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new User(response.data) : null;
        this._callback(object, error);
    }

    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.id;
        delete objectToSend.api_key;
        delete objectToSend.updated_at;
        delete objectToSend.created_at;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    // Password

    resetPwd(object, datas) {
        if (object.bigBisoux === 1)
            delete datas.oldPassword

        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + object.id + '/resetpassword',
            datas,
            this.handleResetPwd)
    }
    handleResetPwd = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default UserController;
