import Rest from "../../../class/tool/Rest"
import Calendar from "./Calendar"

class CalendarController {
    _rest = new Rest()
    _callback = null
    _baseURL = "calendars"

    index(input= "") {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push(new Calendar(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Calendar(response.data) : null
        this._callback(object, error, status)
    }

    post(datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(object, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut
        )
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key])
            }
        }

        return updates
    }
}

export default CalendarController
