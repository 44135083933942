import Rest from "../../../class/tool/Rest";
import Address from "./Address";
import FormBuilder from "../../../class/tool/FormBuilder";
import StringTools from "../../../class/tool/StringTools";
import RegexTools from "../../../class/tool/RegexTools";

class AddressController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "addresses";
    _baseURLForStore = "stores/:idStore/addresses";
    _errors = {
        "name": {
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "address": {
            "wrong": "L'adresse doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "additional": {
            "wrong": "L'adresse doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "postalCode": {
            "empty": "Le code postal est obligatoire",
            "wrong": "Le code postal est invalide"
        },
        "city": {
            "empty": "La ville est obligatoire",
            "wrong": "La ville est invalide, catactères autorisés : - '"
        },
        "country": {
            "empty": "Le pays est obligatoire",
            "wrong": "Le pays est invalide"
        },
        "type_name": {
            "wrong": "Le type est invalide"
        }
    };
    _addressTypes = [
        {
            value: "BILLING",
            id: 1
        },
        {
            value: "DELIVERY",
            id: 2
        }
    ];
    static _addressTypesDict = {
        "BILLING": "Facturation",
        "DELIVERY": "Livraison"
    };
    _countries = [
        {
            value: "FRANCE",
            id: 1
        }
    ];
    _yesNo = [
        {
            value: "Oui",
            id: 1
        },
        {
            value: "Non",
            id: 0
        }
    ];

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        if (formNew) {
            setValues(prev => ({
                ...prev,
                type_name: "BILLING",
                address: "",
                additional: "",
                postalCode: "",
                city: "",
                country: "FRANCE",
                main: ((datas.store.addresses === undefined || datas.store.addresses.length === 0) ? 1 : 0)
            }))
        }
        else {

        }
    }
    setFormRows(setRows, formNew = false, datas) {
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Type",
                    attribute: "type_name",
                    inputType: "select",
                    returnType: "string",
                    list: this._addressTypes,
                    dictionary: AddressController._addressTypesDict,
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    classnameNoInput: "",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Adresse principale",
                    attribute: "main",
                    inputType: "select",
                    returnType: "int",
                    list: this._yesNo,
                    dictionary: null,
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    classnameNoInput: "",
                    isDisabled: datas.store.addresses.length === 0,
                    needed: true,
                    checkData: null
                },
                {
                    label: "Adresse",
                    attribute: "address",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "1 rue de la Liberté",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                },
                {
                    label: "Complément",
                    attribute: "additional",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Complément d'adresse",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                },
                {
                    label: "Code postal",
                    attribute: "postalCode",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "75000",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Ville",
                    attribute: "city",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Paris",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Pays",
                    attribute: "country",
                    inputType: "select",
                    returnType: "string",
                    list: this._countries,
                    dictionary: null,
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    classnameNoInput: "",
                    needed: true,
                    checkData: null
                }
            ]
        }
        else {

        }

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["type_name", "postalCode", "city", "country", "main"];
        let optionals = ["address", "additional"];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }

    // control values

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "newAddress":
                datas = this.returnPostDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "type_name": return this.controlTypeName(value);
            case "main": return this.controlMain(value);
            case "name": return this.controlName(value);
            case "address": return this.controlAddress(value);
            case "additional": return this.controlAdditional(value);
            case "postalCode": return this.controlPostalCode(value);
            case "city": return this.controlCity(value);
            case "country": return this.controlCountry(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlTypeName(value) {
            let regex = RegexTools.getRegex("address_type");

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["type_name"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlMain(value) {
        let regex = RegexTools.getRegex("bool");

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["main"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlAddress(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("name");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["address"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlAdditional(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("name");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false, error: this._errors["additional"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlPostalCode(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("postalCode");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["postalCode"]["wrong"]
                };
            }
        }
        else {
            return {
                result: false,
                error: this._errors["postalCode"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlCity(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("city");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["city"]["wrong"]
                };
            }
        }
        else {
            return {
                result: false,
                error: this._errors["city"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlCountry(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("country");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["country"]["wrong"]
                };
            }
        }
        else {
            return {
                result: false,
                error: this._errors["country"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }

    // Controllers

    index(type, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?" + type + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Address(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Address(response.data) : null;
        this._callback(object, error);
    }

    post(store, datas) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLForStore.replace(":idStore", store.id),
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(store, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.type_name;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURLForStore.replace(":idStore", store.id) + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(store, idAddress) {
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + store.id + '/addresses/' + idAddress,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default AddressController;
