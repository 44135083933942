import Entity from "../../../class/abstract/Entity";
import Company from "../Companies/Company";
import ProspectingPhase from "../ProspectingPhases/ProspectingPhase";

class LegalData extends Entity {
    socialReason = "";
    siret = "";
    ape = "";
    tvaIntra = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default LegalData;
