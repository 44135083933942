import Rest from "../../../class/tool/Rest";
import Store from "./Store";
import Contact from "../Contacts/Contact";
import Countries from "../../../class/tool/Countries";
import FormBuilder from "../../../class/tool/FormBuilder";
import StringTools from "../../../class/tool/StringTools";
import AuthController from "../../_auth/Auth/AuthController";

class StoreController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "stores";
    _baseURLHistory = "stores/:idStore/history";
    _errors = {
        "partnership_id": {
            "empty": "Vous devez sélectionner un partenaire"
        },
        "creator_id": {
            "empty": "Vous devez sélectionner l'utilisateur qui créer le client"
        },
        "company_id": {
            "wrong": "Le groupe que vous tentez d'affilier n'est pas valide"
        },
        "company_name": {
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "name": {
            "empty": "Le nom du client est obligatoire",
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "socialReason": {
            "wrong": "La raison sociale doit être comprise entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "sector": {
            "wrong": "Vous devez sélectionner un secteur d'activité"
        },
        "assigned_to_id": {
            "empty": "Vous devez sélectionner un utilisateur",
            "wrong": "L'utilisateur choisi est incorrecte"
        },
        "assigned_to_type": {
            "empty": "Vous devez sélectionner un type d'utilisateur"
        }
    };
    _sectors = [
        {
            value: "Choisir le secteur d'activité",
            id: null
        },
        {
            value: "----------",
            id: null
        },
        {
            value: "Boulangerie",
            id: 1
        },
        {
            value: "Coiffeur / Institue de beauté",
            id: 2
        },
        {
            value: "Commerce de détail",
            id: 3
        },
        {
            value: "Prêt à porter",
            id: 4
        },
        {
            value: "Restaurant / Bar",
            id: 5
        },
        {
            value: "Snacking / V.A.E",
            id: 6
        }
    ];
    static _states = {
        "ACTIVE": "Actif",
        "STANDBY": "Inactif",
        "EJECT": "Supprimé"
    }

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        const user = JSON.parse(localStorage.getItem("user"));

        if (formNew) {
            setValues(prev => ({
                ...prev,
                creator_id: object.creator_id !== null ? object.creator_id : user.id,
                partnership_id: !AuthController.hasRules(["admin"]) ? user.partnership_id : datas.partnerships[0].id,
                company_id: object.company_id,
                company_name: object.company_name,
                name: object.name
            }));
        }
        else {
            setValues(prev => ({
                ...prev,
                partnership_id: object.partnership_id,
                partnership_name: object.partnership_name,
                company_id: object.company_id,
                company_name: object.company_name,
                assigned_to_id: object.assigned_to_id,
                assigned_to_type: object.assigned_to_type,
                prospect_state: object.prospect_state,
                acquisition_channel_id: object.acquisition_channel_id,
                store: object.name,
                code: object.code,
                addresses: object.addresses,
                bankDatas: object.bankDatas,
                contacts: object.contacts,
                setups: object.setups,
                prospectingPhases: object.prospectingPhases
            }));
        }
    }
    setFormAssignUserValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            assigned_to_id: object.assigned_to_id,
            assigned_to_type: "USER"
        }));
    }
    setFormRows(setRows, formNew = false, datas) {
        const user = JSON.parse(localStorage.getItem("user"));
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Boutique",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Secteur d'activité",
                    attribute: "sector",
                    inputType: "select",
                    returnType: "string",
                    list: this._sectors,
                    dictionary: null,
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    classnameNoInput: "",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Groupe",
                    attribute: "company_name",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Nom du groupe",
                    additional: "Nécessaire pour basculer sur un environnement multi-boutique",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                }
            ]

            if (AuthController.hasRules(["admin"])) {
                rows.splice(0, 0, {
                    label: "Propriétaire",
                    attribute: "partnership_id",
                    inputType: "select",
                    returnType: "int",
                    list: datas.partnerships,
                    dictionary: null,
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    classnameNoInput: "",
                    needed: true,
                    checkData: null
                });
            }
        }
        else {

        }

        setRows(rows);
    }
    setFormAssignUserRows(setRows, datas) {
        let rows;

        rows = [
            {
                label: "Utilisateur",
                attribute: "assigned_to_id",
                inputType: "select",
                returnType: "int",
                list: datas.users,
                dictionary: null,
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                classnameNoInput: "",
                needed: true,
                checkData: null
            }
        ]

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["partnership_id", "creator_id", "name", "sector"];
        let optionals = ["company_id", "company_name"];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnPostAssignUserDatas(datas) {
        let datasToPost = {};
        let needed = ["assigned_to_id", "assigned_to_type"];
        let optionals = [];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "client":
                datas = this.returnPostDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            case "assignUser":
                datas = this.returnPostAssignUserDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToAssignUser(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "partnership_id": return this.controlPartnershipId(value);
            case "creator_id": return this.controlCreatorId(value);
            case "company_id": return this.controlCompanyId(value);
            case "company_name": return this.controlCompanyName(value);
            case "name": return this.controlName(value);
            case "socialReason": return this.controlSocialReason(value);
            case "sector": return this.controlSector(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    checkDataToAssignUser(attribute, value) {
        switch (attribute) {
            case "assigned_to_id": return this.controlAssignToId(value);
            case "assigned_to_type": return this.controlAssignToType(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlPartnershipId(value) {
        let regex = /^[0-9]{1,}$/;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["partnership_id"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlCreatorId(value) {
        let regex = /^[0-9]{1,}$/;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["creator_id"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlCompanyId(value) {
        if (value !== null) {
            let regex = /^[0-9]{1,}$/;

            if (!regex.test(value)) {
                return {
                    result: false,
                    error: this._errors["company_id"]["empty"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlCompanyName(value) {
        if (value.length === 0)
            return null;

        let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["company_name"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlName(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["name"]["empty"]
            };
        }

        let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["name"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlSocialReason(value) {
        if (value.length === 0)
            return null;

        let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["socialReason"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlSector(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["sector"]["wrong"]
            };
        }

        let regex = /^[a-zA-Z/.\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["sector"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlAssignToId(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["assigned_to_id"]["empty"]
            };
        }

        let regex = /^[0-9]{1,}$/i;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["assigned_to_id"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlAssignToType(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["assigned_to_type"]["wrong"]
            };
        }

        if (value !== "USER") {
            return {
                result: false,
                error: this._errors["assigned_to_type"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }

    // Controllers

    index(type, state, version, input, page, perPage, sortingName, sortingValue) {
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?type=" + type + "&state=" + state + "&version=" + version + "&page=" + page + "&perPage=" + perPage + "&sortingName=" + sortingName + "&sortingValue=" + sortingValue + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Store(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    show(id) {
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Store(response.data) : null
        this._callback(object, error, status)
    }

    post(datas) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        if (this._callback !== null) {
            this._callback(response, error, status)
        }
    }

    put(object, datas) {
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut
        )
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    toClient(object) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + object.id + '/toclient',
            {},
            this.handleToClient
        )
    }
    handleToClient = (response, error, status) => {
        this._callback(response, error, status)
    }

    // Contacts

    indexContact(store, input= "") {
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + store.id + '/contacts' + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndexContact)
    }
    handleIndexContact = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data.contacts

            for(var item in data)
                listObjects.push( new Contact(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    // History

    postHistory(store, datas) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLHistory.replace(":idStore", store.id),
            datas,
            this.handlePostHistory
        )
    }
    handlePostHistory = (response, error, status) => {
        if (this._callback !== null) {
            this._callback(response, error, status)
        }
    }

    deleteHistory(history) {
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURLHistory.replace(":idStore", history.store_id) + '/' + history.id,
            {},
            this.handleDeleteHistory
        )
    }
    handleDeleteHistory = (response, error, status) => {
        if (this._callback !== null) {
            this._callback(response, error, status)
        }
    }
}

export default StoreController;
