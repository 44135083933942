import React, { useEffect, useState } from "react"
import Search2Icon from "../../../icons/Search2Icon"
import ToUppercase from "../../../icons/ToUppercase"

const InputText = props => {
    const { attribute, returnType, color, type, maxChars, autocomplete, classname, backgroundColor, classError, value, list, placeholder, icon, focus, readonly, toUppercase, handleChange, handleBlur, handleSubmit, handleSearch, handleSelect } = props
    const [ listDisplayed, setListDisplayed ] = useState(list !== undefined ? list : [])
    const [ showList, setShowList ] = useState(true)
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false)

    const filterList = () => {
        let listFiltred = []

        for(let index in list) {
            if (list[index].value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase())) {
                listFiltred.push(list[index])
            }
        }

        setListDisplayed(listFiltred)
    }
    const changeEvent = event => {
        let value = event.currentTarget.value

        if (maxChars !== undefined && value.length > maxChars) return

        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, value)
    }
    const blurEvent = () => {
        focusOut()

        if (handleBlur === undefined || handleBlur === null) return
        handleBlur()
    }
    const keyDownEvent = event => {
        if (event.key === "Enter" && handleSubmit !== undefined && handleSubmit !== null) {
            event.preventDefault()
            handleSubmit()
        }
    }
    const selectEvent = id => {
        if (handleSelect === undefined || handleSelect === null) return
        handleSelect(attribute, id)
    }
    const focusIn = () => {
        setShowList(true)
    }
    const focusOut = () => {
        setTimeout(() => { setShowList(false) }, 250)
    }

    useEffect(() => {
        setReadOnly(readonly)
    }, [readonly])
    useEffect(() => {
        setListDisplayed(list)
    }, [list])
    useEffect(() => {
        filterList()
    }, [value])

    return (
        <div className={ "textInput " + ((classname !== undefined && classname !== null && classname.length > 0) ? " " + classname : "") + ((icon !== undefined && icon !== null && icon.length > 0) ? " icon " + icon : "") + ((classError !== undefined && classError !== null && classError.length > 0) ? " " + classError : "") }>
            {
                (icon !== undefined && icon !== null)
                && <div className="icon">
                    <Search2Icon />
                </div>
            }
            <input
                id={ attribute }
                type={ type !== undefined ? type : "text" }
                autoComplete={ autocomplete !== undefined ? autocomplete : "false" }
                className={ "extend" }
                value={ value !== null ? value : "" }
                placeholder={ placeholder }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ focus !== null ? focus : false }
                readOnly={ readOnly }
                onKeyDown={ keyDownEvent }
                onFocus={ focusIn }
                style={{backgroundColor: ((backgroundColor !== undefined && backgroundColor !== null) ? backgroundColor + "22" : "transparent")}} />
            {
                toUppercase !== undefined
                && <ToUppercase fill={ color } classname="toUppercase" handleClick={ toUppercase } />
            }
            {
                handleSearch !== undefined
                && <Search2Icon fill={ color } classname="searchInput" handleClick={ handleSearch } />
            }
            {
                maxChars !== undefined
                && <p className="maxChars">{value.length}/{maxChars}</p>
            }
            <div className="clearing" />
            {
                (showList && listDisplayed.length > 0)
                && <ul className="smartList">
                    {
                        listDisplayed.map((item, index) => (
                            <li key={ index } onClick={ () => { selectEvent(item.id) } }>
                                { item.value }
                                {
                                    item.extendValue !== undefined
                                    && <p className="extendValue">{ item.extendValue }</p>
                                }
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    );
}

export default InputText
