import Rest from "../../../class/tool/Rest";
import Contact from "./Contact";
import FormBuilder from "../../../class/tool/FormBuilder";
import RegexTools from "../../../class/tool/RegexTools";
import StringTools from "../../../class/tool/StringTools";

class ContactController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "contacts";
    _baseURLForStore = "stores/:idStore/contacts";
    _errors = {
        "partnership_id": {
            "empty": "Vous devez sélectionner un partenaire"
        },
        "contact_id": {
            "wrong": "Vous devez sélection un id de contact valide"
        },
        "firstname": {
            "wrong": "Le prénom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "lastname": {
            "empty": "Le nom du contact est obligatoire",
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "email": {
            "wrong": "L'addresse email est invalide"
        },
        "phone": {
            "wrong": "Le numéro de fixe est invalide"
        },
        "mobile": {
            "wrong": "Le numéro de mobile est invalide"
        },
        "tags": {
            "wrong": "Un ou plusieurs tags sont incorrects"
        }
    };

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        if (formNew) {
            setValues(prev => ({
                ...prev,
                partnership_id: datas.store.partnership_id,
                contact_id: null,
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                mobile: ""/*,
                tags: []*/
            }))
        }
        else {

        }
    }
    setFormRows(setRows, formNew = false, tagList = []) {
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "lastname",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Dupont",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Prénom",
                    attribute: "firstname",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Christine",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                },
                {
                    label: "Email",
                    attribute: "email",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "christine.dupont@mail.com",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                },
                {
                    label: "Mobile",
                    attribute: "mobile",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "07",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                },
                {
                    label: "Téléphone",
                    attribute: "phone",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "09",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                }/*,
                {
                    label: "Tags",
                    attribute: "tags",
                    inputType: "tag",
                    returnType: "array",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Gérant",
                    autoComplete: "off",
                    needed: false,
                    checkData: null
                }*/
            ]
        }
        else {

        }

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["partnership_id", "lastname"];
        let optionals = ["contact_id", "firstname", "email", "phone", "mobile"/*, "tags"*/];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default: break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }

    // control values

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "newContact":
                datas = this.returnPostDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "partnership_id": return this.controlPartnershipId(value);
            case "contact_id": return this.controlContactId(value);
            case "firstname": return this.controlFirstname(value);
            case "lastname": return this.controlLastname(value);
            case "email": return this.controlEmail(value);
            case "phone": return this.controlPhone(value);
            case "mobile": return this.controlMobile(value);
            case "tags": return this.controlTags(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlPartnershipId(value) {
        let regex = /^[0-9]{1,}$/;

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["partnership_id"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlContactId(value) {
        if (value !== null) {
            let regex = /^[0-9]{1,}$/;

            if (!regex.test(value)) {
                return {
                    result: false,
                    error: this._errors["contact_id"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlFirstname(value) {
        if (value !== null && value.length > 0) {
            let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["firstname"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlLastname(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["lastname"]["empty"]
            };
        }

        let regex = /^[a-zA-Z0-9\-+'.,#&\s]{2,30}$/i;

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["lastname"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlEmail(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("email");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["email"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlPhone(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("phone");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["phone"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlMobile(value) {
        if (value !== null && value.length > 0) {
            let regex = RegexTools.getRegex("mobile");

            if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["mobile"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlTags(value) {
        if (value !== null && value.length > 0) {

        }

        return {
            result: true,
            error: null
        };
    }

    // Controllers

    index(input= "", skytill = false, tree = false, limit = null) {
        let params = ""

        if (input.length > 0)
            params += "?input=" + input

        if (skytill) {
            params += (params.length === 0 ? "?" : "&")
            params += "skytill=true"
        }

        if (tree) {
            params += (params.length === 0 ? "?" : "&")
            params += "tree=true"
        }

        if (limit !== null) {
            params += (params.length === 0 ? "?" : "&")
            params += "limit=" + limit
        }

        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []
        let pagination = null
        let data

        if (response.data !== undefined && response.data.data !== undefined) {
            if(response.data.total !== undefined) {
                pagination = {
                    total: response.data.total,
                    from: response.data.from != null ? response.data.from : 0,
                    to: response.data.to != null ? response.data.to : 0,
                    per_page: response.data.per_page,
                    current_page: response.data.current_page,
                    first_page_url: new URL(response.data.first_page_url).search,
                    last_page_url: new URL(response.data.last_page_url).search,
                    next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                    prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                }
            }

            data = response.data.data
        }
        else if (response.data)
            data = response.data

        for(let item in data)
            listObjects.push(new Contact(data[item]))

        this._callback(listObjects, error, pagination, status)
    }

    show(id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Contact(response.data) : null
        this._callback(object, error, status)
    }

    attach(store, idContact, datas) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLForStore.replace(":idStore", store.id) + '/contacts/' + idContact,
            datas,
            this.handleAttach
        )
    }
    handleAttach = (response, error, status) => {
        this._callback(response, error, status)
    }

    post(store, datas) {
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLForStore.replace(":idStore", store.id),
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(store, idContact) {
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURLForStore.replace(":idStore", store.id) + '/' + idContact,
            {},
            this.handleDelete
        )
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default ContactController;
