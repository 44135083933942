import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import $ from "jquery";
import Table from "./Table";
import "../../../css/tools/table/Listing.css";

const Listing = props => {
    const {
        item,
        itemClass,
        placeholderSearch,
        titleNbItems,
        emptyList,
        model,
        page,
        pageSelect,
        updatePageSelect,
        list,
        loading,
        pagination,
        checkable,
        openable,
        preview,
        setPerPage,
        sortingName,
        sortingValue,
        setSortingName,
        setSortingValue,
        noBorder
    } = props;
    const [loadingPagePrev, setLoadingPagePrev] = useState(false)
    const [loadingPageNext, setLoadingPageNext] = useState(false)
    const [showOptionNbItems, setShowOptionNbItems] = useState(false)
    const [showFormMany, setShowFormMany] = useState(false)
    const [listChecked, setListChecked] = useState([])
    const [nbSelected, setNbSelected] = useState(0)

    const resize = () => {
        let sizeToReduce = $(".site > .header").height() - 15
        let idContent = "#content"
        $(idContent).css({paddingTop: sizeToReduce + "px", maxHeight: "calc(100vh - " + sizeToReduce + "px)"})
    }
    const updateLoadingPageNext = val => {
        setLoadingPageNext(val);
    }
    const updateLoadingPagePrev = val => {
        setLoadingPagePrev(val);
    }
    const handleChangePerPage = val => {
        setPerPage(val)
        setShowOptionNbItems(false)

        const cookies = new Cookies(null, { domain: process.env.REACT_APP_HOST_ENVIRONMENT, path: "/" })
        cookies.set('object_per_page', val)
    }
    const handleSort = name => {
        if (sortingName === name)
            setSortingValue(sortingValue === "asc" ? "desc" : "asc");
        else {
            setSortingName(name);
            setSortingValue("asc");
        }
    }
    const handleCheckAll = () => {
        let tmpListChecked = listChecked.slice();

        if (tmpListChecked.length === list.length)
            tmpListChecked = [];
        else {
            for (let index in list) {
                if (!tmpListChecked.includes(list[index].id))
                    tmpListChecked.push(list[index].id);
            }
        }

        setNbSelected(tmpListChecked.length);
        setListChecked(tmpListChecked);
    }
    const emptyChecked = () => {
        setNbSelected(0);
        setListChecked([]);
    }
    const handleCheckLine = id => {
        let tmpListChecked = listChecked.slice();

        if (tmpListChecked.includes(id))
            tmpListChecked.splice(tmpListChecked.indexOf(id), 1);
        else
            tmpListChecked.push(id);

        setNbSelected(tmpListChecked.length);
        setListChecked(tmpListChecked);
    }
    const handleShowOptionNbItems = () => {
        setShowOptionNbItems(!showOptionNbItems);
    }

    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
    }, [])
    useEffect(() => {
        if (page !== null && page !== pageSelect) {
            if(page > pageSelect) updateLoadingPageNext(true);
            else updateLoadingPagePrev(true);

            updatePageSelect(page);
        }
    }, [page])
    useEffect(() => {
        if (!loading) {
            updateLoadingPageNext(false)
            updateLoadingPagePrev(false)
        }

        resize()
    }, [loading])

    return (
        <Table
            item={ item }
            titleNbItems={ titleNbItems }
            emptyList={ emptyList }
            model={ model }
            list={ list }
            loading={ loading }
            checkable={ checkable }
            openable={ openable }
            preview={ preview }
            listChecked={ listChecked }
            pagination={ pagination }
            loadingPagePrev={ loadingPagePrev }
            loadingPageNext={ loadingPageNext }
            showOptionNbItems={ showOptionNbItems }
            sortingParam={ sortingName }
            sortingDirection={ sortingValue }
            noBorder={ noBorder !== undefined ? noBorder : false }
            handleChangePerPage={ handleChangePerPage }
            handleCheckLine={ handleCheckLine }
            handleCheckAll={ handleCheckAll }
            handleSort={ handleSort }
            handleShowOptionNbItems={ handleShowOptionNbItems }
        />
    )
}

export default Listing;