import Account from "./Account";
import Rest from "../../../class/tool/Rest";
import FormBuilder from "../../../class/tool/FormBuilder";
import StringTools from "../../../class/tool/StringTools";
import RegexTools from "../../../class/tool/RegexTools";

class AccountController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "setups/{idSetup}/accounts";
    _baseURLExistAccount = "setups/{idSetup}/existaccounts";
    _baseURLTechnicialAccount = "setups/{idSetup}/technicianaccounts";
    _baseURLControllerAccount = "setups/{idSetup}/controlleraccounts";
    _errors = {
        "firstname": {
            "empty": "Le prénom du contact est obligatoire",
            "wrong": "Le prénom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "lastname": {
            "empty": "Le nom du contact est obligatoire",
            "wrong": "Le nom doit être compris entre 2 et 30 caractères. Alphanumérique, caractères autorisés : - + ' # & , ."
        },
        "email": {
            "empty": "l'adresse email est obligatoire",
            "wrong": "L'addresse email est invalide"
        },
        "rule": {
            "empty": "Le compte doit posséder au moins 1 rôle",
            "wrong": "Le / Les rôles sélectionnés sont invalide"
        },
        "companyRule": {
            "wrong": "Le / Les rôles sélectionnés sont invalide"
        },
        "contact_id": {
            "empty": "Vous devez sélectionner un compte existant"
        }
    };
    _tags = [
        {
            value: 'ADMIN',
            master: true
        },
        {
            value: 'ACCOUNTANT',
            master: false
        },
        {
            value: 'ANALYST',
            master: false
        },
        {
            value: 'TECH',
            master: false
        },
        {
            value: 'PRODUCTS',
            master: false
        },
        {
            value: 'MANAGER',
            master: false
        }
    ];
    _tagsDict = {
        'SKYTECH': "[TECHNICIEN] Administrateur",
        'CONTROLLER': "Contrôleur",
        'ADMIN': "Administrateur",
        'ACCOUNTANT': "Comptable",
        'ANALYST': "Analyste",
        'TECH': "Technicien",
        'PRODUCTS': "Catalogue",
        'MANAGER': "Manager"
    };
    static _accountStatus = {
        0: "Erreur",
        200: "OK",
        461: "Désactivé",
        468: "Initialisation"
    }

    // perform

    setFormValues(object, setValues, formNew = false, datas) {
        if (formNew) {
            if (datas.store.company_name === null) {
                setValues(prev => ({
                    ...prev,
                    firstname: StringTools.capitalizeFirstLetter(object.firstname.toLocaleLowerCase()),
                    lastname: StringTools.capitalizeFirstLetter(object.lastname.toLocaleLowerCase()),
                    email: object.email.toLocaleLowerCase(),
                    rule: this.buildRules(object.rules)
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    firstname: StringTools.capitalizeFirstLetter(object.firstname.toLocaleLowerCase()),
                    lastname: StringTools.capitalizeFirstLetter(object.lastname.toLocaleLowerCase()),
                    email: object.email.toLocaleLowerCase(),
                    rule: this.buildRules(object.rules),
                    companyRule: this.buildRules(object.companyRules)
                }));
            }
        }
        else {

        }
    }
    setFormControllerValues(setValues) {
        setValues(prev => ({
            ...prev,
            firstname: "",
            lastname: "",
            email: ""
        }));
    }
    setFormFromContactValues(object, setValues, datas) {
        if (datas.store.company_name === null) {
            setValues(prev => ({
                ...prev,
                email: "",
                contact_id: null,
                rule: this.buildRules(object.rules)
            }));
        }
        else {
            setValues(prev => ({
                ...prev,
                email: "",
                contact_id: null,
                rule: this.buildRules(object.rules),
                companyRule: this.buildRules(object.companyRules)
            }));
        }
    }
    setFormRows(setRows, formNew = false, datas) {
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "lastname",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Dupont",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Prénom",
                    attribute: "firstname",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "Christine",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Email",
                    attribute: "email",
                    inputType: "text",
                    returnType: "string",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    placeholder: "christine.dupont@mail.com",
                    autoComplete: "off",
                    needed: true,
                    checkData: null
                },
                {
                    label: "Droit(s) sur la boutique",
                    attribute: "rule",
                    inputType: "predefinedTag",
                    returnType: "array",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    autoComplete: "off",
                    tags: this._tags,
                    tagsDict: this._tagsDict,
                    needed: true,
                    checkData: null
                }
            ]

            if (datas.store.company_name !== null) {
                rows.push({
                    label: "Droit(s) sur le groupe",
                    attribute: "companyRule",
                    inputType: "predefinedTag",
                    returnType: "array",
                    classnameWrapper: null,
                    classnameLabel: null,
                    classnameInput: "",
                    autoComplete: "off",
                    tags: this._tags,
                    tagsDict: this._tagsDict,
                    needed: false,
                    checkData: null
                });
            }
        }
        else {

        }

        setRows(rows);
    }
    setFormControllerRows(setRows) {
        let rows;

        rows = [
            {
                label: "Nom",
                attribute: "lastname",
                inputType: "text",
                returnType: "string",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                placeholder: "Dupont",
                autoComplete: "off",
                needed: true,
                checkData: null
            },
            {
                label: "Prénom",
                attribute: "firstname",
                inputType: "text",
                returnType: "string",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                placeholder: "Christine",
                autoComplete: "off",
                needed: true,
                checkData: null
            },
            {
                label: "Email",
                attribute: "email",
                inputType: "text",
                returnType: "string",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                placeholder: "christine.dupont@mail.com",
                autoComplete: "off",
                needed: true,
                checkData: null
            }
        ]

        setRows(rows);
    }
    setFormFromContactRows(setRows, datas) {
        let rows;

        rows = [
            {
                label: "Email",
                attribute: "email",
                inputType: "text",
                returnType: "string",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                placeholder: "christine.dupont@mail.com",
                autoComplete: "off",
                needed: true,
                checkData: null
            },
            {
                label: "Droit(s) sur la boutique",
                attribute: "rule",
                inputType: "predefinedTag",
                returnType: "array",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                autoComplete: "off",
                tags: this._tags,
                tagsDict: this._tagsDict,
                needed: true,
                checkData: null
            }
        ];

        if (datas.store.company_name !== null) {
            rows.push({
                label: "Droit(s) sur le groupe",
                attribute: "companyRule",
                inputType: "predefinedTag",
                returnType: "array",
                classnameWrapper: null,
                classnameLabel: null,
                classnameInput: "",
                autoComplete: "off",
                tags: this._tags,
                tagsDict: this._tagsDict,
                needed: false,
                checkData: null
            });
        }

        setRows(rows);
    }
    returnPostDatas(datas) {
        let datasToPost = {};
        let needed = ["firstname", "lastname", "email", "rule"];
        let optionals = ["companyRule"];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default:
                    break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnPostControllerDatas(datas) {
        let datasToPost = {};
        let needed = ["firstname", "lastname", "email"];
        let optionals = [];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default:
                    break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnPostFromContactDatas(datas) {
        let datasToPost = {};
        let needed = ["contact_id", "rule"];
        let optionals = ["companyRule"];

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]];
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === undefined || datas[optionals[i]] === null) continue;

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue;
                    break;
                default:
                    break;
            }

            datasToPost[optionals[i]] = datas[optionals[i]];
        }

        return datasToPost;
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    buildRules(strRules) {
        if (strRules === null || strRules === undefined || strRules.length === 0)
            return []
        else
            return strRules.split('|')
    }

    // control values

    hasValidMinimumDatas(values, type) {
        let datas;
        let resultData;

        switch (type) {
            case "addAccount":
                datas = this.returnPostDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            case "addAccountController":
                datas = this.returnPostControllerDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            case "addAccountContact":
                datas = this.returnPostFromContactDatas(values);

                for (let key in datas) {
                    resultData = this.checkDataToPost(key, datas[key]);

                    if (!resultData.result)
                        return false;
                }

                return true;
            default:
                return false;
        }
    }
    checkDataToPost(attribute, value) {
        switch (attribute) {
            case "firstname": return this.controlFirstname(value);
            case "lastname": return this.controlLastname(value);
            case "email": return this.controlEmail(value);
            case "rule": return this.controlRule(value);
            case "companyRule": return this.controlCompanyRule(value);
            case "contact_id": return this.controlContactId(value);
            default:
                console.log("Unknown attribute", attribute);
                return {
                    result: false,
                    error: "Unknown attribute"
                };
        }
    }
    controlFirstname(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["firstname"]["empty"]
            };
        }

        let regex = RegexTools.getRegex("name");

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["firstname"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlLastname(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["lastname"]["empty"]
            };
        }

        let regex = RegexTools.getRegex("name");

        if (!regex.test(StringTools.removeAccents(value))) {
            return {
                result: false,
                error: this._errors["lastname"]["wrong"]
            };
        }

        return {
            result: true,
            error: null
        };
    }
    controlEmail(value) {
        if (value === undefined || value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["email"]["empty"]
            };
        }

        let regex = RegexTools.getRegex("email");

        if (!regex.test(StringTools.removeAccents(value))) {
                return {
                    result: false,
                    error: this._errors["email"]["wrong"]
                };
            }

        return {
            result: true,
            error: null
        };
    }
    controlRule(value) {
        if (value === null || value.length === 0) {
            return {
                result: false,
                error: this._errors["rule"]["empty"]
            };
        }

        let tags = [];
        for (let i in this._tags)
            tags.push(this._tags[i].value);

        for (let i in value) {
            if (!tags.includes(value[i])) {
                return {
                    result: false,
                    error: this._errors["rule"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlCompanyRule(value) {
        let tags = [];
        for (let i in this._tags)
            tags.push(this._tags[i].value);

        for (let i in value) {
            if (!tags.includes(value[i])) {
                return {
                    result: false,
                    error: this._errors["companyRule"]["wrong"]
                };
            }
        }

        return {
            result: true,
            error: null
        };
    }
    controlContactId(value) {
        if (value === null) {
            return {
                result: false,
                error: this._errors["contact_id"]["empty"]
            };
        }

        let regex = RegexTools.getRegex("id");

        if (!regex.test(value)) {
            return {
                result: false,
                error: this._errors["contact_id"]["empty"]
            };
        }

        return {
            result: true,
            error: null
        };
    }

    // Controllers

    indexAccounts(setup) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idSetup}", setup.id),
            {},
            this.handleIndexAccount)
    }
    handleIndexAccount = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push(new Account(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    addAccount(setup, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idSetup}", setup.id),
            datas,
            this.handleAddAccount
        )
    }
    handleAddAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    registerAccounts(setup, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLExistAccount.replace("{idSetup}", setup.id),
            datas,
            this.handleRegisterAccounts
        )
    }
    handleRegisterAccounts = (response, error, status) => {
        this._callback(response, error, status)
    }

    registerTechnicianAccount(setup) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLTechnicialAccount.replace("{idSetup}", setup.id),
            {},
            this.handleRegisterTechnicianAccount
        )
    }
    handleRegisterTechnicianAccount = (response, error, status) => {
        this._callback(response, error, status);
    }

    registerControllerAccount(setup, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLControllerAccount.replace("{idSetup}", setup.id),
            datas,
            this.handleRegisterControllerAccount
        )
    }
    handleRegisterControllerAccount = (response, error, status) => {
        this._callback(response, error, status);
    }

    updateAccount(setup, id, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            datas,
            this.handleUpdateAccount
        )
    }
    handleUpdateAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    disableAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {
                status: 427
            },
            this.handleDisableAccount
        )
    }
    handleDisableAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    enableAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {
                status: 200
            },
            this.handleEnableAccount
        )
    }
    handleEnableAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    removeAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {},
            this.handleRemoveAccount
        )
    }
    handleRemoveAccount = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default AccountController;
