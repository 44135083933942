import Rest from "../../../class/tool/Rest";
import Setup from "./Setup";
import Account from "../Accounts/Account";
import User from "../../_auth/Users/User";

class SetupController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "stores/:idStore/setups";
    _baseURLAccount = "setups/:idSetup/accounts";

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }

    index(store) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", store.id),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push( new Setup(data[item]) );
        }

        this._callback(listObjects, error);
    }

    show(store, idSetup) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", store.id) + '/' + idSetup,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Setup(response.data) : null;
        this._callback(object, error);
    }

    post(store) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace(":idStore", store.id),
            {},
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(store, object, datas) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace(":idStore", store.id) + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    registerAccounts(setup, store, ids) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLAccount.replace(":idSetup", setup.id),
            {
                "contacts": ids
            },
            this.handleRegisterAccounts
        );
    }
    handleRegisterAccounts = (response, error) => {
        this._callback(response, error);
    }

    registerTechnicianAccount(setup) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLAccount.replace(":idSetup", setup.id) + '/technicianaccounts',
            {},
            this.handleRegisterTechnicianAccount
        );
    }
    handleRegisterTechnicianAccount = (response, error) => {
        this._callback(response, error);
    }

    // Accounts

    indexAccounts(setup) {
        this._rest._contentType = "application/json";
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURLAccount.replace(":idSetup", setup.id),
            {},
            this.handleIndexAccount);
    }
    handleIndexAccount = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(let item in data)
                listObjects.push(new Account(data[item]));
        }

        this._callback(listObjects, error);
    }
}

export default SetupController;
