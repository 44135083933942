import React, { useEffect, useState } from "react";
import {v4 as uuidv4} from "uuid";
import DeleteIcon from "../../../icons/DeleteIcon";
import Search2Icon from "../../../icons/Search2Icon";
import StringTools from "../../../../class/tool/StringTools";

const InputCloudTag = props => {
    const { attribute, returnType, inputText, classname, values, list, placeholder, handleChange } = props;
    const [ listDatas, setListDatas ] = useState([]);
    const [ listFiltered, setListFiltered ] = useState([]);
    const [ search, setSearch ] = useState("");

    const changeChoice = datas => {
        if (handleChange === undefined || handleChange === null)
            return;

        handleChange(attribute, returnType, datas);
    }
    const showList = () => {
        if (listFiltered.length > 0)
            setListFiltered([]);
        else
            setListFiltered(list.slice());
    }
    const deleteEvent = uuid => {
        let datas = values.slice();
        let index = datas.findIndex(_ => _.uuid === uuid);

        if (index < 0)
            return;

        datas.splice(index, 1);
        changeChoice(datas);
    }
    const changeEvent = event => {
        setSearch(event.currentTarget.value);
    }
    const blurEvent = () => {
        addInList();
    }
    const keyDownEvent = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            addInList();
        }
    }
    const filterResearch = () => {
        if (search.length === 0) {
            setListFiltered([]);
            return;
        }

        let tmpFiltered = [];
        tmpFiltered = list.filter(_ => _.name.includes(search));
        setListFiltered(tmpFiltered);
    }
    const addInList = () => {
        if (search.length === 0) return;

        let datas = values.slice();
        let indexInValues = values.findIndex(_ => _.name === search.trim().toLocaleUpperCase());

        if (indexInValues >= 0) {
            setSearch("");
            return;
        }

        let indexInList = list.findIndex(_ => _.name === search.trim().toLocaleUpperCase());

        datas.push({
            uuid: indexInList >= 0 ? list[indexInList].uuid : null,
            id: indexInList >= 0 ? list[indexInList].id : null,
            name: search.trim().toLocaleUpperCase()
        });

        changeChoice(datas);
        setSearch("");
    }
    const selectInList = tag => {
        let datas = values.slice();

        datas.push({
            uuid: uuidv4(),
            id: tag.id,
            name: tag.name
        });

        changeChoice(datas);
        setSearch("");
    }

    useEffect(() => {
        filterResearch();
    }, [search])

    return (
        <div className={ "inputTag " + (classname !== undefined ? classname : "") }>
            <div className="containerTag">
                {
                    values.map((tag, index) => (
                        <div key={ index } className="tag">
                            <p>{ StringTools.capitalizeFirstLetter(tag.name.toLocaleLowerCase()) }</p>
                            <DeleteIcon handleClick={ () => { deleteEvent(tag.uuid) } } />
                        </div>
                    ))
                }
                {
                    inputText
                    && <input type="text" placeholder={placeholder} value={search} onChange={changeEvent} onKeyDown={keyDownEvent} onBlur={blurEvent} />
                }
            </div>
            <Search2Icon classname={"search"} handleClick={ showList } />
            {
                listFiltered.length > 0
                && <div className="smartList">
                    {
                        listFiltered.map((tag, index) => (
                            <div key={index} className={"tag"} onClick={() => { selectInList(tag) }}>
                                <p>{ StringTools.capitalizeFirstLetter(tag.name.toLocaleLowerCase()) }</p>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default InputCloudTag