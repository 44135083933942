import React, { useEffect, useState } from "react";
import {Observable, map, debounceTime, distinctUntilChanged} from "rxjs";
import $ from "jquery";
import CloseRegularIcon from "../../../icons/CloseRegularIcon";
import LoaderCircle from "../../../loader/LoaderCircle";

const InputText = props => {
    const { attribute, returnType, type, maxChars, autoComplete, value, placeholder, focus, readonly, isDisabled, eventsHandler } = props;
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false);
    const [ disabled, setDisabled ] = useState((isDisabled !== undefined && isDisabled !== null) ? isDisabled : false);
    const [ loading, setLoading ] = useState(false);
    const [ searchResults, setSearchResults ] = useState([]);

    const initSearching = () => {
        if (eventsHandler.handleSearch === undefined || eventsHandler.handleSearch === null)
            return;

        const input = document.querySelector("#" + attribute);
        const inputSearch$ = new Observable(observer => {
            input.addEventListener("input", (event) => {
                observer.next(event);
            })
        });
        const inputed$ = inputSearch$.pipe(
            map((event) => event.target.value),
            debounceTime(400),
            distinctUntilChanged()
        );

        inputed$.subscribe(value => {
            if (value.length > 0)
                search(value);
            else
                setSearchResults([]);
        });
    }
    const changeEvent = event => {
        let value = event.currentTarget.value;

        if (maxChars !== undefined && value.length > maxChars) return;
        if (eventsHandler.handleChange === undefined || eventsHandler.handleChange === null) return;

        eventsHandler.handleChange(attribute, returnType, value);
    }
    const blurEvent = () => {
        if (eventsHandler.handleBlur === undefined || eventsHandler.handleBlur === null) return;

        eventsHandler.handleBlur();
    }
    const keyDownEvent = event => {
        if (event.key === "Enter" && eventsHandler.handleSubmit !== undefined && eventsHandler.handleSubmit !== null) {
            event.preventDefault();
            eventsHandler.handleSubmit();
        }
    }
    const cancel = () => {
        if (eventsHandler.handleChange !== undefined && eventsHandler.handleChange !== null)
            eventsHandler.handleChange(attribute, returnType, "");

        $("#" + attribute).focus();
    }
    const search = value => {
        setLoading(true);
        eventsHandler.handleSearch(value, resultHandleSearch);
    }
    const resultHandleSearch = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                let datas = [];

                for (let i in list) {
                    datas.push({
                        id: list[i].id,
                        text: list[i][eventsHandler.handleSearchColumnName]
                    })
                }

                setSearchResults(datas);

                break;
            default:
                setSearchResults([]);
                break;
        }
    }
    const clickOnSearchResult = id => {
        if (eventsHandler.handleClickOnSearch === undefined || eventsHandler.handleClickOnSearch === null)
            return;

        eventsHandler.handleClickOnSearch(searchResults.find(_ => _.id === id));
        setSearchResults([]);
    }

    useEffect(() => {
        initSearching();
    }, []);
    useEffect(() => {
        setReadOnly(readonly);
    }, [readonly]);
    useEffect(() => {
        setDisabled(isDisabled);
    }, [isDisabled]);

    return (
        <>
            <input
                id={ attribute }
                type={ type !== undefined ? type : "text" }
                autoComplete={ autoComplete !== undefined ? autoComplete : "on" }
                value={ value !== null ? value : "" }
                placeholder={ placeholder }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ focus !== null ? focus : false }
                readOnly={ readOnly }
                disabled={ disabled }
                onKeyDown={ keyDownEvent } />
            {
                loading
                    ? <LoaderCircle display={"loading"} stroke={"#006D3E"} />
                    : <CloseRegularIcon classname={"cancel"} fill={"#797979"} handleClick={cancel} />
            }
            {
                searchResults.length > 0
                && <ul className={"searchList"} style={{width: $("#" + attribute).width() + "px", maxWidth: $("#" + attribute).width() + "px"}}>
                    {
                        searchResults.map((data, index) => (
                            <li key={index} onClick={() => { clickOnSearchResult(data.id); }}>{data.text}</li>
                        ))
                    }
                </ul>
            }
        </>
    );
}

export default InputText;
